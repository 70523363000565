import { getFormattedNumber } from "@msys/formatting";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocale } from "../LocaleProvider";

export interface SelectOption<Value = string> {
  counter?: number | undefined;
  icon?: React.ReactNode;
  key?: string;
  label: string;
  value: Value;
  greyedOut?: boolean;
}

export interface Props<
  Value,
  Multiple extends boolean = false,
  V = Multiple extends true ? Value[] : Value,
> extends Omit<MuiSelectProps<V>, "value" | "onChange" | "native" | "error"> {
  emptyLabel?: string;
  options: (SelectOption<Value> | { groupTitle: string })[];
  value: MuiSelectProps<V>["value"] | null;
  onChange: (
    value: Multiple extends true ? Value[] : Value,
    event: SelectChangeEvent<V | "">,
    child: React.ReactNode
  ) => void;
  clearable?: boolean;
  required?: boolean;
  error?: string;
  onClear?: () => void;
}

export function Select<Value, Multiple extends boolean = false>({
  label,
  emptyLabel,
  value,
  onChange,
  options,
  renderValue,
  fullWidth,
  size,
  clearable = false,
  onClear,
  error,
  required,
  ...props
}: Props<Value, Multiple>) {
  const locale = useLocale();
  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      style={props.style}
      error={Boolean(error)}
      disabled={props.disabled}
    >
      {size !== "extra-small" && (
        <InputLabel required={required}>
          {(!value ? emptyLabel : label) ?? label}
        </InputLabel>
      )}
      <MuiSelect<(Multiple extends true ? Value[] : Value) | "">
        {...props}
        required={required}
        error={Boolean(error)}
        fullWidth={fullWidth}
        size={size}
        native={false}
        value={value ?? ""}
        renderValue={
          renderValue
            ? value => {
                if (value === "") return null;
                return renderValue(value);
              }
            : undefined
        }
        onChange={(event, child) =>
          onChange(
            event.target.value as Multiple extends true ? Value[] : Value,
            event,
            child
          )
        }
        {...(clearable && value
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClear?.();
                    }}
                    aria-label="Clear"
                    style={{ position: "relative", top: 8, left: -16 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : undefined)}
      >
        {options.map((option, index) =>
          "groupTitle" in option ? (
            // @ts-ignore
            <ListSubheader
              disabled
              key={`${option.groupTitle}-${index}`}
              sx={{ color: "grey.500", minHeight: "36px", lineHeight: "36px" }}
            >
              {option.groupTitle}
            </ListSubheader>
          ) : (
            // @ts-ignore MenuItem only allows number | string | readonly string[] https://github.com/mui-org/material-ui/issues/14286
            <MenuItem
              key={option.key ?? JSON.stringify(option.value)}
              value={option.value}
              style={{
                minHeight: "36px",
                color: option?.greyedOut ? "grey" : undefined,
              }}
            >
              {option.icon || option.counter !== undefined ? (
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  width="100%"
                >
                  {option.icon}
                  <div style={{ flex: 1 }}>{option.label}</div>
                  {option.counter !== undefined ? (
                    <Typography variant="caption" sx={{ color: "grey.600" }}>
                      {getFormattedNumber(option.counter, locale)}
                    </Typography>
                  ) : null}
                </Stack>
              ) : (
                option.label
              )}
            </MenuItem>
          )
        )}
      </MuiSelect>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
