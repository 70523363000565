import { PhoneInput } from "@msys/ui";
import { useField, useFormikContext } from "formik";
import React from "react";

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: React.ComponentProps<typeof PhoneInput>["variant"];
}

export const PhoneField = ({
  id,
  name,
  label,
  placeholder,
  required,
  fullWidth,
  disabled,
  variant,
}: Props) => {
  const [{ value: formValue = "" }, metaProps, helperProps] = useField<string>({
    name,
  });
  const { isSubmitting } = useFormikContext();

  return (
    <PhoneInput
      id={id}
      name={name}
      value={formValue}
      onChange={value => {
        helperProps.setValue(value);
        helperProps.setTouched(true);
      }}
      label={label}
      required={required}
      fullWidth={fullWidth}
      placeholder={placeholder}
      error={metaProps.error}
      disabled={disabled ?? isSubmitting}
      variant={variant}
    />
  );
};
