import { stripHtmlTags } from "@msys/common";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardProps,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Attachment3dModel, AttachmentImage } from "./attachments/helpers";
import { GalleryModal, GalleryModalProps } from "./gallery/GalleryModal";
import { useScreenWidth } from "./hooks/useScreenWidth";
import {
  ModalOpenProcess,
  ModalOpenProcessRef,
} from "./modal/ModalOpenProcess";
import defaultImageUrl from "../assets/no-image.png";
import { TypographyWithMaxNumberOfLines } from "./text/Typography";

type Props<
  C extends React.ElementType,
  I extends AttachmentImage | Attachment3dModel,
> = CardProps<C, { component?: C }> & {
  imageUrl?: string;
  ActionButton?: React.ReactNode;
  StatusIcons?: React.ReactNode[];
  Info?: React.ReactNode;
  Tags?: React.ReactNode;
  title?: string;
  description?: string;
  brand?: string;
  article?: string;
  price?: string;
  onClick?: () => void;
  size?: "small" | "medium";
  descriptionMaxNumberOfLines?: number;
  images?: GalleryModalProps<I>["images"];
};

export const MediaCardItem = <
  C extends React.ElementType,
  I extends AttachmentImage | Attachment3dModel,
>({
  imageUrl = defaultImageUrl,
  ActionButton,
  StatusIcons,
  Info,
  Tags,
  title,
  brand,
  article,
  description,
  price,
  onClick,
  size: sizeProp,
  descriptionMaxNumberOfLines,
  images,
  ...props
}: Props<C, I>) => {
  const { isMinTablet } = useScreenWidth();
  const size = sizeProp ?? (isMinTablet ? "medium" : "small");
  const processRef = React.useRef<ModalOpenProcessRef>();
  return (
    <Card
      {...props}
      sx={{
        display: "block",
        textDecoration: "none",
        ...props.sx,
      }}
    >
      <CardMedia
        sx={{
          position: "relative",
          width: "100%",
          // aspectRatio: "4 / 3", // Not supported on Safari < 15 https://caniuse.com/mdn-css_properties_aspect-ratio
          paddingTop: "75%",
          cursor: images && images.length > 0 ? "pointer" : "default",
        }}
        image={imageUrl}
        onClick={
          images && images.length > 0
            ? e => {
                e.preventDefault();
                e.stopPropagation();
                processRef.current?.open();
              }
            : undefined
        }
      >
        {ActionButton ? (
          <CardActions
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onMouseDown={e => {
              e.stopPropagation();
            }}
            onTouchStart={e => {
              e.stopPropagation();
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Stack
              direction={"row"}
              spacing={0.5}
              alignItems="center"
              flexShrink={0}
              flexGrow={0}
            >
              {ActionButton}
            </Stack>
          </CardActions>
        ) : null}
      </CardMedia>
      <CardActionArea disableRipple onClick={onClick}>
        <CardContent
          sx={{
            padding: size === "medium" ? "8px 12px" : "4px 8px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minWidth: 0,
            gap: 0.5,
          }}
        >
          {brand && (
            <Typography variant={"body2"} fontWeight="bold">
              {brand}
            </Typography>
          )}
          {title || StatusIcons ? (
            <Stack
              direction={"row"}
              spacing={0}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <TypographyWithMaxNumberOfLines
                $maxLines={2}
                variant={size === "medium" ? "h2" : "h3"}
              >
                {title}
              </TypographyWithMaxNumberOfLines>
              {StatusIcons ? (
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems="center"
                  flexShrink={0}
                  flexGrow={0}
                  height={22}
                >
                  {StatusIcons}
                </Stack>
              ) : null}
            </Stack>
          ) : null}
          {description ? (
            <TypographyWithMaxNumberOfLines
              $maxLines={
                descriptionMaxNumberOfLines ?? (size === "medium" ? 3 : 1)
              }
              variant={"body2"}
            >
              {stripHtmlTags(description, true)}
            </TypographyWithMaxNumberOfLines>
          ) : null}
          {article && (
            <Typography variant={"body2"} sx={{ color: "grey.600" }} noWrap>
              {article}
            </Typography>
          )}
          {Info ? (
            <Box maxHeight={"76px"} overflow="hidden">
              {Info}
            </Box>
          ) : null}
          {Tags ? (
            <Box maxHeight={"76px"} overflow="hidden">
              {Tags}
            </Box>
          ) : null}
          {price ? (
            <Typography variant="body1" align="left">
              {price}
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
      {images && (
        <ModalOpenProcess
          ref={processRef}
          Modal={GalleryModal}
          modalProps={{
            images,
          }}
        />
      )}
    </Card>
  );
};
