import { stripHtmlTags } from "@msys/common";
import {
  ellipsisStyle,
  TypographyWithMaxNumberOfLines,
  useScreenWidth,
} from "@msys/ui";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import defaultImageUrl from "../../assets/images/no-image.png";
import { useTranslate } from "@tolgee/react";
import { isPredefinedTopic, useContentTopics } from "./useContentTopics";

export const ArticleListItem = ({
  id,
  title,
  group,
  previewImageUrl,
  text,
  MenuButton,
  onClick,
}: {
  id: string;
  title: string;
  group: string;
  previewImageUrl: string;
  text: string;
  MenuButton?: React.ReactNode;
  onClick(): void;
}) => {
  const { t } = useTranslate(["Global", "Contents"]);
  const { topicIcons } = useContentTopics();
  const Icon = group && isPredefinedTopic(group) ? topicIcons[group] : null;

  const { isMinTablet } = useScreenWidth();

  return (
    <ButtonBase
      onClick={onClick}
      sx={theme => ({
        textAlign: "left",
        transition: "background-color 0.2s ease-out",
        backgroundColor: theme.palette.common.white,
        ["&:hover:not(:has(button:hover)):not(:has(a:hover))"]: {
          backgroundColor: theme.palette.grey[100],
        },
        ["&:active:not(:has(button:active)):not(:has(a:active))"]: {
          backgroundColor: theme.palette.grey[200],
        },
        p: 1,
        borderRadius: "4px",
        width: "100%",
        display: "flex",
        justifyContent: "stretch",
      })}
      focusRipple
    >
      <Stack
        direction="row"
        id={id}
        justifyContent="flex-start"
        spacing={2}
        width="100%"
        flex={1}
      >
        <Box
          flexShrink="0"
          flexGrow="0"
          width={"calc(100% / 3)"}
          overflow="hidden"
          display="flex"
          sx={{ aspectRatio: "16 / 9" }}
        >
          <img
            draggable={false}
            src={previewImageUrl || defaultImageUrl}
            alt={t("Content cover photo", {
              ns: "Contents",
            })}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "2px",
            }}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          minWidth={0}
          flex={1}
        >
          <Stack
            direction="column"
            spacing={isMinTablet ? 1 : 0.5}
            minWidth={0}
            flex={1}
            py={1}
          >
            {group ? (
              <Typography
                variant="caption"
                color="warning.main"
                fontWeight="bold"
                style={ellipsisStyle}
                component="div"
              >
                {Icon ? (
                  <Stack direction="row" spacing={0.5} style={ellipsisStyle}>
                    <Icon fontSize="extra-small" />
                    <span style={ellipsisStyle}>{group}</span>
                  </Stack>
                ) : (
                  group
                )}
              </Typography>
            ) : (
              <Typography
                variant="caption"
                color="grey.600"
                fontWeight="bold"
                style={ellipsisStyle}
                component="div"
              >
                {t("Other", { ns: "Contents" })}
              </Typography>
            )}
            <TypographyWithMaxNumberOfLines
              variant={isMinTablet ? "h3" : "h4"}
              $maxLines={2}
              gutterBottom
            >
              {title}
            </TypographyWithMaxNumberOfLines>
            {text && (
              <TypographyWithMaxNumberOfLines
                $maxLines={isMinTablet ? 3 : 2}
                variant="body2"
              >
                {stripHtmlTags(text, true)}
              </TypographyWithMaxNumberOfLines>
            )}
          </Stack>
          {MenuButton}
        </Stack>
      </Stack>
    </ButtonBase>
  );
};
