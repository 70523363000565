import React from "react";
import { Size, useElementObserveSize } from "./useElementObserveSize";

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  ref: React.MutableRefObject<T | null>
): Size {
  const [size, setSize] = React.useState<Size>({ width: 0, height: 0 });
  useElementObserveSize<T>(ref, setSize);
  return size;
}
