import { getImageUrl } from "@msys/common";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, IconButtonProps, ImageList } from "@mui/material";
import React from "react";
import {
  Attachment3dModel,
  AttachmentImage,
  is3dModel,
  isImage,
} from "../attachments/helpers";
import { useIconButtonLightStyles } from "../button/useIconButtonStyles";
import { ImageListItem } from "../image/ImageListItem";
import { ThreeDGenericImage } from "../threeD/ThreeDGenericImage";

const DEFAULT_IMAGE_SIZE = 240; // TODO: is this a good generic value

export interface GalleryGridProps<
  I extends AttachmentImage | Attachment3dModel
> {
  selectedIndex?: number;
  images: I[];
  handleClick?: null | ((index: number, image: I) => void);
  handleDelete?: (image: I) => void;
  columns?: number;
  style?: React.CSSProperties;
  idPrefix?: string;
  showDeleteButton?: boolean;
}

export const GalleryGrid = <I extends AttachmentImage | Attachment3dModel>({
  selectedIndex,
  images = [],
  handleClick,
  handleDelete,
  columns = 2,
  style,
  idPrefix = "msys-gallery-grid-",
  showDeleteButton = false,
}: GalleryGridProps<I>) => {
  return images.length > 0 ? (
    <ImageList
      style={{
        width: "100%",
        ...style,
      }}
      cols={columns}
    >
      {images.map((image, index) => (
        <ImageListItem
          key={image.id}
          id={`${idPrefix}${image.id}`}
          onClick={
            handleClick
              ? e => {
                  e.stopPropagation();
                  handleClick(index, image);
                }
              : undefined
          }
          role="button"
          $clickable
          $selected={selectedIndex === index}
        >
          {isImage(image) ? (
            <img
              draggable={false}
              src={getImageUrl({ url: image.url, width: DEFAULT_IMAGE_SIZE })}
              srcSet={`${getImageUrl({
                url: image.url,
                width: DEFAULT_IMAGE_SIZE,
                scaling: 2,
              })} 2x`}
              alt={image.title}
              loading="lazy"
            />
          ) : is3dModel(image) ? (
            <ThreeDGenericImage />
          ) : null}
          {showDeleteButton && handleDelete && (
            <StyledDeleteButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handleDelete(image);
              }}
              style={{ zIndex: 12 }}
            />
          )}
        </ImageListItem>
      ))}
    </ImageList>
  ) : null;
};

const StyledDeleteButton = ({ style, ...props }: IconButtonProps) => {
  const { classes } = useIconButtonLightStyles();
  return (
    <IconButton
      classes={classes}
      style={{
        position: "absolute",
        bottom: "4px",
        right: "4px",
        ...style,
      }}
      size="small"
      color="primary"
      {...props}
    >
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  );
};
