import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Chip } from "@mui/material";
import isEqual from "lodash.isequal";
import without from "lodash.without";
import React from "react";
import { Props as SelectProps, Select } from "./Select";

interface Props<T>
  extends Omit<SelectProps<T, true>, "renderValue" | "multiple"> {}

export function SelectMultiple<T>(props: Props<T>) {
  const { options, onChange } = props;
  const ref = React.useRef<HTMLElement>(null);

  const getChipLabel = (value: T) => {
    const option = options?.find(option =>
      "groupTitle" in option ? false : isEqual(option.value, value)
    );
    return option
      ? "groupTitle" in option
        ? undefined
        : option.label
      : undefined;
  };

  return (
    <Select<T, true>
      {...props}
      inputRef={ref}
      multiple
      renderValue={selected => {
        if (!selected) return null;
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="baseline"
            flexWrap="wrap"
            sx={{ ml: -0.5, mt: -0.5 }}
          >
            {selected.map(value => (
              <Chip
                key={value as unknown as string}
                size="small"
                clickable={false}
                variant="outlined"
                label={getChipLabel(value)}
                onDelete={
                  !props.disabled
                    ? event => {
                        onChange(without(selected, value), event, null);
                      }
                    : undefined
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={e => {
                      // prevent Select intercepting 'mousedown' event
                      e.stopPropagation();
                      e.preventDefault();
                      ref.current?.focus();
                    }}
                  />
                }
                sx={{ ml: 0.5, mt: 0.25 }}
              />
            ))}
          </Box>
        );
      }}
    />
  );
}
