/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterBoolFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterBoolFilterComputed', key: string, expr: string, valueBoolComputed?: boolean | null, missingValue: boolean, operatorBool: Types.EntitySearchBoolFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterBoolInFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterBoolInFilterComputed', key: string, expr: string, valueBoolInComputed?: Array<boolean> | null, missingValue: boolean, operatorBoolIn: Types.EntitySearchBoolInFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberArrayOfFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterNumberArrayOfFilterComputed', key: string, expr: string, valueNumberArrayOfComputed?: Array<number> | null, missingValue: boolean, operatorNumberArrayOf: Types.EntitySearchArrayOfFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberBetweenFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterNumberBetweenFilterComputed', key: string, expr: string, missingValue: boolean, operatorNumberBetween: Types.EntitySearchNumberBetweenFilterOperator, valueNumberBetweenComputed?: { __typename: 'ValueNumberBetween', min: number, max: number } | null };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterNumberFilterComputed', key: string, expr: string, valueNumberComputed?: number | null, missingValue: boolean, operatorNumber: Types.EntitySearchNumberFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberInFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterNumberInFilterComputed', key: string, expr: string, valueNumberInComputed?: Array<number> | null, missingValue: boolean, operatorNumberIn: Types.EntitySearchNumberInFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterTextArrayOfFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterTextArrayOfFilterComputed', key: string, expr: string, valueTextArrayOfComputed?: Array<string> | null, missingValue: boolean, operatorTextArrayOf: Types.EntitySearchArrayOfFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterTextFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterTextFilterComputed', key: string, expr: string, valueTextComputed?: string | null, missingValue: boolean, operatorText: Types.EntitySearchTextFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterTextInFilterComputed_Fragment = { __typename: 'EntitySearchPropertyFilterTextInFilterComputed', key: string, expr: string, valueTextInComputed?: Array<string> | null, missingValue: boolean, operatorTextIn: Types.EntitySearchTextInFilterOperator };

export type ItemTemplateSearchFilterPropertyFilterComputedFragment = ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterBoolFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterBoolInFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberArrayOfFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberBetweenFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterNumberInFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterTextArrayOfFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterTextFilterComputed_Fragment | ItemTemplateSearchFilterPropertyFilterComputed_EntitySearchPropertyFilterTextInFilterComputed_Fragment;

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterBoolFilter_Fragment = { __typename: 'EntitySearchPropertyFilterBoolFilter', key: string, operatorBool: Types.EntitySearchBoolFilterOperator, valueBool: boolean };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterBoolInFilter_Fragment = { __typename: 'EntitySearchPropertyFilterBoolInFilter', key: string, operatorBoolIn: Types.EntitySearchBoolInFilterOperator, valueBoolIn: Array<boolean> };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberArrayOfFilter_Fragment = { __typename: 'EntitySearchPropertyFilterNumberArrayOfFilter', key: string, operatorNumberArrayOf: Types.EntitySearchArrayOfFilterOperator, valueNumberArrayOf: Array<number> };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberBetweenFilter_Fragment = { __typename: 'EntitySearchPropertyFilterNumberBetweenFilter', key: string, operatorNumberBetween: Types.EntitySearchNumberBetweenFilterOperator, valueNumberBetween: { __typename: 'ValueNumberBetween', min: number, max: number } };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberFilter_Fragment = { __typename: 'EntitySearchPropertyFilterNumberFilter', key: string, operatorNumber: Types.EntitySearchNumberFilterOperator, valueNumber: number };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberInFilter_Fragment = { __typename: 'EntitySearchPropertyFilterNumberInFilter', key: string, operatorNumberIn: Types.EntitySearchNumberInFilterOperator, valueNumberIn: Array<number> };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterTextArrayOfFilter_Fragment = { __typename: 'EntitySearchPropertyFilterTextArrayOfFilter', key: string, operatorTextArrayOf: Types.EntitySearchArrayOfFilterOperator, valueTextArrayOf: Array<string> };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterTextFilter_Fragment = { __typename: 'EntitySearchPropertyFilterTextFilter', key: string, operatorText: Types.EntitySearchTextFilterOperator, valueText: string };

export type ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterTextInFilter_Fragment = { __typename: 'EntitySearchPropertyFilterTextInFilter', key: string, operatorTextIn: Types.EntitySearchTextInFilterOperator, valueTextIn: Array<string> };

export type ItemTemplateSearchFilterPropertyFilterFragment = ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterBoolFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterBoolInFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberArrayOfFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberBetweenFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterNumberInFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterTextArrayOfFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterTextFilter_Fragment | ItemTemplateSearchFilterPropertyFilter_EntitySearchPropertyFilterTextInFilter_Fragment;

export const ItemTemplateSearchFilterPropertyFilterComputedFragmentDoc = gql`
    fragment ItemTemplateSearchFilterPropertyFilterComputed on ItemTemplateSearchFilterPropertyFilterComputed {
  ... on EntitySearchPropertyFilterNumberFilterComputed {
    key
    expr
    valueNumberComputed
    missingValue
    operatorNumber
  }
  ... on EntitySearchPropertyFilterNumberInFilterComputed {
    key
    expr
    valueNumberInComputed
    missingValue
    operatorNumberIn
  }
  ... on EntitySearchPropertyFilterNumberBetweenFilterComputed {
    key
    expr
    valueNumberBetweenComputed {
      min
      max
    }
    missingValue
    operatorNumberBetween
  }
  ... on EntitySearchPropertyFilterNumberArrayOfFilterComputed {
    key
    expr
    valueNumberArrayOfComputed
    missingValue
    operatorNumberArrayOf
  }
  ... on EntitySearchPropertyFilterTextFilterComputed {
    key
    expr
    valueTextComputed
    missingValue
    operatorText
  }
  ... on EntitySearchPropertyFilterTextInFilterComputed {
    key
    expr
    valueTextInComputed
    missingValue
    operatorTextIn
  }
  ... on EntitySearchPropertyFilterTextArrayOfFilterComputed {
    key
    expr
    valueTextArrayOfComputed
    missingValue
    operatorTextArrayOf
  }
  ... on EntitySearchPropertyFilterBoolFilterComputed {
    key
    expr
    valueBoolComputed
    missingValue
    operatorBool
  }
  ... on EntitySearchPropertyFilterBoolInFilterComputed {
    key
    expr
    valueBoolInComputed
    missingValue
    operatorBoolIn
  }
}
    `;
export const ItemTemplateSearchFilterPropertyFilterFragmentDoc = gql`
    fragment ItemTemplateSearchFilterPropertyFilter on ItemTemplateSearchFilterPropertyFilter {
  ... on EntitySearchPropertyFilterBoolFilter {
    key
    operatorBool
    valueBool
  }
  ... on EntitySearchPropertyFilterBoolInFilter {
    key
    operatorBoolIn
    valueBoolIn
  }
  ... on EntitySearchPropertyFilterNumberFilter {
    key
    operatorNumber
    valueNumber
  }
  ... on EntitySearchPropertyFilterNumberInFilter {
    key
    operatorNumberIn
    valueNumberIn
  }
  ... on EntitySearchPropertyFilterNumberBetweenFilter {
    key
    operatorNumberBetween
    valueNumberBetween {
      min
      max
    }
  }
  ... on EntitySearchPropertyFilterNumberArrayOfFilter {
    key
    operatorNumberArrayOf
    valueNumberArrayOf
  }
  ... on EntitySearchPropertyFilterTextFilter {
    key
    operatorText
    valueText
  }
  ... on EntitySearchPropertyFilterTextInFilter {
    key
    operatorTextIn
    valueTextIn
  }
  ... on EntitySearchPropertyFilterTextArrayOfFilter {
    key
    operatorTextArrayOf
    valueTextArrayOf
  }
}
    `;