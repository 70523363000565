import { getImageUrl } from "@msys/common";
import { ImageList } from "@mui/material";
import React from "react";
import {
  Attachment3dModel,
  AttachmentImage,
  is3dModel,
  isImage,
} from "../attachments/helpers";
import { ImageListItem } from "../image/ImageListItem";
import { ThreeDGenericImage } from "../threeD/ThreeDGenericImage";

const DEFAULT_IMAGE_SIZE = 240; // TODO: is this a good generic value

export interface GalleryColProps<
  I extends AttachmentImage | Attachment3dModel
> {
  images: I[];
  handleClick?:
    | null
    | ((index: number, image: I, isMoreAmount: boolean) => void);
  maxItems?: number;
  selectedIndex?: number;
  imageWidth?: number;
  containerHeight?: number;
  aspectRatio?: React.CSSProperties["aspectRatio"];
  style?: React.CSSProperties;
  idPrefix?: string;
}

export const GalleryCol = <I extends AttachmentImage | Attachment3dModel>({
  images,
  handleClick,
  maxItems,
  selectedIndex,
  imageWidth,
  containerHeight,
  aspectRatio,
  style,
  idPrefix = "msys-gallery-col-",
}: GalleryColProps<I>) => {
  const imagesList = maxItems ? images.slice(0, maxItems) : images;
  const height =
    containerHeight ??
    (maxItems
      ? "100%"
      : imageWidth
      ? images.length * imageWidth + (images.length - 1) * 4
      : "100%");
  const width = imageWidth;
  const containerAspectRatio =
    containerHeight && !imageWidth && imagesList.length > 0
      ? `${
          (containerHeight - (imagesList.length - 1) * 4) / imagesList.length
        } / ${containerHeight}`
      : undefined;
  return images.length > 0 ? (
    <ImageList
      style={{
        height,
        width: width ?? "auto",
        aspectRatio: containerAspectRatio,
        flexGrow: 0,
        flexShrink: 0,
        userSelect: "none",
        ...style,
      }}
      cols={1}
      rowHeight="auto"
    >
      {imagesList.map((image, index) => {
        const isMoreAmount = Boolean(
          maxItems &&
            images.length > maxItems &&
            index === imagesList.length - 1
        );
        return (
          <ImageListItem
            key={image.id}
            id={`${idPrefix}${image.id}`}
            onClick={
              handleClick
                ? e => {
                    e.stopPropagation();
                    handleClick(index, image, isMoreAmount);
                  }
                : undefined
            }
            role={
              Boolean(handleClick) && selectedIndex !== index
                ? "button"
                : "initial"
            }
            $clickable
            $selected={selectedIndex === index}
            $moreAmount={
              isMoreAmount ? Math.min(images.length - maxItems!, 99) : undefined
            }
            $aspectRatio={aspectRatio}
          >
            {isImage(image) ? (
              <img
                draggable={false}
                src={getImageUrl({
                  url: image.url,
                  width: width ?? DEFAULT_IMAGE_SIZE,
                })}
                srcSet={`${getImageUrl({
                  url: image.url,
                  width: width ?? DEFAULT_IMAGE_SIZE,
                  scaling: 2,
                })} 2x`}
                alt={image.title}
                loading="lazy"
              />
            ) : is3dModel(image) ? (
              <ThreeDGenericImage />
            ) : null}
          </ImageListItem>
        );
      })}
    </ImageList>
  ) : null;
};
