import { etimUnits, getUnits, getUnitByKey } from "@msys/units";
import React from "react";
import { useTolgee, useTranslate } from "@tolgee/react";

export function usePropertyUnits() {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["EtimUnitsValue", "EtimUnitsDescription"]);

  const getUnitsWithTranslations = React.useCallback(() => {
    return getUnits(t, language);
  }, [language, t]);

  const getUnitLabel = React.useCallback(
    (key: string) => {
      const label = getUnitByKey(key, t)?.value ?? "";
      if (label.length > 3) return ` ${label}`;
      return label;
    },
    [t]
  );

  const unitsKeys = React.useMemo(() => etimUnits.map(unit => unit.key), []);

  return { getUnits: getUnitsWithTranslations, getUnitLabel, unitsKeys };
}
