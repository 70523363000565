import { Box, useTheme } from "@mui/material";
import React from "react";
import { useKey } from "react-use";
import { AttachmentImage, Attachment3dModel } from "../attachments/helpers";
import { BackdropModal } from "./BackdropModal";
import { GalleryRow } from "./GalleryRow";
import { GallerySlider } from "./GallerySlider";
import { useScreenWidth } from "../hooks/useScreenWidth";

export interface GalleryModalProps<
  I extends AttachmentImage | Attachment3dModel
> {
  images: I[];
  initialIndex?: number;
  handleClose: () => void;
  handleAdd?: () => void;
  handleDelete?: (image: I) => void;
  handleRotate?: (image: I, direction: "right" | "left") => void;
  disabled?: boolean;
  /*
   * Use Modal as a standalone modal which is not pushing something to stack which involves closing other modals
   * Useful to show small modal on top of existing one without closin it
   */
  notInStack?: boolean;
  downloadTitle?: string;
  addTitle?: string;
  deleteTitle?: string;
  rotateTitle?: string;
  arButtonTitle?: string;
}

export function GalleryModal<I extends AttachmentImage | Attachment3dModel>({
  images,
  initialIndex,
  handleClose,
  handleAdd,
  handleDelete,
  handleRotate,
  disabled,
  notInStack = true,
  downloadTitle,
  addTitle,
  deleteTitle,
  rotateTitle,
  arButtonTitle,
}: GalleryModalProps<I>) {
  const theme = useTheme();
  const [isTop, setIsTop] = React.useState<boolean>(true);
  const { isMaxPhone, isMinLargeDesktop } = useScreenWidth();

  const [currentIndex, setCurrentIndex] = React.useState(initialIndex ?? 0);

  React.useEffect(() => {
    if (!images[currentIndex]) return;
    document
      .getElementById(`msys-image-thumbnail-${images[currentIndex].id}`)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [images, currentIndex]);

  const moveNext = React.useCallback(() => {
    setCurrentIndex(index => (index + 1) % images.length);
  }, [images]);
  const movePrev = React.useCallback(() => {
    setCurrentIndex(index => (index - 1 + images.length) % images.length);
  }, [images]);

  React.useEffect(() => {
    if (images.length === 0) handleClose();
  }, [images.length, handleClose]);

  useKey("ArrowLeft", isTop ? movePrev : undefined, undefined, [isTop]);
  useKey("ArrowRight", isTop ? moveNext : undefined, undefined, [isTop]);

  return (
    <BackdropModal
      handleClose={handleClose}
      notInStack={notInStack}
      onTopChange={setIsTop}
    >
      <GallerySlider
        images={images}
        slideIndex={currentIndex}
        onSlideIndexChange={setCurrentIndex}
        handleAdd={handleAdd}
        handleRotate={handleRotate}
        handleDelete={handleDelete}
        disabled={disabled}
        downloadTitle={downloadTitle}
        addTitle={addTitle}
        deleteTitle={deleteTitle}
        rotateTitle={rotateTitle}
        threeDInteractive={true}
        arButtonTitle={arButtonTitle}
      />
      <Box
        sx={{
          overflowX: "auto",
          paddingTop: "4px",
          paddingBottom: "4px",
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <GalleryRow
          idPrefix={`msys-image-thumbnail-`}
          selectedIndex={currentIndex}
          handleClick={index => setCurrentIndex(index)}
          images={images}
          imageHeight={
            isMaxPhone
              ? theme.layout.imageSize.xs
              : isMinLargeDesktop
              ? theme.layout.imageSize.xl
              : theme.layout.imageSize.md
          }
          style={{ margin: "0 auto" }}
        />
      </Box>
    </BackdropModal>
  );
}
