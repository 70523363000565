import { useFormatting } from "@msys/ui";
import { clamp } from "lodash";
import React from "react";
import { FormattedField } from "./FormattedField";

export const FormattedFloatOptionalField = ({
  name,
  unit,
  min,
  max,
  multiplier = 1,
  setFieldValue,
  ...props
}: {
  name: string;
  unit?: string;
  min?: number;
  max?: number;
  multiplier?: number;
  setFieldValue?: (field: string, value: number | null) => void;
} & Omit<
  React.ComponentProps<typeof FormattedField<number>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue"
>) => {
  const { getFormattedFloat, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: number | null | undefined) {
    if (value === null || value === undefined) return undefined;
    return getFormattedUnit(getFormattedFloat((value ?? 0) * multiplier), unit);
  }

  function getActualValue(value: string) {
    let floatValue: number | null =
      value === "" ? null : getFloat(value, false);

    if (floatValue !== null) {
      floatValue = floatValue / multiplier;
      if (Number.isNaN(floatValue)) return null;
      const newValue = clamp(
        floatValue,
        min ?? Number.MIN_SAFE_INTEGER,
        max ?? Number.MAX_SAFE_INTEGER
      );
      return newValue;
    }
    return null;
  }

  function getEditableValue(value: number | null | undefined) {
    if (value === null || value === undefined) return "";
    return getEditableFloat((value ?? 0) * multiplier);
  }

  return (
    <FormattedField<number | null>
      {...props}
      name={name}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
      setFieldValue={setFieldValue}
    />
  );
};

function getFormattedUnit(value: string, unit?: string) {
  return `${value}${unit ?? ""}`.trim();
}
