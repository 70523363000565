/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttachmentFragment = { __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string };

export type ItemAttachmentFragment = { __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean };

export type AttachmentSnapshotFragment = { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string };

export type Attachments_Building_Fragment = { __typename: 'Building', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_Project_Fragment = { __typename: 'Project', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_Quote_Fragment = { __typename: 'Quote', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_QuoteTemplate_Fragment = { __typename: 'QuoteTemplate', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_Request_Fragment = { __typename: 'Request', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_Requirement_Fragment = { __typename: 'Requirement', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_ShoppableTemplate_Fragment = { __typename: 'ShoppableTemplate', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type Attachments_TaskDocument_Fragment = { __typename: 'TaskDocument', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type AttachmentsFragment = Attachments_Building_Fragment | Attachments_Project_Fragment | Attachments_Quote_Fragment | Attachments_QuoteTemplate_Fragment | Attachments_Request_Fragment | Attachments_Requirement_Fragment | Attachments_ShoppableTemplate_Fragment | Attachments_TaskDocument_Fragment;

export type WithItemAttachmentsFragment = { __typename: 'Item', id: string, attachments: Array<{ __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean }> };

export type AddItemAttachmentsMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID']['input'];
  attachments: Array<Types.AttachmentInput>;
}>;


export type AddItemAttachmentsMutation = { addItemAttachments: { __typename: 'AddItemAttachmentsResult', attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } };

export type ModifyItemAttachmentMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
  url: Types.Scalars['String']['input'];
}>;


export type ModifyItemAttachmentMutation = { modifyItemAttachment: { __typename: 'ModifyItemAttachmentResult', attachment: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type RemoveItemAttachmentMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;


export type RemoveItemAttachmentMutation = { removeItemAttachments: { __typename: 'RemoveItemAttachmentsResult', ok: boolean } };

export type AddProductAttachmentsMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
  attachments: Array<Types.AttachmentInput>;
}>;


export type AddProductAttachmentsMutation = { addProductAttachments: { __typename: 'AddProductAttachmentsResult', attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } };

export type ModifyProductAttachmentMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
  url: Types.Scalars['String']['input'];
}>;


export type ModifyProductAttachmentMutation = { modifyProductAttachment: { __typename: 'ModifyProductAttachmentResult', attachment: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type RemoveProductAttachmentMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;


export type RemoveProductAttachmentMutation = { removeProductAttachments: { __typename: 'RemoveProductAttachmentsResult', ok: boolean } };

export type AddProjectAttachmentsMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  attachments: Array<Types.AttachmentInput>;
}>;


export type AddProjectAttachmentsMutation = { addProjectAttachments: { __typename: 'AddProjectAttachmentsResult', attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } };

export type ModifyProjectAttachmentMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
  url: Types.Scalars['String']['input'];
}>;


export type ModifyProjectAttachmentMutation = { modifyProjectAttachment: { __typename: 'ModifyProjectAttachmentResult', attachment: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type RemoveProjectAttachmentMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;


export type RemoveProjectAttachmentMutation = { removeProjectAttachments: { __typename: 'RemoveProjectAttachmentsResult', ok: boolean } };

export type AddRequestAttachmentsMutationVariables = Types.Exact<{
  requestId: Types.Scalars['ID']['input'];
  attachments: Array<Types.AttachmentInput>;
}>;


export type AddRequestAttachmentsMutation = { addRequestAttachments: { __typename: 'AddRequestAttachmentsResult', attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } };

export type ModifyRequestAttachmentMutationVariables = Types.Exact<{
  requestId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
  url: Types.Scalars['String']['input'];
}>;


export type ModifyRequestAttachmentMutation = { modifyRequestAttachment: { __typename: 'ModifyRequestAttachmentResult', attachment: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type RemoveRequestAttachmentMutationVariables = Types.Exact<{
  requestId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;


export type RemoveRequestAttachmentMutation = { removeRequestAttachments: { __typename: 'RemoveRequestAttachmentsResult', ok: boolean } };

export const AttachmentSnapshotFragmentDoc = gql`
    fragment AttachmentSnapshot on AttachmentSnapshot {
  id
  title
  mimeType
  url
  group
}
    `;
export const AttachmentFragmentDoc = gql`
    fragment Attachment on Attachment {
  id
  title
  mimeType
  url
  group
}
    `;
export const AttachmentsFragmentDoc = gql`
    fragment Attachments on WithAttachments {
  id
  attachments {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;
export const ItemAttachmentFragmentDoc = gql`
    fragment ItemAttachment on ItemAttachment {
  id
  title
  mimeType
  url
  group
  clientVisibility
  isProductAttachment
}
    `;
export const WithItemAttachmentsFragmentDoc = gql`
    fragment WithItemAttachments on WithItemAttachments {
  id
  attachments {
    ...ItemAttachment
  }
}
    ${ItemAttachmentFragmentDoc}`;
export const AddItemAttachmentsDocument = gql`
    mutation AddItemAttachments($itemId: ID!, $attachments: [AttachmentInput!]!) {
  addItemAttachments(itemId: $itemId, attachments: $attachments) {
    attachments {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type AddItemAttachmentsMutationFn = Apollo.MutationFunction<AddItemAttachmentsMutation, AddItemAttachmentsMutationVariables>;

/**
 * __useAddItemAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddItemAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemAttachmentsMutation, { data, loading, error }] = useAddItemAttachmentsMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddItemAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AddItemAttachmentsMutation, AddItemAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemAttachmentsMutation, AddItemAttachmentsMutationVariables>(AddItemAttachmentsDocument, options);
      }
export type AddItemAttachmentsMutationHookResult = ReturnType<typeof useAddItemAttachmentsMutation>;
export type AddItemAttachmentsMutationResult = Apollo.MutationResult<AddItemAttachmentsMutation>;
export type AddItemAttachmentsMutationOptions = Apollo.BaseMutationOptions<AddItemAttachmentsMutation, AddItemAttachmentsMutationVariables>;
export const ModifyItemAttachmentDocument = gql`
    mutation ModifyItemAttachment($itemId: ID!, $attachmentId: ID!, $url: String!) {
  modifyItemAttachment(
    itemId: $itemId
    attachmentId: $attachmentId
    input: {url: $url}
  ) {
    attachment {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyItemAttachmentMutationFn = Apollo.MutationFunction<ModifyItemAttachmentMutation, ModifyItemAttachmentMutationVariables>;

/**
 * __useModifyItemAttachmentMutation__
 *
 * To run a mutation, you first call `useModifyItemAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyItemAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyItemAttachmentMutation, { data, loading, error }] = useModifyItemAttachmentMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      attachmentId: // value for 'attachmentId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useModifyItemAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<ModifyItemAttachmentMutation, ModifyItemAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyItemAttachmentMutation, ModifyItemAttachmentMutationVariables>(ModifyItemAttachmentDocument, options);
      }
export type ModifyItemAttachmentMutationHookResult = ReturnType<typeof useModifyItemAttachmentMutation>;
export type ModifyItemAttachmentMutationResult = Apollo.MutationResult<ModifyItemAttachmentMutation>;
export type ModifyItemAttachmentMutationOptions = Apollo.BaseMutationOptions<ModifyItemAttachmentMutation, ModifyItemAttachmentMutationVariables>;
export const RemoveItemAttachmentDocument = gql`
    mutation RemoveItemAttachment($itemId: ID!, $attachmentId: ID!) {
  removeItemAttachments(itemId: $itemId, attachmentIds: [$attachmentId]) {
    ok
  }
}
    `;
export type RemoveItemAttachmentMutationFn = Apollo.MutationFunction<RemoveItemAttachmentMutation, RemoveItemAttachmentMutationVariables>;

/**
 * __useRemoveItemAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveItemAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemAttachmentMutation, { data, loading, error }] = useRemoveItemAttachmentMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useRemoveItemAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemAttachmentMutation, RemoveItemAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemAttachmentMutation, RemoveItemAttachmentMutationVariables>(RemoveItemAttachmentDocument, options);
      }
export type RemoveItemAttachmentMutationHookResult = ReturnType<typeof useRemoveItemAttachmentMutation>;
export type RemoveItemAttachmentMutationResult = Apollo.MutationResult<RemoveItemAttachmentMutation>;
export type RemoveItemAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveItemAttachmentMutation, RemoveItemAttachmentMutationVariables>;
export const AddProductAttachmentsDocument = gql`
    mutation AddProductAttachments($productId: ID!, $attachments: [AttachmentInput!]!) {
  addProductAttachments(productId: $productId, attachments: $attachments) {
    attachments {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type AddProductAttachmentsMutationFn = Apollo.MutationFunction<AddProductAttachmentsMutation, AddProductAttachmentsMutationVariables>;

/**
 * __useAddProductAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddProductAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductAttachmentsMutation, { data, loading, error }] = useAddProductAttachmentsMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddProductAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AddProductAttachmentsMutation, AddProductAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProductAttachmentsMutation, AddProductAttachmentsMutationVariables>(AddProductAttachmentsDocument, options);
      }
export type AddProductAttachmentsMutationHookResult = ReturnType<typeof useAddProductAttachmentsMutation>;
export type AddProductAttachmentsMutationResult = Apollo.MutationResult<AddProductAttachmentsMutation>;
export type AddProductAttachmentsMutationOptions = Apollo.BaseMutationOptions<AddProductAttachmentsMutation, AddProductAttachmentsMutationVariables>;
export const ModifyProductAttachmentDocument = gql`
    mutation ModifyProductAttachment($productId: ID!, $attachmentId: ID!, $url: String!) {
  modifyProductAttachment(
    productId: $productId
    attachmentId: $attachmentId
    input: {url: $url}
  ) {
    attachment {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyProductAttachmentMutationFn = Apollo.MutationFunction<ModifyProductAttachmentMutation, ModifyProductAttachmentMutationVariables>;

/**
 * __useModifyProductAttachmentMutation__
 *
 * To run a mutation, you first call `useModifyProductAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyProductAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyProductAttachmentMutation, { data, loading, error }] = useModifyProductAttachmentMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      attachmentId: // value for 'attachmentId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useModifyProductAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<ModifyProductAttachmentMutation, ModifyProductAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyProductAttachmentMutation, ModifyProductAttachmentMutationVariables>(ModifyProductAttachmentDocument, options);
      }
export type ModifyProductAttachmentMutationHookResult = ReturnType<typeof useModifyProductAttachmentMutation>;
export type ModifyProductAttachmentMutationResult = Apollo.MutationResult<ModifyProductAttachmentMutation>;
export type ModifyProductAttachmentMutationOptions = Apollo.BaseMutationOptions<ModifyProductAttachmentMutation, ModifyProductAttachmentMutationVariables>;
export const RemoveProductAttachmentDocument = gql`
    mutation RemoveProductAttachment($productId: ID!, $attachmentId: ID!) {
  removeProductAttachments(productId: $productId, attachmentIds: [$attachmentId]) {
    ok
  }
}
    `;
export type RemoveProductAttachmentMutationFn = Apollo.MutationFunction<RemoveProductAttachmentMutation, RemoveProductAttachmentMutationVariables>;

/**
 * __useRemoveProductAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveProductAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductAttachmentMutation, { data, loading, error }] = useRemoveProductAttachmentMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useRemoveProductAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProductAttachmentMutation, RemoveProductAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProductAttachmentMutation, RemoveProductAttachmentMutationVariables>(RemoveProductAttachmentDocument, options);
      }
export type RemoveProductAttachmentMutationHookResult = ReturnType<typeof useRemoveProductAttachmentMutation>;
export type RemoveProductAttachmentMutationResult = Apollo.MutationResult<RemoveProductAttachmentMutation>;
export type RemoveProductAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveProductAttachmentMutation, RemoveProductAttachmentMutationVariables>;
export const AddProjectAttachmentsDocument = gql`
    mutation AddProjectAttachments($projectId: ID!, $attachments: [AttachmentInput!]!) {
  addProjectAttachments(projectId: $projectId, attachments: $attachments) {
    attachments {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type AddProjectAttachmentsMutationFn = Apollo.MutationFunction<AddProjectAttachmentsMutation, AddProjectAttachmentsMutationVariables>;

/**
 * __useAddProjectAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddProjectAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectAttachmentsMutation, { data, loading, error }] = useAddProjectAttachmentsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddProjectAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectAttachmentsMutation, AddProjectAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectAttachmentsMutation, AddProjectAttachmentsMutationVariables>(AddProjectAttachmentsDocument, options);
      }
export type AddProjectAttachmentsMutationHookResult = ReturnType<typeof useAddProjectAttachmentsMutation>;
export type AddProjectAttachmentsMutationResult = Apollo.MutationResult<AddProjectAttachmentsMutation>;
export type AddProjectAttachmentsMutationOptions = Apollo.BaseMutationOptions<AddProjectAttachmentsMutation, AddProjectAttachmentsMutationVariables>;
export const ModifyProjectAttachmentDocument = gql`
    mutation ModifyProjectAttachment($projectId: ID!, $attachmentId: ID!, $url: String!) {
  modifyProjectAttachment(
    projectId: $projectId
    attachmentId: $attachmentId
    input: {url: $url}
  ) {
    attachment {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyProjectAttachmentMutationFn = Apollo.MutationFunction<ModifyProjectAttachmentMutation, ModifyProjectAttachmentMutationVariables>;

/**
 * __useModifyProjectAttachmentMutation__
 *
 * To run a mutation, you first call `useModifyProjectAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyProjectAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyProjectAttachmentMutation, { data, loading, error }] = useModifyProjectAttachmentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      attachmentId: // value for 'attachmentId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useModifyProjectAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<ModifyProjectAttachmentMutation, ModifyProjectAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyProjectAttachmentMutation, ModifyProjectAttachmentMutationVariables>(ModifyProjectAttachmentDocument, options);
      }
export type ModifyProjectAttachmentMutationHookResult = ReturnType<typeof useModifyProjectAttachmentMutation>;
export type ModifyProjectAttachmentMutationResult = Apollo.MutationResult<ModifyProjectAttachmentMutation>;
export type ModifyProjectAttachmentMutationOptions = Apollo.BaseMutationOptions<ModifyProjectAttachmentMutation, ModifyProjectAttachmentMutationVariables>;
export const RemoveProjectAttachmentDocument = gql`
    mutation RemoveProjectAttachment($projectId: ID!, $attachmentId: ID!) {
  removeProjectAttachments(projectId: $projectId, attachmentIds: [$attachmentId]) {
    ok
  }
}
    `;
export type RemoveProjectAttachmentMutationFn = Apollo.MutationFunction<RemoveProjectAttachmentMutation, RemoveProjectAttachmentMutationVariables>;

/**
 * __useRemoveProjectAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveProjectAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectAttachmentMutation, { data, loading, error }] = useRemoveProjectAttachmentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useRemoveProjectAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectAttachmentMutation, RemoveProjectAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectAttachmentMutation, RemoveProjectAttachmentMutationVariables>(RemoveProjectAttachmentDocument, options);
      }
export type RemoveProjectAttachmentMutationHookResult = ReturnType<typeof useRemoveProjectAttachmentMutation>;
export type RemoveProjectAttachmentMutationResult = Apollo.MutationResult<RemoveProjectAttachmentMutation>;
export type RemoveProjectAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveProjectAttachmentMutation, RemoveProjectAttachmentMutationVariables>;
export const AddRequestAttachmentsDocument = gql`
    mutation AddRequestAttachments($requestId: ID!, $attachments: [AttachmentInput!]!) {
  addRequestAttachments(requestId: $requestId, attachments: $attachments) {
    attachments {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type AddRequestAttachmentsMutationFn = Apollo.MutationFunction<AddRequestAttachmentsMutation, AddRequestAttachmentsMutationVariables>;

/**
 * __useAddRequestAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddRequestAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRequestAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRequestAttachmentsMutation, { data, loading, error }] = useAddRequestAttachmentsMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddRequestAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AddRequestAttachmentsMutation, AddRequestAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRequestAttachmentsMutation, AddRequestAttachmentsMutationVariables>(AddRequestAttachmentsDocument, options);
      }
export type AddRequestAttachmentsMutationHookResult = ReturnType<typeof useAddRequestAttachmentsMutation>;
export type AddRequestAttachmentsMutationResult = Apollo.MutationResult<AddRequestAttachmentsMutation>;
export type AddRequestAttachmentsMutationOptions = Apollo.BaseMutationOptions<AddRequestAttachmentsMutation, AddRequestAttachmentsMutationVariables>;
export const ModifyRequestAttachmentDocument = gql`
    mutation ModifyRequestAttachment($requestId: ID!, $attachmentId: ID!, $url: String!) {
  modifyRequestAttachment(
    requestId: $requestId
    attachmentId: $attachmentId
    input: {url: $url}
  ) {
    attachment {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyRequestAttachmentMutationFn = Apollo.MutationFunction<ModifyRequestAttachmentMutation, ModifyRequestAttachmentMutationVariables>;

/**
 * __useModifyRequestAttachmentMutation__
 *
 * To run a mutation, you first call `useModifyRequestAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRequestAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRequestAttachmentMutation, { data, loading, error }] = useModifyRequestAttachmentMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      attachmentId: // value for 'attachmentId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useModifyRequestAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<ModifyRequestAttachmentMutation, ModifyRequestAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyRequestAttachmentMutation, ModifyRequestAttachmentMutationVariables>(ModifyRequestAttachmentDocument, options);
      }
export type ModifyRequestAttachmentMutationHookResult = ReturnType<typeof useModifyRequestAttachmentMutation>;
export type ModifyRequestAttachmentMutationResult = Apollo.MutationResult<ModifyRequestAttachmentMutation>;
export type ModifyRequestAttachmentMutationOptions = Apollo.BaseMutationOptions<ModifyRequestAttachmentMutation, ModifyRequestAttachmentMutationVariables>;
export const RemoveRequestAttachmentDocument = gql`
    mutation RemoveRequestAttachment($requestId: ID!, $attachmentId: ID!) {
  removeRequestAttachments(requestId: $requestId, attachmentIds: [$attachmentId]) {
    ok
  }
}
    `;
export type RemoveRequestAttachmentMutationFn = Apollo.MutationFunction<RemoveRequestAttachmentMutation, RemoveRequestAttachmentMutationVariables>;

/**
 * __useRemoveRequestAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveRequestAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRequestAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRequestAttachmentMutation, { data, loading, error }] = useRemoveRequestAttachmentMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useRemoveRequestAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRequestAttachmentMutation, RemoveRequestAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRequestAttachmentMutation, RemoveRequestAttachmentMutationVariables>(RemoveRequestAttachmentDocument, options);
      }
export type RemoveRequestAttachmentMutationHookResult = ReturnType<typeof useRemoveRequestAttachmentMutation>;
export type RemoveRequestAttachmentMutationResult = Apollo.MutationResult<RemoveRequestAttachmentMutation>;
export type RemoveRequestAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveRequestAttachmentMutation, RemoveRequestAttachmentMutationVariables>;