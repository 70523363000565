import { Box } from "@mui/material";
import React from "react";

/** Circle wrapper around icon button to place on top of picture */
export const ButtonCircleWrapper = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <Box
      display="flex"
      flexGrow={0}
      flexShrink={0}
      borderRadius="100%"
      bgcolor="common.white"
    >
      {children}
    </Box>
  );
};
