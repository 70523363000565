import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps,
  Typography,
  TypographyProps,
} from "@mui/material";

interface Props {
  label: string;
  disabled?: boolean;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  labelVariant?: TypographyProps["variant"];
}

export const Switch = ({
  label,
  disabled,
  labelPlacement = "end",
  labelVariant = "body2",
  ...props
}: SwitchProps & Props) => (
  <FormControlLabel
    control={<MuiSwitch type="checkbox" disabled={disabled} {...props} />}
    label={<Typography variant={labelVariant}>{label}</Typography>}
    labelPlacement={labelPlacement}
    disabled={disabled}
  />
);
