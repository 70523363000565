/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionProcess_NextAndPreviousItemsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
  viewerDecisionRole?: Types.InputMaybe<Types.AskWhom>;
  decisionContext?: Types.InputMaybe<Types.AskWhen>;
  applyItemActions?: Types.InputMaybe<Array<Types.ApplyItemActionInput>>;
  itemUuidSeed: Types.Scalars['String']['input'];
  embeddedMode: Types.Scalars['Boolean']['input'];
  contractorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type DecisionProcess_NextAndPreviousItemsQuery = { itemNextAndPreviousDecisionItem: { __typename: 'ItemNextAndPreviousDecisionItemResult', nextItem?: { __typename: 'Item', id: string, originVersionNumber?: number | null } | null, previousItem?: { __typename: 'Item', id: string, originVersionNumber?: number | null } | null } };

export type DecisionProcess_NextPossibleItemQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  itemPathSortable?: Types.InputMaybe<Types.Scalars['String']['input']>;
  viewerDecisionRole?: Types.InputMaybe<Types.AskWhom>;
  decisionContext?: Types.InputMaybe<Types.AskWhen>;
  applyItemActions?: Types.InputMaybe<Array<Types.ApplyItemActionInput>>;
  itemUuidSeed: Types.Scalars['String']['input'];
  embeddedMode: Types.Scalars['Boolean']['input'];
  contractorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type DecisionProcess_NextPossibleItemQuery = { itemNextPossibleDecisionItem: { __typename: 'ItemNextPossibleDecisionItemResult', nextPossibleItem?: { __typename: 'Item', id: string, originVersionNumber?: number | null } | null } };


export const DecisionProcess_NextAndPreviousItemsDocument = gql`
    query DecisionProcess_NextAndPreviousItems($projectId: ID, $docId: ID!, $itemId: ID!, $viewerDecisionRole: AskWhom, $decisionContext: AskWhen, $applyItemActions: [ApplyItemActionInput!], $itemUuidSeed: String!, $embeddedMode: Boolean!, $contractorId: ID) {
  itemNextAndPreviousDecisionItem(
    projectId: $projectId
    docId: $docId
    itemId: $itemId
    viewerDecisionRole: $viewerDecisionRole
    decisionContext: $decisionContext
    applyItemActions: $applyItemActions
    itemUuidSeed: $itemUuidSeed
    embeddedMode: $embeddedMode
    contractorId: $contractorId
  ) {
    nextItem {
      id
      originVersionNumber
    }
    previousItem {
      id
      originVersionNumber
    }
  }
}
    `;

/**
 * __useDecisionProcess_NextAndPreviousItemsQuery__
 *
 * To run a query within a React component, call `useDecisionProcess_NextAndPreviousItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionProcess_NextAndPreviousItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionProcess_NextAndPreviousItemsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *      viewerDecisionRole: // value for 'viewerDecisionRole'
 *      decisionContext: // value for 'decisionContext'
 *      applyItemActions: // value for 'applyItemActions'
 *      itemUuidSeed: // value for 'itemUuidSeed'
 *      embeddedMode: // value for 'embeddedMode'
 *      contractorId: // value for 'contractorId'
 *   },
 * });
 */
export function useDecisionProcess_NextAndPreviousItemsQuery(baseOptions: Apollo.QueryHookOptions<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables> & ({ variables: DecisionProcess_NextAndPreviousItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables>(DecisionProcess_NextAndPreviousItemsDocument, options);
      }
export function useDecisionProcess_NextAndPreviousItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables>(DecisionProcess_NextAndPreviousItemsDocument, options);
        }
export function useDecisionProcess_NextAndPreviousItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables>(DecisionProcess_NextAndPreviousItemsDocument, options);
        }
export type DecisionProcess_NextAndPreviousItemsQueryHookResult = ReturnType<typeof useDecisionProcess_NextAndPreviousItemsQuery>;
export type DecisionProcess_NextAndPreviousItemsLazyQueryHookResult = ReturnType<typeof useDecisionProcess_NextAndPreviousItemsLazyQuery>;
export type DecisionProcess_NextAndPreviousItemsSuspenseQueryHookResult = ReturnType<typeof useDecisionProcess_NextAndPreviousItemsSuspenseQuery>;
export type DecisionProcess_NextAndPreviousItemsQueryResult = Apollo.QueryResult<DecisionProcess_NextAndPreviousItemsQuery, DecisionProcess_NextAndPreviousItemsQueryVariables>;
export const DecisionProcess_NextPossibleItemDocument = gql`
    query DecisionProcess_NextPossibleItem($projectId: ID, $docId: ID!, $itemId: ID, $itemPathSortable: String, $viewerDecisionRole: AskWhom, $decisionContext: AskWhen, $applyItemActions: [ApplyItemActionInput!], $itemUuidSeed: String!, $embeddedMode: Boolean!, $contractorId: ID) {
  itemNextPossibleDecisionItem(
    projectId: $projectId
    docId: $docId
    itemId: $itemId
    itemPathSortable: $itemPathSortable
    viewerDecisionRole: $viewerDecisionRole
    decisionContext: $decisionContext
    applyItemActions: $applyItemActions
    itemUuidSeed: $itemUuidSeed
    embeddedMode: $embeddedMode
    contractorId: $contractorId
  ) {
    nextPossibleItem {
      id
      originVersionNumber
    }
  }
}
    `;

/**
 * __useDecisionProcess_NextPossibleItemQuery__
 *
 * To run a query within a React component, call `useDecisionProcess_NextPossibleItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionProcess_NextPossibleItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionProcess_NextPossibleItemQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *      itemPathSortable: // value for 'itemPathSortable'
 *      viewerDecisionRole: // value for 'viewerDecisionRole'
 *      decisionContext: // value for 'decisionContext'
 *      applyItemActions: // value for 'applyItemActions'
 *      itemUuidSeed: // value for 'itemUuidSeed'
 *      embeddedMode: // value for 'embeddedMode'
 *      contractorId: // value for 'contractorId'
 *   },
 * });
 */
export function useDecisionProcess_NextPossibleItemQuery(baseOptions: Apollo.QueryHookOptions<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables> & ({ variables: DecisionProcess_NextPossibleItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables>(DecisionProcess_NextPossibleItemDocument, options);
      }
export function useDecisionProcess_NextPossibleItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables>(DecisionProcess_NextPossibleItemDocument, options);
        }
export function useDecisionProcess_NextPossibleItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables>(DecisionProcess_NextPossibleItemDocument, options);
        }
export type DecisionProcess_NextPossibleItemQueryHookResult = ReturnType<typeof useDecisionProcess_NextPossibleItemQuery>;
export type DecisionProcess_NextPossibleItemLazyQueryHookResult = ReturnType<typeof useDecisionProcess_NextPossibleItemLazyQuery>;
export type DecisionProcess_NextPossibleItemSuspenseQueryHookResult = ReturnType<typeof useDecisionProcess_NextPossibleItemSuspenseQuery>;
export type DecisionProcess_NextPossibleItemQueryResult = Apollo.QueryResult<DecisionProcess_NextPossibleItemQuery, DecisionProcess_NextPossibleItemQueryVariables>;