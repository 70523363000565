import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Stack, TextFieldProps } from "@mui/material";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import React, { CSSProperties } from "react";
import { DraftEditor } from "./DraftEditor";
import { RichTextValue } from "./RichTextValue";

interface Props {
  content?: RawDraftContentState;
  readViewContent?: RawDraftContentState;
  placeholder?: React.ReactElement;
  htmlContent?: string;
  label?: string;
  saveButtonLabel: string;
  cancelButtonLabel: string;
  onSave?: (content: RawDraftContentState) => Promise<void> | void;
  onChange?: (content: RawDraftContentState) => Promise<void> | void;
  canEdit?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  InfoButton?: React.ReactNode;
  InputProps?: TextFieldProps["InputProps"];
  renderEditIconButton?: (button: React.ReactNode) => React.ReactNode;
}

const defaultRenderButton = (button: React.ReactNode) => button;

export function RichTextEditor({
  label = "",
  content,
  readViewContent,
  placeholder,
  htmlContent,
  saveButtonLabel,
  cancelButtonLabel,
  onSave,
  onChange,
  canEdit = true,
  disabled = false,
  style,
  InfoButton,
  InputProps,
  renderEditIconButton = defaultRenderButton,
}: Props) {
  const [isEditing, setIsEditing] = React.useState(!onSave && canEdit);

  const getInitialState = React.useCallback(
    function getInitialState(): EditorState {
      if (content)
        return EditorState.createWithContent(convertFromRaw(content));
      if (htmlContent) {
        const blocksFromHTML = convertFromHTML(htmlContent);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(contentState);
      }
      return EditorState.createEmpty();
    },
    [content, htmlContent]
  );

  const [editorState, setEditorState] = React.useState(getInitialState);
  const contentState = editorState.getCurrentContent();

  const readOnly = (!onSave && !onChange) || !isEditing;

  const isNotEmpty =
    contentState.hasText() ||
    contentState.getBlockMap().first().getType() !== "unstyled";

  return !canEdit ? (
    placeholder && !isNotEmpty ? (
      placeholder
    ) : (
      <RichTextValue
        style={style}
        content={readViewContent ?? content}
        htmlContent={htmlContent}
      />
    )
  ) : (
    <div style={{ position: "relative", ...(style || {}) }}>
      {onSave ? (
        <Stack
          direction={"row"}
          spacing={0.5}
          justifyContent="flex-end"
          sx={
            isEditing
              ? { position: "absolute", top: 8, right: 8, zIndex: 3 }
              : { float: "right", position: "relative", zIndex: 3, mt: "-4px" }
          }
        >
          {isEditing ? (
            <>
              {InfoButton}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setIsEditing(false);
                  setEditorState(getInitialState());
                }}
              >
                {cancelButtonLabel}
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={async () => {
                  await onSave(convertToRaw(contentState));
                  setIsEditing(false);
                }}
              >
                {saveButtonLabel}
              </Button>
            </>
          ) : (
            <>
              {InfoButton}
              {renderEditIconButton(
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </Stack>
      ) : null}
      {isEditing ? (
        <DraftEditor
          editorState={editorState}
          onChange={value => {
            setEditorState(value);
            onChange?.(convertToRaw(value.getCurrentContent()));
          }}
          label={label}
          readOnly={readOnly}
          forceShowToolbar={!!onSave}
          disabled={disabled}
          InputProps={InputProps}
        />
      ) : placeholder && !isNotEmpty ? (
        placeholder
      ) : (
        <RichTextValue
          content={readViewContent ?? content}
          htmlContent={htmlContent}
        />
      )}
    </div>
  );
}
