import { createFilterOptions } from "@mui/material/useAutocomplete";
import { AutocompleteField, AutocompleteFieldProps } from "./AutocompleteField";

interface Props<
  Option extends { inputValue?: string },
  DisableClearable extends boolean | undefined
> extends Omit<
    AutocompleteFieldProps<Option, DisableClearable, true>,
    | "freeSolo"
    | "clearOnBlur"
    | "selectOnFocus"
    | "handleHomeEndKeys"
    | "getOptionLabel"
    | "filterOptions"
  > {
  getOptionLabel: Exclude<
    AutocompleteFieldProps<Option, DisableClearable, true>["getOptionLabel"],
    undefined
  >;
  getNewValueOption: (inputValue: string) => Option;
}

export const AutocompleteFreeSoloField = <
  Option extends { inputValue?: string },
  DisableClearable extends boolean | undefined
>({
  name,
  getOptionLabel,
  getNewValueOption,
  ...props
}: Props<Option, DisableClearable>) => {
  const filter = createFilterOptions<Option>();

  return (
    <AutocompleteField<Option, DisableClearable, true>
      {...props}
      name={name}
      freeSolo
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      getOptionLabel={getOptionLabel}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const isExisting = options.some(
          role => getOptionLabel(role) === params.inputValue
        );
        // Suggest the creation of a new value
        if (params.inputValue !== "" && !isExisting) {
          filtered.push(getNewValueOption(params.inputValue));
        }

        return filtered;
      }}
    />
  );
};
