import {
  getEditableFloat,
  getEditableInteger,
  getFloat,
  getFormattedDate,
  getFormattedDateTime,
  getFormattedDuration,
  getFormattedFloat,
  getFormattedInteger,
  getFormattedPrice,
  getFormattedTime,
  getHumanReadableDuration,
} from "@msys/formatting";
import React from "react";
import { useLocale } from "../LocaleProvider";

export function useFormatting() {
  const locale = useLocale();

  const memoizedGetFormattedPrice = React.useCallback(
    (value: Parameters<typeof getFormattedPrice>[0]) =>
      getFormattedPrice(value, locale),
    [locale]
  );
  const memoizedGetFormattedFloat = React.useCallback(
    (value: Parameters<typeof getFormattedFloat>[0]) =>
      getFormattedFloat(value, locale),
    [locale]
  );
  const memoizedGetEditableFloat = React.useCallback(
    (
      value: Parameters<typeof getEditableFloat>[0],
      returnEmptyStringIfZero?: boolean
    ) => getEditableFloat(value, locale, returnEmptyStringIfZero),
    [locale]
  );
  const memoizedGetFloat = React.useCallback(
    (value: Parameters<typeof getFloat>[0], returnZeroIfNaN?: boolean) =>
      getFloat(value, locale, returnZeroIfNaN),
    [locale]
  );
  const memoizedGetFormattedDate = React.useCallback(
    (value: Parameters<typeof getFormattedDate>[0]) =>
      getFormattedDate(value, locale),
    [locale]
  );
  const memoizedGetFormattedDateTime = React.useCallback(
    (value: Parameters<typeof getFormattedDateTime>[0]) =>
      getFormattedDateTime(value, locale),
    [locale]
  );

  return {
    getFormattedInteger,
    getEditableInteger,
    getFormattedTime,
    getFormattedDuration,
    getFormattedDate: memoizedGetFormattedDate,
    getFormattedDateTime: memoizedGetFormattedDateTime,
    getFloat: memoizedGetFloat,
    getFormattedFloat: memoizedGetFormattedFloat,
    getFormattedPrice: memoizedGetFormattedPrice,
    getEditableFloat: memoizedGetEditableFloat,
    getHumanReadableDuration: getHumanReadableDuration,
  };
}
