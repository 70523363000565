import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonProps, Icon, IconButton } from "@mui/material";

export function FilterButton(props: Omit<ButtonProps, "color" | "size">) {
  return (
    <IconButton
      {...props}
      color="primary"
      size="small"
      sx={{ flexShrink: 0, width: "34px", height: "34px" }}
    >
      <FilterIcon />
    </IconButton>
  );
}

export function FilterIcon() {
  return (
    <Icon fontSize="small" sx={{ flexShrink: 0 }}>
      <FontAwesomeIcon icon={faFilter} />
    </Icon>
  );
}
