interface Props {
  id?: string;
  innerRef: React.RefObject<HTMLInputElement>;
  accept: string;
  multiple: boolean;
  onCancel?: () => void;
  onStart?: () => void;
  resetValue?: boolean;
}
interface Single {
  multiple: false;
  onComplete: (file: File) => void | Promise<void>;
}
interface Multiple {
  multiple: true;
  onComplete: (files: File[]) => void | Promise<void>;
}

export const FileInput = ({
  id,
  innerRef,
  accept,
  multiple,
  onStart,
  onCancel,
  onComplete,
  resetValue = true,
}: Props & (Single | Multiple)) => {
  return (
    <input
      id={id}
      ref={innerRef}
      type="file"
      style={{ display: "none" }}
      multiple={multiple}
      accept={accept}
      onChange={async ({ target }) => {
        if (target?.files && target.files.length > 0) {
          onStart?.();
          if (multiple === true) {
            await onComplete?.(Array.from(target.files));
          } else {
            await onComplete?.(target.files[0]);
          }

          if (innerRef.current && resetValue) innerRef.current.value = "";
        } else {
          onCancel?.();
        }
      }}
      onClick={event => {
        event.stopPropagation();
      }}
    />
  );
};
