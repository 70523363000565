import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps as MuiAutocompleteProps,
  CircularProgress,
  TextField,
} from "@mui/material";
import { identity, isNil, merge } from "lodash";
import { matchSorter } from "match-sorter";
import React from "react";

export interface AutocompleteProps<Option, FreeSolo extends boolean = false>
  extends Omit<
    MuiAutocompleteProps<Option, false, false, FreeSolo>,
    "onChange" | "renderInput"
  > {
  onChange: (
    value: Option | AutocompleteFreeSoloValueMapping<FreeSolo> | null,
    event: React.ChangeEvent<{}>,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option> | undefined
  ) => void;
  inputLabel: string;
  required?: boolean;
  autoFocus?: boolean;
  error?: string;
  loading?: boolean;
  TextFieldProps?: React.ComponentProps<typeof TextField>;
  startAdornment?: React.ReactNode;
}

export const Autocomplete = <Option, FreeSolo extends boolean = false>({
  value = null,
  onChange,
  placeholder,
  inputLabel,
  autoFocus,
  required,
  error,
  loading,
  TextFieldProps,
  filterOptions,
  startAdornment,
  ...props
}: AutocompleteProps<Option, FreeSolo>) => {
  const handleChange = React.useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: Option | AutocompleteFreeSoloValueMapping<FreeSolo> | null,
      reason: AutocompleteChangeReason,
      details: AutocompleteChangeDetails<Option> | undefined
    ) => {
      onChange(value, event, reason, details);
    },
    [onChange]
  );

  const handleReset = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      onChange(null, event);
    },
    [onChange]
  );

  return (
    <MuiAutocomplete<Option, false, false, FreeSolo>
      {...props}
      renderInput={params => {
        return (
          <TextField
            {...merge(params, TextFieldProps)}
            placeholder={placeholder}
            label={inputLabel}
            autoFocus={autoFocus}
            required={required}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              startAdornment:
                startAdornment || params.InputProps?.startAdornment ? (
                  <>
                    {startAdornment}
                    {params.InputProps?.startAdornment}
                  </>
                ) : undefined,
            }}
          />
        );
      }}
      value={value}
      onChange={handleChange}
      onReset={handleReset}
      filterOptions={filterOptions ?? identity}
      loading={loading}
    />
  );
};

export const getFilterOptions =
  (keys: string[]) =>
  <Option,>(
    options: Option[],
    { inputValue }: { inputValue: string }
  ): Option[] => {
    return matchSorter(options, inputValue, { keys });
  };
