import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import React from "react";
import { useIconButtonDarkStyles } from "../button/useIconButtonStyles";
import { useModalStack } from "../modal/ModalStack";
import { inverseTheme } from "../Theme";

interface Props {
  handleClose: () => void;
  /*
   * Use Modal as a standalone modal which is not pushing something to stack which involves closing other modals
   * Useful to show small modal on top of existing one without closin it
   */
  notInStack?: boolean;
  onTopChange?: (isTop: boolean) => void;
}

export function BackdropModal({
  handleClose,
  notInStack,
  onTopChange,
  children,
}: React.PropsWithChildren<Props>) {
  const { isTop } = useModalStack(notInStack, onTopChange);

  return (
    <MuiThemeProvider theme={inverseTheme}>
      <Dialog
        open={true}
        onClose={(event, reason) => {
          if (reason === "backdropClick") return;
          handleClose();
        }}
        onClick={e => e.stopPropagation()}
        fullScreen={true}
        fullWidth={true}
        style={{ display: isTop ? "block" : "none" }}
        BackdropProps={{
          sx: { background: "rgba(0, 0, 0, 0.8)" },
        }}
        PaperProps={{
          variant: "elevation",
          elevation: 0,
          sx: { background: "none" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {children}
          <Box
            display="flex"
            position="absolute"
            left="8px"
            top="8px"
            zIndex={2}
          >
            <CloseButton onClick={handleClose} />
          </Box>
        </Box>
      </Dialog>
    </MuiThemeProvider>
  );
}

const CloseButton = ({ onClick }: { onClick: Function }) => {
  const { classes } = useIconButtonDarkStyles();
  return (
    <IconButton
      classes={classes}
      color="secondary"
      onClick={onClick as React.MouseEventHandler<HTMLElement>}
      aria-label={"Close"}
      size="large"
    >
      <CloseIcon />
    </IconButton>
  );
};
