import { getFormattedNumber } from "@msys/formatting";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Checkbox,
  FilledInput,
  filledInputClasses,
  Stack,
  Typography,
} from "@mui/material";
import { identity } from "lodash";
import React from "react";
import { useLocale } from "../LocaleProvider";
import {
  PopoverOpenButton,
  PopoverOpenButtonRef,
} from "../popover/PopoverOpenButton";
import { defaultFilterOptions, PopperComponent } from "./ButtonSelect";
import { SelectOption } from "./Select";

interface Props<Value> {
  label: string;
  searchLabel: string;
  noOptionsLabel: string;
  options: SelectOption<Value>[];
  value: Value[];
  onChange: (value: Value[]) => void;
  disabled?: boolean;
  filterOptions?: (
    options: SelectOption<Value>[],
    { inputValue }: { inputValue: string }
  ) => SelectOption<Value>[];
  popoverWidth?: "md" | "lg" | "xl";
}

export const ButtonSelectMultiple = <Value,>(props: Props<Value>) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const popoverOpenButtonRef = React.useRef<PopoverOpenButtonRef>();
  return (
    <PopoverOpenButton
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      content={
        <ButtonSelectMultipleForm<Value>
          {...props}
          handleClose={() => popoverOpenButtonRef.current?.handleClose()}
        />
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      popoverRef={popoverOpenButtonRef}
    >
      <Button
        size="extra-small"
        variant="text"
        color="primary"
        sx={{ textTransform: "none" }}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s ease-out",
              ...(open ? { transform: `rotate(-180deg)` } : undefined),
            }}
          />
        }
        disabled={props.disabled}
      >
        {props.label}
        {props.value.length > 0 ? ` (${props.value.length})` : ""}
      </Button>
    </PopoverOpenButton>
  );
};

function ButtonSelectMultipleForm<Value>({
  searchLabel,
  noOptionsLabel,
  options,
  value,
  onChange,
  filterOptions = defaultFilterOptions,
  handleClose,
  popoverWidth = "md",
}: Props<Value> & { handleClose(): void }) {
  const locale = useLocale();
  const [inputValue, setInputValue] = React.useState<string>("");
  const _options = filterOptions(options, { inputValue });
  return (
    <Stack
      sx={theme => ({
        width: `${theme.layout.filters.popoverWidth[popoverWidth]}px`,
      })}
      spacing={0}
      direction="column"
    >
      <Autocomplete
        open
        multiple
        fullWidth
        disableCloseOnSelect
        size="extra-small"
        onClose={(event, reason) => {
          if (reason === "escape") {
            handleClose();
          }
        }}
        inputValue={inputValue}
        onInputChange={(_, value, reason) => {
          if (reason === "input") {
            setInputValue(value);
          }
        }}
        value={_options.filter(o => value.includes(o.value))}
        onChange={(event, newValue, reason) => {
          if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Backspace" ||
              (event as React.KeyboardEvent).key === "Delete") &&
            reason === "removeOption"
          ) {
            return;
          }
          onChange(newValue.map(v => v.value));
        }}
        filterOptions={identity}
        PopperComponent={PopperComponent}
        noOptionsText={noOptionsLabel}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option.icon || option.value || option.counter !== undefined ? (
              <Stack alignItems="center" direction="row" spacing={1} flex={1}>
                <Box display="flex" alignItems="center" height={0}>
                  <Checkbox
                    edge="start"
                    size="small"
                    checked={value.includes(option.value)}
                    tabIndex={-1}
                    disableRipple
                  />
                </Box>
                {option.icon}
                <Typography variant="body2" sx={{ flex: 1 }}>
                  {option.label}
                </Typography>
                {option.counter !== undefined ? (
                  <Typography variant="caption" sx={{ color: "grey.600" }}>
                    {getFormattedNumber(option.counter, locale)}
                  </Typography>
                ) : null}
              </Stack>
            ) : (
              <Typography variant="body2">{option.label}</Typography>
            )}
          </li>
        )}
        options={_options}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <FilledInput
            size="extra-small"
            type="search"
            fullWidth
            ref={params.InputProps.ref}
            inputProps={{ enterKeyHint: "search", ...params.inputProps }}
            autoFocus
            placeholder={`${searchLabel}…`}
            sx={{
              [`&.${filledInputClasses.root}`]: {
                padding: "0 !important",
              },
            }}
          />
        )}
        sx={theme => ({
          [`&.${autocompleteClasses.root}`]: {
            paddingX: 1,
            width: "100%",
            ...(options.length < 10
              ? { height: 0, overflow: "hidden" }
              : {
                  paddingY: 1,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }),
          },
        })}
      />
    </Stack>
  );
}
