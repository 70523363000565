import { v4 } from "uuid";

export const newContent = () => {
  return {
    __typename: "Content" as const,
    id: v4(),
    title: "",
    description: "",
    group: "",
    keywords: "",
    previewImageUrl: "",
    attachments: [],
    links: [],
    youtubeVideos: [],
    richTexts: [],
  };
};

export const newContentLink = () => {
  return {
    id: v4(),
    title: "",
    description: "",
    group: "",
    url: "",
    previewImageUrl: "",
  };
};

export const newContentYoutubeVideo = () => {
  return {
    id: v4(),
    title: "",
    description: "",
    group: "",
    previewImageUrl: "",
    videoId: "",
  };
};

export const newContentAttachment = () => {
  return {
    id: v4(),
    title: "",
    description: "",
    group: "",
    previewImageUrl: "",
    url: "",
    mimeType: "",
  };
};

export const newContentRichText = () => {
  return {
    id: v4(),
    title: "",
    description: "",
    group: "",
    previewImageUrl: "",
    richText: "",
  };
};
