import { Capacitor } from "@capacitor/core";
import { getImageUrl } from "@msys/common";
import {
  Attachment,
  Attachment3dModel,
  AttachmentImage,
  GalleryCol,
  GalleryRow,
  useScreenWidth,
} from "@msys/ui";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Box, BoxProps, Button, Stack, useTheme } from "@mui/material";
import React from "react";
import {
  AttachmentPhotoUploaderWithEditor,
  AttachmentPhotoUploaderWithEditorRef,
} from "../../features/attachments/AttachmentPhotoUploaderWithEditor";
import { DragAndDropUploader } from "../../features/attachments/DragAndDropUploader";
import { useTranslate } from "@tolgee/react";
import { AttachmentUploader } from "../../features/attachments/AttachmentUploader";
import { getRotatedUrl } from "../../features/attachments/helpers";
import {
  AddAttachmentsFn,
  ModifyAttachmentFn,
  RemoveAttachmentFn,
} from "../../features/attachments/useAttachments";
import { GalleryModal } from "./GalleryModal";
import { GallerySlider } from "./GallerySlider";

interface Props {
  pictures: (AttachmentImage | Attachment3dModel)[];
  showDelete: boolean;
  showAdd: boolean;
  showRotate: boolean;
  showUpload: boolean;
  rowMaxItems?: number;
  colMaxItems?: number;
  height?: number;
  layout?: "rows" | "cols";
  useSlider?: boolean;
  uploadBoxProps?: BoxProps;
  showImageName?: boolean;
  addAttachments?: AddAttachmentsFn;
  modifyAttachment?: ModifyAttachmentFn;
  removeAttachment?: RemoveAttachmentFn;
  loading?: boolean;
}

export const PictureGallery = ({
  height,
  pictures,
  showDelete,
  showAdd,
  showRotate = true,
  showUpload = false,
  rowMaxItems = 6,
  colMaxItems = 4,
  layout = "rows",
  useSlider = true,
  uploadBoxProps,
  showImageName,
  addAttachments,
  modifyAttachment,
  removeAttachment,
  loading,
}: Props) => {
  const { t } = useTranslate("PictureGallery");

  const theme = useTheme();

  const pictureHeight =
    height ??
    (showImageName
      ? theme.layout.galleryHeight.sm
      : theme.layout.galleryHeight.xs);

  const [modalInitialIndex, setModalInitialIndex] = React.useState<
    number | null
  >(null);
  const { isMinTablet } = useScreenWidth();

  const attachmentInputRef = React.useRef<HTMLInputElement>(null);
  const attachmentPhotoUploaderRef =
    React.useRef<AttachmentPhotoUploaderWithEditorRef>(null);

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleAdd = () => {
    if (loading) return;
    if (Capacitor.isNativePlatform()) {
      attachmentPhotoUploaderRef.current?.start();
    } else {
      attachmentInputRef.current?.click();
    }
  };

  const handleAttachment = async (attachment: Attachment) => {
    if (loading) return;
    await addAttachments?.([attachment]);
  };

  const handleAttachments = async (attachments: Attachment[]) => {
    if (loading) return;
    await addAttachments?.(attachments);
  };

  const handleDelete = async (attachment: Attachment) => {
    if (loading) return;
    await removeAttachment?.(attachment.id);
  };

  const handleRotate = async (
    attachment: Attachment,
    direction: "right" | "left"
  ) => {
    if (loading) return;
    await modifyAttachment?.(
      attachment.id,
      getRotatedUrl(attachment.url, direction)
    );
  };

  return (
    <>
      {pictures.length > 0 ? (
        <Stack
          position="relative"
          spacing={0.5}
          direction={layout === "rows" ? "column" : "row"}
        >
          {useSlider ? (
            <GallerySlider
              disabled={loading}
              images={pictures}
              slideIndex={currentIndex}
              onSlideIndexChange={setCurrentIndex}
              showDownloadButton={false}
              showImageName={showImageName}
              handleAdd={showAdd && addAttachments ? handleAdd : undefined}
              handleClick={index => setModalInitialIndex(index)}
              height={pictureHeight}
              threeDInteractive={false}
            />
          ) : (
            <Box
              flex={1}
              display={"flex"}
              height={pictureHeight}
              position="relative"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setModalInitialIndex(0);
              }}
              sx={{ cursor: "pointer" }}
            >
              <img
                draggable={false}
                style={{
                  display: "flex",
                  flex: 1,
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  userSelect: "none",
                  touchAction: "none",
                  pointerEvents: "none",
                }}
                src={getImageUrl({ url: pictures[0]!.url })}
                alt={pictures[0]!.title}
              />
            </Box>
          )}

          {layout === "rows" && pictures.length > 1 && (
            <GalleryRow
              images={pictures}
              selectedIndex={useSlider ? currentIndex : undefined}
              handleClick={(index, image, isMoreAmount) => {
                if (useSlider) {
                  isMoreAmount
                    ? setModalInitialIndex(index)
                    : setCurrentIndex(index);
                } else {
                  setModalInitialIndex(index);
                }
              }}
              maxItems={rowMaxItems}
            />
          )}
          {layout === "cols" && pictures.length > 1 && (
            <GalleryCol
              images={pictures}
              selectedIndex={useSlider ? currentIndex : undefined}
              handleClick={(index, image, isMoreAmount) => {
                if (useSlider) {
                  isMoreAmount
                    ? setModalInitialIndex(index)
                    : setCurrentIndex(index);
                } else {
                  setModalInitialIndex(index);
                }
              }}
              maxItems={colMaxItems}
              containerHeight={pictureHeight}
            />
          )}
        </Stack>
      ) : showUpload && addAttachments ? (
        <Box {...uploadBoxProps}>
          {isMinTablet && !Capacitor.isNativePlatform() ? (
            <DragAndDropUploader
              multiple={true}
              accept="image/*,.heic,.heif"
              uploading={loading}
              onAttachments={handleAttachments}
              title={t("Add photos")}
              iconComponent={AddAPhotoIcon}
            />
          ) : (
            <Button
              startIcon={<AddAPhotoIcon />}
              variant="outlined"
              onClick={handleAdd}
              disabled={loading}
              fullWidth
            >
              {t("Add photos")}
            </Button>
          )}
        </Box>
      ) : null}
      {(showAdd || showUpload) &&
      addAttachments &&
      Capacitor.isNativePlatform() ? (
        <AttachmentPhotoUploaderWithEditor
          ref={attachmentPhotoUploaderRef}
          onAttachment={handleAttachment}
        />
      ) : null}
      {(showAdd || showUpload) && addAttachments ? (
        <AttachmentUploader
          innerRef={attachmentInputRef}
          accept="image/*,.heic,.heif"
          multiple={true}
          onComplete={handleAttachments}
        />
      ) : null}
      {modalInitialIndex !== null && (
        <GalleryModal
          images={pictures}
          handleAdd={showAdd && addAttachments ? handleAdd : undefined}
          handleRotate={
            showRotate && modifyAttachment ? handleRotate : undefined
          }
          handleDelete={
            showDelete && removeAttachment ? handleDelete : undefined
          }
          initialIndex={modalInitialIndex}
          disabled={loading}
          handleClose={() => setModalInitialIndex(null)}
        />
      )}
    </>
  );
};
