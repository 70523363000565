import { gql } from "@apollo/client";
import { getPictures } from "@msys/common";
import { TypographyWithMaxNumberOfLines } from "@msys/ui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, ButtonBase, Checkbox, Paper } from "@mui/material";
import { isUndefined } from "lodash";
import defaultImageUrl from "../../assets/images/no-image.png";
import { cssValue } from "../../../common/MuiThemeProvider";
import { DecisionBehaviorOfSubitems } from "../../../clients/graphqlTypes";
import { DecisionOptionGalleryItem_ItemFragment } from "./DecisionOptionGalleryItem.generated";
import { isItemGreyedOut } from "./helpers";

interface Props {
  item: DecisionOptionGalleryItem_ItemFragment;
  selected?: boolean;
  disabled?: boolean;
  behaviour: DecisionBehaviorOfSubitems;
  togglePreselection: (itemId: string, checked: boolean) => void;
  onClick(): void;
}

export function DecisionOptionGalleryItem({
  item,
  selected: isSelected = false,
  disabled,
  behaviour,
  togglePreselection,
  onClick,
}: Props) {
  const title = !isUndefined(item.pendingChangeAttributes["title"])
    ? item.pendingChangeAttributes["title"]
    : item.title;

  const pictures = getPictures(item.attachments);

  const isGreyedOut = isItemGreyedOut(item);
  const isEliminated = !!item.decisionOptionElimination;
  const isPreselected = item.decisionOptionIsPreselected ?? false;

  return (
    <Paper
      disableRipple={true}
      component={ButtonBase}
      onClick={onClick}
      sx={theme => ({
        transition: "background-color 0.2s ease-out",
        borderRadius: "4px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: isGreyedOut
          ? theme.palette.grey[100]
          : theme.palette.common.white,
        color: isEliminated ? theme.palette.grey[600] : "inherit",
        ...(!isPreselected
          ? {
              "&:hover:not(:has(button:hover)):not(:has(a:hover))": {
                backgroundColor: isGreyedOut
                  ? theme.palette.grey[200]
                  : theme.palette.grey[100],
              },
            }
          : undefined),
        ...(isPreselected
          ? {
              boxShadow: cssValue.preselectedOutlineShadow,
              borderColor: "transparent",
            }
          : undefined),
        ...(isSelected
          ? {
              boxShadow: cssValue.selectedOutlineShadow,
              borderColor: "transparent",
            }
          : undefined),
      })}
    >
      <Box
        flexShrink="0"
        flexGrow="0"
        width="100%"
        overflow="hidden"
        display="flex"
        position="relative"
        sx={{ aspectRatio: "4 / 3", borderRadius: "4px 4px 0 0" }}
      >
        <img
          draggable={false}
          src={pictures[0]?.url ?? defaultImageUrl}
          alt={pictures[0]?.title ?? ""}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "inherit",
          }}
        />
        {behaviour === "SELECT_MANY" ? (
          <Checkbox
            disabled={isEliminated || disabled}
            size="medium"
            checked={isPreselected}
            color="success"
            sx={theme => ({
              position: "absolute",
              top: "6px",
              left: "6px",
              padding: "2px",
              color: theme.palette.common.white,
              backgroundColor: "rgba(0,0,0,0.2)",
              transition: "color 0.2s ease-out, background-color 0.2s ease-out",
              "&.Mui-checked": {
                backgroundColor: "rgba(255,255,255,1)",
              },
            })}
            onChange={async (_, checked) => {
              await togglePreselection(item.id, checked);
            }}
            onClick={async e => {
              e.stopPropagation();
            }}
          />
        ) : (
          <Checkbox
            disabled={isEliminated || disabled}
            size="medium"
            checked={isPreselected}
            color="success"
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            sx={theme => ({
              position: "absolute",
              top: "8px",
              left: "8px",
              padding: 0,
              color: theme.palette.common.white,
              backgroundColor: "rgba(0,0,0,0.2)",
              transition: "color 0.2s ease-out, background-color 0.2s ease-out",
              "&.Mui-checked": {
                backgroundColor: "rgba(255,255,255,1)",
              },
            })}
            onChange={async (_, checked) => {
              await togglePreselection(item.id, checked);
            }}
            onClick={async e => {
              e.stopPropagation();
            }}
          />
        )}
      </Box>
      <Box p={1}>
        <TypographyWithMaxNumberOfLines
          variant={"h3"}
          $maxLines={2}
          textAlign="left"
        >
          {title}
        </TypographyWithMaxNumberOfLines>
      </Box>
    </Paper>
  );
}
