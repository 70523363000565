import {
  Box,
  BoxProps,
  Collapse,
  StackProps,
  SvgIconProps,
  TypographyProps,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions/transition";
import isUndefined from "lodash.isundefined";
import React from "react";
import { useUpdateEffect } from "react-use";
import { CollapseHeader } from "./CollapseHeader";
import { TypographyWithIcon } from "./text/Typography";

interface Props extends Omit<StackProps, "title"> {
  id?: string;
  Icon?: React.ReactElement;
  title: React.ReactNode;
  titleVariant?: Exclude<TypographyProps["variant"], "inherit">;
  titleColor?: TypographyProps["color"];
  iconButtonColor?: SvgIconProps["color"];
  itemCount?: number;
  isInitiallyExpanded?: boolean;
  expanded?: boolean;
  setExpanded?: (isExpanded: boolean) => void;
  CollapsedHint?: React.ReactNode;
  ActionButtons?: React.ReactNode;
  disableAutoExpand?: boolean;
  hideItemCountOnExpand?: boolean;
  collapseTimeout?: TransitionProps["timeout"] | "auto";
  fullWidth?: boolean;
}

export const CollapseSection = ({
  id,
  children,
  isInitiallyExpanded,
  expanded,
  setExpanded,
  Icon,
  title,
  titleVariant = "h4",
  titleColor = "primary",
  iconButtonColor = "primary",
  itemCount,
  CollapsedHint,
  ActionButtons,
  disableAutoExpand = false,
  hideItemCountOnExpand = false,
  collapseTimeout,
  fullWidth,
  ...props
}: React.PropsWithChildren<Props & Omit<BoxProps, "title">>) => {
  const [isExpanded, setIsExpanded] = useExpanded(
    !isUndefined(isInitiallyExpanded)
      ? isInitiallyExpanded
      : !isUndefined(itemCount)
      ? itemCount && itemCount > 0
        ? true
        : false
      : true,
    expanded,
    setExpanded
  );

  useUpdateEffect(() => {
    if (disableAutoExpand) return;

    if (itemCount && itemCount > 0) {
      setIsExpanded(true);
    } else if (itemCount === 0) {
      setIsExpanded(false);
    }
  }, [itemCount]);

  return (
    <Box id={id} width="100%">
      <CollapseHeader
        isExpandable={true}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        title={
          <TypographyWithIcon
            count={hideItemCountOnExpand && isExpanded ? undefined : itemCount}
            icon={Icon}
            variant={titleVariant}
            color={titleColor}
            fullWidth={fullWidth}
          >
            {title}
          </TypographyWithIcon>
        }
        CollapsedHint={CollapsedHint}
        ActionButton={ActionButtons}
        iconButtonColor={iconButtonColor}
        fullWidth={fullWidth}
        {...props}
      />
      <Collapse in={isExpanded} timeout={collapseTimeout}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};

function useExpanded(
  isInitiallyExpanded: boolean,
  expanded?: boolean,
  setExpanded?: (isExpanded: boolean) => void
): [boolean, (isExpanded: boolean) => void] {
  const [isExpanded, setIsExpanded] = React.useState(isInitiallyExpanded);

  if (expanded !== undefined && setExpanded !== undefined) {
    return [expanded, setExpanded];
  }

  return [isExpanded, setIsExpanded];
}
