import { MenuButton, MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { merge } from "lodash";
import React from "react";
import { ConfirmModal } from "../../commons/modals/ConfirmModal";
import { useTranslate } from "@tolgee/react";
import { ContentEditModal } from "./ContentEditModal";
import { Content } from "../../../clients/graphqlTypes";

interface Props {
  article: Content;
  onChange: (newArticle: Content, oldArticle: Content) => void;
  onDelete: (article: Content) => void;
}

export const ArticleMenuButton = ({ article, onChange, onDelete }: Props) => {
  const { t } = useTranslate(["Contents", "Global"]);
  return (
    <MenuButton>
      <ModalOpenButton
        Modal={ContentEditModal}
        modalProps={{
          title: t("Edit content", {
            ns: "Contents",
          }),
          content: article,
          confirmButtonLabel: t("Save", {
            ns: "Global",
          }),
          handleComplete: newArticle => {
            onChange(newArticle, article);
          },
        }}
      >
        <MenuItemWithIcon icon={<EditIcon />}>
          {t("Edit", {
            ns: "Contents",
          })}
        </MenuItemWithIcon>
      </ModalOpenButton>
      <ModalOpenButton
        Modal={ConfirmModal}
        modalProps={{
          title: t("Are you sure you want to delete this content?", {
            ns: "Contents",
          }),
          text: t("This can't be undone.", {
            ns: "Contents",
          }),
          handleConfirm: () => {
            onDelete(article);
          },
        }}
      >
        <MenuItemWithIcon icon={<DeleteIcon />}>
          {t("Delete", {
            ns: "Global",
          })}
        </MenuItemWithIcon>
      </ModalOpenButton>
    </MenuButton>
  );
};
