import React from "react";
import { useDropzone } from "react-dropzone";
import { Stack } from "../../commons/layout/Stack";
import { color, cssValue } from "../../../common/MuiThemeProvider";
import { useTranslate } from "@tolgee/react";
import { styled } from "@mui/material/styles";
import { transientOptions } from "../../styles";
import UploadIcon from "@mui/icons-material/Upload";
import { Typography } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";

interface Props {
  title?: string;
  size?: "small" | "large";
  uploading?: boolean;
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
  onFiles: (files: File[]) => void;
  iconComponent?: React.ComponentType<SvgIconTypeMap["props"]>;
}

/**
 * Basic drag & drop container
 */
export const DragAndDropContainer = ({
  title,
  uploading,
  disabled = false,
  accept = "*",
  size = "small",
  multiple = true,
  onFiles,
  iconComponent: Icon = AddPhotoAlternateIcon,
}: Props) => {
  const { t } = useTranslate("AttachmentField");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // @ts-ignore
    onDrop: acceptedFiles => onFiles(acceptedFiles),
    accept,
    disabled,
    multiple,
  });

  const { disabled: _1, isDragActive: _2, ...rootProps } = getRootProps();

  return (
    <DragContainer
      $disabled={disabled}
      $isDragActive={isDragActive}
      $minHeight={size === "small" ? 104 : 280}
      $size={size}
      {...rootProps}
    >
      <input {...getInputProps()} />
      <Stack alignItems="center" spacing={1} justifyContent="center" mb={1}>
        {size === "small" ? (
          <Icon color="secondary" fontSize="small" />
        ) : (
          <UploadIcon color="secondary" fontSize="small" />
        )}
        <Typography
          variant="body2"
          align="center"
          color="secondary"
          sx={{ textTransform: "uppercase" }}
        >
          {title ?? t("Choose files/photos")}
        </Typography>
      </Stack>
      {uploading ? (
        <Typography
          variant="caption"
          align="center"
          sx={{ color: color.grey, textTransform: "lowercase" }}
        >
          {t("Uploading…")}
        </Typography>
      ) : isDragActive ? (
        <Typography
          variant="caption"
          align="center"
          sx={{ color: color.grey, textTransform: "lowercase" }}
        >
          {t("Drop files here")}
        </Typography>
      ) : (
        <Typography
          variant="caption"
          align="center"
          sx={{ color: color.grey, textTransform: "lowercase" }}
        >
          {t("Or drag & drop here")}
        </Typography>
      )}
    </DragContainer>
  );
};

const DragContainer = styled("div", transientOptions)<{
  $isDragActive: boolean;
  $disabled: boolean;
  $minHeight?: number;
  $size?: "small" | "large";
}>`
  min-height: ${({ $minHeight = 104 }) => $minHeight}px;
  border-radius: ${cssValue.boxBorderRadius};
  border: 1px dashed ${color.secondary};
  background-color: ${({ $isDragActive }) =>
    $isDragActive ? color.blue10 : color.blue9};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  ${({ $disabled }) =>
    !$disabled
      ? `
        cursor: pointer;
        &:hover {
          background-color: ${color.blue10};
        }
      `
      : ""}

  ${({ $size = "small" }) =>
    $size === "large"
      ? `
        height: 100%;
        flex: 1;
      `
      : ""}

  transition: background-color 0.2s ease-out;
`;
