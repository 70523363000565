import { useScreenWidth } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button as MuiButton,
  buttonClasses,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { px } from "../../../common/MuiThemeProvider";

export interface ButtonProps extends MuiButtonProps {
  isLast?: boolean;
  isLoading?: boolean;
  marginLeft?: px;
  marginTop?: px;
}

/**
 * @deprecated Use `<LoadingButton loading={...} />` instead
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      children,
      disabled = false,
      isLoading = false,
      isLast = false,
      marginLeft,
      marginTop,
      size = "medium",
      variant = "contained",
      style,
      endIcon,
      startIcon,
      ...otherProps
    },
    ref
  ) => {
    return (
      <MuiButton
        ref={ref}
        color={color}
        disabled={disabled || isLoading}
        startIcon={startIcon}
        endIcon={isLoading ? <CircularProgress size={14} /> : endIcon}
        size={size}
        variant={variant}
        style={{
          marginTop,
          marginLeft,
          ...(isLast
            ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
            : {}),
          ...style,
        }}
        {...otherProps}
      >
        {children}
      </MuiButton>
    );
  }
);

export const ButtonLink: FC<ButtonProps & LinkProps> = ({
  children,
  onClick,
  to,
  ...otherProps
}) => {
  return (
    //@ts-ignore
    <Button component={Link} to={to} {...otherProps}>
      {children}
    </Button>
  );
};

interface ButtonCreateProps extends MuiButtonProps {
  title: string;
  compact?: true;
  Icon?: React.ReactElement;
}

export const ButtonCreate = React.forwardRef<
  HTMLButtonElement,
  ButtonCreateProps
>(
  (
    {
      title,
      variant = "contained",
      size = "small",
      color = "primary",
      children,
      compact,
      Icon,
      ...props
    },
    ref
  ) => {
    const { isMaxPhone } = useScreenWidth();
    return isMaxPhone || compact ? (
      <IconButton size="small" color={color} aria-label={title} {...props}>
        {Icon ?? <AddIcon />}
      </IconButton>
    ) : (
      <MuiButton
        ref={ref}
        style={{ whiteSpace: "nowrap" }}
        startIcon={Icon ?? <AddIcon />}
        size={size}
        variant={variant}
        color={color}
        {...props}
      >
        {title}
      </MuiButton>
    );
  }
);

interface ButtonIconWithArrowProps extends MuiButtonProps {
  Icon: React.ReactElement;
  open: boolean;
}

export const ButtonIconWithArrow = React.forwardRef<
  HTMLButtonElement,
  ButtonIconWithArrowProps
>(({ Icon, open, size = "small", variant = "text", ...props }, ref) => {
  return (
    <MuiButton
      ref={ref}
      style={{ whiteSpace: "nowrap" }}
      endIcon={
        <KeyboardArrowDown
          fontSize="small"
          sx={{
            transition: "transform 0.2s ease-out",
            ...(open ? { transform: `rotate(-180deg)` } : undefined),
          }}
        />
      }
      size={size}
      variant={variant}
      sx={{ minWidth: "auto", [`.${buttonClasses.endIcon}`]: { ml: 0 } }}
      {...props}
    >
      {Icon}
    </MuiButton>
  );
});
