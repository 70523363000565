import React from "react";
import {
  AttachmentImage,
  GalleryGridWithModalProps,
  GalleryGridWithModal,
  Attachment3dModel,
} from "@msys/ui";
import { useTranslate } from "@tolgee/react";

export const GalleryGrid = <I extends AttachmentImage | Attachment3dModel>(
  props: GalleryGridWithModalProps<I>
) => {
  const { t } = useTranslate("Global");
  return (
    <GalleryGridWithModal
      downloadTitle={t("Download")}
      addTitle={t("Add")}
      rotateTitle={t("Rotate")}
      deleteTitle={t("Delete")}
      arButtonTitle={t("View in your space")}
      {...props}
    />
  );
};
