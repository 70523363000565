import { Box } from "@mui/material";
import React from "react";
import threeDIconUrl from "../../assets/3d-icon.svg";
import threeDImageUrl from "../../assets/3d-image.jpeg";

export const ThreeDGenericImage = () => {
  return (
    <Box width="100%" height="100%" position="relative">
      <img
        draggable={false}
        src={threeDImageUrl}
        alt="3D preview"
        loading="lazy"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "relative",
          zIndex: 1,
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          backgroundColor: "rgba(0, 0, 0, 0.15)",
          zIndex: 2,
        }}
      />
      <img
        draggable={false}
        src={threeDIconUrl}
        alt="3D icon"
        loading="lazy"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "53px",
          height: "60px",
          maxWidth: "70%",
          maxHeight: "75%",
          minWidth: "40%",
          minHeight: "45%",
          zIndex: 3,
        }}
      />
    </Box>
  );
};
