/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TemplatesQuoteStatus_TemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean };

export const TemplatesQuoteStatus_TemplateFragmentDoc = gql`
    fragment TemplatesQuoteStatus_Template on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  owningSystemOrganisationId
  hasAnyPublishedVersion
  containsUnpublishedChanged
}
    `;