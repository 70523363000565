export const browserHasFlexGap = checkFlexGap();
export const browserHasInputDateTime = checkInputType("datetime-local");
export const browserHasInputTime = checkInputType("time");
export const browserHasInputDate = checkInputType("date");
export const browserHasTouch = checkTouch();
export const browserHasWheelEvent = checkWheel();

// source: https://stackoverflow.com/a/52854585
export const browserHasOnlyTouch =
  "matchMedia" in window
    ? Boolean(window.matchMedia("(any-hover: none)").matches)
    : browserHasTouch;

// source: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/flexgap.js
function checkFlexGap() {
  // create flex container with row-gap set
  const flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  // create two, elements inside it
  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode?.removeChild(flex);

  return isSupported;
}

// source: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/input/inputtypes.js
function checkInputType(type: string): boolean {
  const inputElem = document.createElement("input");
  inputElem.setAttribute("type", type);
  const smile = "1)";
  let bool = inputElem.type !== "text" && "style" in inputElem;
  if (bool) {
    inputElem.value = smile;
    inputElem.style.cssText = "position:absolute;visibility:hidden;";
    // If the upgraded input component rejects the :) text, we got a winner
    bool = inputElem.value !== smile;
  }
  return !!bool;
}

// source: https://stackoverflow.com/a/4819886
function checkTouch(): boolean {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}

// source: https://stackoverflow.com/a/4819886
function checkWheel(): boolean {
  const div = document.createElement("div");
  const hasWheel = "onwheel" in div;
  return hasWheel;
}
