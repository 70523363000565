/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PimProductCategoryFragmentDoc, ProductSearchItem__ProductSearchResultFragmentDoc } from './Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PimAddSupplierProductViewedMutationVariables = Types.Exact<{
  input: Types.PimAddSupplierProductViewedInput;
}>;


export type PimAddSupplierProductViewedMutation = { pimAddSupplierProductViewed: { __typename: 'PimAddSupplierProductViewedResult', ok: boolean } };

export type GetProductFullDescription_Product_PimCustomSupplierProduct_Fragment = { __typename: 'PimCustomSupplierProduct', id: string, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null } | null };

export type GetProductFullDescription_Product_PimSupplierProduct_Fragment = { __typename: 'PimSupplierProduct', id: string, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null } | null };

export type GetProductFullDescription_ProductFragment = GetProductFullDescription_Product_PimCustomSupplierProduct_Fragment | GetProductFullDescription_Product_PimSupplierProduct_Fragment;

export type GetProductFullDescription_ProductSearchResult_PimCustomSupplierProductSearchResult_Fragment = { __typename: 'PimCustomSupplierProductSearchResult', id: string, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null } | null };

export type GetProductFullDescription_ProductSearchResult_PimSupplierProductSearchResult_Fragment = { __typename: 'PimSupplierProductSearchResult', id: string, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null } | null };

export type GetProductFullDescription_ProductSearchResultFragment = GetProductFullDescription_ProductSearchResult_PimCustomSupplierProductSearchResult_Fragment | GetProductFullDescription_ProductSearchResult_PimSupplierProductSearchResult_Fragment;

export type ProductBreadcrumbItems__ProductCategoryTreeQueryVariables = Types.Exact<{
  categoryKey: Types.Scalars['String']['input'];
  supplierId: Types.Scalars['ID']['input'];
}>;


export type ProductBreadcrumbItems__ProductCategoryTreeQuery = { pimGetSupplierProductCategoryTree: { __typename: 'MissingCapabilities' } | { __typename: 'PimProductCategoryTreeWithSupplier', tree: { __typename: 'PimProductCategoryTree', parents: Array<{ __typename: 'PimProductCategory', id: string, parentId?: string | null, supplierId: string, key: string, title: string }>, productCategory?: { __typename: 'PimProductCategory', id: string, parentId?: string | null, supplierId: string, key: string, title: string } | null } } };

export const GetProductFullDescription_ProductFragmentDoc = gql`
    fragment GetProductFullDescription_Product on Product {
  id
  texts {
    description
    extendedDescription
  }
}
    `;
export const GetProductFullDescription_ProductSearchResultFragmentDoc = gql`
    fragment GetProductFullDescription_ProductSearchResult on ProductSearchResult {
  id
  texts {
    description
    extendedDescription
  }
}
    `;
export const PimAddSupplierProductViewedDocument = gql`
    mutation PimAddSupplierProductViewed($input: PimAddSupplierProductViewedInput!) {
  pimAddSupplierProductViewed(input: $input) {
    ok
  }
}
    `;
export type PimAddSupplierProductViewedMutationFn = Apollo.MutationFunction<PimAddSupplierProductViewedMutation, PimAddSupplierProductViewedMutationVariables>;

/**
 * __usePimAddSupplierProductViewedMutation__
 *
 * To run a mutation, you first call `usePimAddSupplierProductViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePimAddSupplierProductViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pimAddSupplierProductViewedMutation, { data, loading, error }] = usePimAddSupplierProductViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePimAddSupplierProductViewedMutation(baseOptions?: Apollo.MutationHookOptions<PimAddSupplierProductViewedMutation, PimAddSupplierProductViewedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PimAddSupplierProductViewedMutation, PimAddSupplierProductViewedMutationVariables>(PimAddSupplierProductViewedDocument, options);
      }
export type PimAddSupplierProductViewedMutationHookResult = ReturnType<typeof usePimAddSupplierProductViewedMutation>;
export type PimAddSupplierProductViewedMutationResult = Apollo.MutationResult<PimAddSupplierProductViewedMutation>;
export type PimAddSupplierProductViewedMutationOptions = Apollo.BaseMutationOptions<PimAddSupplierProductViewedMutation, PimAddSupplierProductViewedMutationVariables>;
export const ProductBreadcrumbItems__ProductCategoryTreeDocument = gql`
    query ProductBreadcrumbItems__ProductCategoryTree($categoryKey: String!, $supplierId: ID!) {
  pimGetSupplierProductCategoryTree(
    supplierId: $supplierId
    supplierProductCategoryKey: $categoryKey
  ) {
    ... on PimProductCategoryTreeWithSupplier {
      tree {
        parents {
          id
          ...PimProductCategory
        }
        productCategory {
          id
          ...PimProductCategory
        }
      }
    }
  }
}
    ${PimProductCategoryFragmentDoc}`;

/**
 * __useProductBreadcrumbItems__ProductCategoryTreeQuery__
 *
 * To run a query within a React component, call `useProductBreadcrumbItems__ProductCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBreadcrumbItems__ProductCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBreadcrumbItems__ProductCategoryTreeQuery({
 *   variables: {
 *      categoryKey: // value for 'categoryKey'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useProductBreadcrumbItems__ProductCategoryTreeQuery(baseOptions: Apollo.QueryHookOptions<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables> & ({ variables: ProductBreadcrumbItems__ProductCategoryTreeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables>(ProductBreadcrumbItems__ProductCategoryTreeDocument, options);
      }
export function useProductBreadcrumbItems__ProductCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables>(ProductBreadcrumbItems__ProductCategoryTreeDocument, options);
        }
export function useProductBreadcrumbItems__ProductCategoryTreeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables>(ProductBreadcrumbItems__ProductCategoryTreeDocument, options);
        }
export type ProductBreadcrumbItems__ProductCategoryTreeQueryHookResult = ReturnType<typeof useProductBreadcrumbItems__ProductCategoryTreeQuery>;
export type ProductBreadcrumbItems__ProductCategoryTreeLazyQueryHookResult = ReturnType<typeof useProductBreadcrumbItems__ProductCategoryTreeLazyQuery>;
export type ProductBreadcrumbItems__ProductCategoryTreeSuspenseQueryHookResult = ReturnType<typeof useProductBreadcrumbItems__ProductCategoryTreeSuspenseQuery>;
export type ProductBreadcrumbItems__ProductCategoryTreeQueryResult = Apollo.QueryResult<ProductBreadcrumbItems__ProductCategoryTreeQuery, ProductBreadcrumbItems__ProductCategoryTreeQueryVariables>;