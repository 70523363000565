import { convertFromHTML, convertToHTML } from "draft-convert";
import type {
  ContentState as DraftContentState,
  RawDraftContentState,
} from "draft-js";
import draftJs from "draft-js";
import omitDeep from "omit-deep-lodash";
import { isDeepEqual } from "react-use/lib/util";

const { ContentState, convertFromRaw, convertToRaw, Modifier, SelectionState } =
  draftJs;

// Source: https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
const prepareForRegExp = (string: string) => {
  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return string.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
};

export const replaceInText = (
  text: string = "",
  replacements: Record<string, string | undefined> = {}
): string => {
  if (!text) return text;
  return Object.keys(replacements).reduce((text: string, pattern: string) => {
    const replacement = replacements[pattern];

    if (replacement) {
      return text.replace(
        new RegExp(prepareForRegExp(pattern), "ig"),
        replacement
      );
    }

    return text;
  }, text);
};

// Source: https://github.com/facebook/draft-js/issues/2157#issuecomment-519840458
export function replaceTextInDraftJSRawContentSingleBlock(
  rawContentState: RawDraftContentState,
  replacements: { [key: string]: string | undefined }
): RawDraftContentState {
  let contentState = convertFromRaw(rawContentState);
  let blockMap = contentState.getBlockMap().toArray();
  let start;
  Object.entries(replacements).forEach(([key, value]) => {
    if (!value) return;
    if (value.includes(key)) return; // prevent from infinite cycle
    for (let blockIndex in blockMap) {
      do {
        const block = blockMap[blockIndex];
        const blockKey = block?.getKey();
        const text = block?.getText() ?? "";
        start = text.indexOf(key);
        if (blockKey && start > -1) {
          contentState = Modifier.replaceText(
            contentState,
            SelectionState.createEmpty(blockKey).merge({
              anchorOffset: start,
              focusOffset: start + key.length,
            }),
            value
          );
          blockMap = contentState.getBlockMap().toArray();
        }
      } while (start > -1);
    }
  });
  return convertToRaw(contentState);
}

export const getRawDraftStateWithReplacements = (
  blocks?: string | null | undefined,
  text?: string | null | undefined,
  textReplacements?: { [key: string]: string | undefined }
) => {
  let rawDraftContentState: RawDraftContentState | null = null;

  if (blocks) {
    try {
      rawDraftContentState = replaceTextInDraftJSRawContentSingleBlock(
        JSON.parse(blocks),
        textReplacements ?? {}
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  if (!rawDraftContentState) {
    rawDraftContentState = convertToRaw(
      ContentState.createFromText(
        replaceInText(text ?? "", textReplacements ?? {})
      )
    );
  }

  return rawDraftContentState;
};

export function textToStringifiedRawContentState(text: string) {
  return JSON.stringify(convertToRaw(ContentState.createFromText(text)));
}

// TODO: improve comparison of Draft.js raw content states
export const isEqualRawDraftContentState = (
  rawContentState1: RawDraftContentState,
  rawContentState2: RawDraftContentState
): boolean => {
  return isDeepEqual(
    omitDeep(rawContentState1, ["key"]),
    omitDeep(rawContentState2, ["key"])
  );
};

export const draftStateToHtml = (contentState: DraftContentState): string => {
  return convertToHTML({
    entityToHTML: (entity: any, originalText: any) => {
      if (entity.type === "LINK") {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      return originalText;
    },
  })(contentState);
};

export const htmlToDraftState = (html: string): DraftContentState => {
  return convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === "a") {
        return createEntity("LINK", "MUTABLE", { url: node.href });
      }
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === "blockquote") {
        return {
          type: "blockquote",
          data: {},
        };
      }
    },
  })(html);
};
