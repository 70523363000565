import { useScreenWidth } from "@msys/ui";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, ButtonProps, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { CSSProperties } from "react";

interface Props {
  onPreviousClick?: (() => void | Promise<void>) | null;
  onNextClick?: (() => void | Promise<void>) | null;
}

export const PreviousAndNextButtons = ({
  onPreviousClick,
  onNextClick,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {onPreviousClick !== undefined && (
        <PreviousButton
          handleClick={onPreviousClick}
          disabled={!onPreviousClick}
        />
      )}
      {onNextClick !== undefined && (
        <NextButton handleClick={onNextClick} disabled={!onNextClick} />
      )}
    </Stack>
  );
};

export const PreviousButton = ({
  handleClick,
  color = "primary",
  variant = "outlined",
  visibility,
  disabled,
}: {
  handleClick?: (() => void | Promise<void>) | null;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  visibility?: CSSProperties["visibility"];
  disabled?: boolean;
}) => {
  const { t } = useTranslate(["Global"]);
  const { isMinTablet } = useScreenWidth();

  return handleClick !== undefined ? (
    <Button
      disabled={disabled}
      startIcon={<ArrowBackIosNewIcon />}
      onClick={handleClick ?? undefined}
      variant={variant}
      color={color}
      sx={{
        visibility,
        ...(!isMinTablet
          ? { ".MuiButton-startIcon": { margin: 0 } }
          : undefined),
      }}
    >
      {isMinTablet &&
        t("Previous", {
          ns: "Global",
        })}
    </Button>
  ) : null;
};

export const NextButton = ({
  handleClick,
  color = "primary",
  variant = "outlined",
  disabled,
}: {
  handleClick?: (() => void | Promise<void>) | null;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  disabled?: boolean;
}) => {
  const { t } = useTranslate(["Global"]);
  const { isMinTablet } = useScreenWidth();

  return handleClick !== undefined ? (
    <Button
      disabled={disabled}
      endIcon={<ArrowForwardIosIcon />}
      onClick={handleClick ?? undefined}
      variant={variant}
      color={color}
      sx={!isMinTablet ? { ".MuiButton-endIcon": { margin: 0 } } : undefined}
    >
      {isMinTablet &&
        t("Next", {
          ns: "Global",
        })}
    </Button>
  ) : null;
};
