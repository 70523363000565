import React from "react";

export type Counters = { [key: string]: number };

export const GlobalLoadingStateContext = React.createContext<{
  counters: Counters;
  setCounters: React.Dispatch<React.SetStateAction<Counters>>;
}>({
  counters: {},
  setCounters: () => {},
});
