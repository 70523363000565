/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContentsContentFragment = { __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> };

export type Contents_Building_Fragment = { __typename: 'Building', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_CrmCompany_Fragment = { __typename: 'CrmCompany', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_CrmPerson_Fragment = { __typename: 'CrmPerson', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_Invoice_Fragment = { __typename: 'Invoice', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_InvoiceItem_Fragment = { __typename: 'InvoiceItem', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_Item_Fragment = { __typename: 'Item', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_Order_Fragment = { __typename: 'Order', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_OrderItem_Fragment = { __typename: 'OrderItem', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_PlanSession_Fragment = { __typename: 'PlanSession', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_Project_Fragment = { __typename: 'Project', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_Quote_Fragment = { __typename: 'Quote', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_QuoteTemplate_Fragment = { __typename: 'QuoteTemplate', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_Requirement_Fragment = { __typename: 'Requirement', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type Contents_ShoppableTemplate_Fragment = { __typename: 'ShoppableTemplate', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> };

export type ContentsFragment = Contents_Building_Fragment | Contents_CrmCompany_Fragment | Contents_CrmPerson_Fragment | Contents_Invoice_Fragment | Contents_InvoiceItem_Fragment | Contents_Item_Fragment | Contents_Order_Fragment | Contents_OrderItem_Fragment | Contents_PlanSession_Fragment | Contents_Project_Fragment | Contents_Quote_Fragment | Contents_QuoteTemplate_Fragment | Contents_Requirement_Fragment | Contents_ShoppableTemplate_Fragment;

export type ContentsSetMutationVariables = Types.Exact<{
  input: Types.SetContentsInput;
}>;


export type ContentsSetMutation = { setContents: { __typename: 'SetContentsResult', id: string, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }> } };

export const ContentsContentFragmentDoc = gql`
    fragment ContentsContent on Content {
  id
  title
  description
  group
  previewImageUrl
  keywords
  youtubeVideos {
    id
    title
    description
    previewImageUrl
    group
    videoId
  }
  links {
    id
    title
    description
    previewImageUrl
    group
    url
  }
  richTexts {
    id
    title
    description
    previewImageUrl
    group
    richText
  }
  attachments {
    id
    title
    description
    group
    mimeType
    previewImageUrl
    url
  }
}
    `;
export const ContentsFragmentDoc = gql`
    fragment Contents on WithContents {
  id
  contents {
    ...ContentsContent
  }
}
    ${ContentsContentFragmentDoc}`;
export const ContentsSetDocument = gql`
    mutation ContentsSet($input: SetContentsInput!) {
  setContents(input: $input) {
    id
    contents {
      ...ContentsContent
    }
  }
}
    ${ContentsContentFragmentDoc}`;
export type ContentsSetMutationFn = Apollo.MutationFunction<ContentsSetMutation, ContentsSetMutationVariables>;

/**
 * __useContentsSetMutation__
 *
 * To run a mutation, you first call `useContentsSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentsSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentsSetMutation, { data, loading, error }] = useContentsSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentsSetMutation(baseOptions?: Apollo.MutationHookOptions<ContentsSetMutation, ContentsSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContentsSetMutation, ContentsSetMutationVariables>(ContentsSetDocument, options);
      }
export type ContentsSetMutationHookResult = ReturnType<typeof useContentsSetMutation>;
export type ContentsSetMutationResult = Apollo.MutationResult<ContentsSetMutation>;
export type ContentsSetMutationOptions = Apollo.BaseMutationOptions<ContentsSetMutation, ContentsSetMutationVariables>;