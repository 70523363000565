import {
  Switch,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Theme,
  Typography,
  SwitchProps,
  TypographyProps,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useField, useFormikContext } from "formik";
import React from "react";

interface CheckboxFieldProps {
  id?: string;
  name: string;
  label?: React.ComponentProps<typeof FormControlLabel>["label"];
  labelPlacement?: React.ComponentProps<
    typeof FormControlLabel
  >["labelPlacement"];
  required?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  indeterminate?: boolean;
  size?: React.ComponentProps<typeof Checkbox>["size"];
  sx?: SxProps<Theme>;
  labelTypographyProps?: TypographyProps;
}

export const CheckboxField = ({
  name,
  label,
  disabled,
  required,
  indeterminate,
  size,
  sx,
  labelPlacement,
  labelTypographyProps,
}: CheckboxFieldProps) => {
  const [inputProps, metaProps, helperProps] = useField<boolean>(name);
  const { isSubmitting } = useFormikContext<Record<string, any>>();

  return (
    <FormControl
      variant="standard"
      disabled={disabled ?? isSubmitting}
      error={Boolean(metaProps.error)}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={inputProps.value}
            indeterminate={indeterminate}
            onChange={(event, checked) => {
              helperProps.setValue(checked);
            }}
            required={required}
            disabled={disabled ?? isSubmitting}
            size={size}
            sx={sx}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        slotProps={{ typography: labelTypographyProps }}
      />
      {metaProps.error ? (
        <FormHelperText>{metaProps.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

interface Option<Value> {
  label: FormControlLabelProps["label"];
  value: Value;
}

export const CheckboxGroupField = <Value,>({
  name,
  label,
  labelColor,
  options,
  color,
  helperText,
  disabled,
  size,
  inputType = "checkbox",
}: {
  name: string;
  label?: React.ReactNode;
  labelColor?: FormLabelProps["color"];
  options: Option<Value>[];
  color?: CheckboxProps["color"];
  helperText?: string;
  disabled?: boolean;
  size?: SwitchProps["size"];
  inputType?: "checkbox" | "switch";
}) => {
  const [inputProps, metaProps, helperProps] = useField<Value[]>(name);
  const { isSubmitting } = useFormikContext<Record<string, any>>();

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      disabled={disabled ?? isSubmitting}
      error={Boolean(metaProps.error)}
    >
      {label && (
        <FormLabel component="legend" color={labelColor} sx={{ mb: 0.5 }}>
          {label}
        </FormLabel>
      )}
      <FormGroup>
        {options.map((option, index) =>
          inputType === "checkbox" ? (
            <FormControlLabel
              control={
                <Checkbox
                  size={size}
                  color={color}
                  checked={inputProps.value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      helperProps.setValue(
                        Array.from(new Set([...inputProps.value, option.value]))
                      );
                    } else {
                      helperProps.setValue(
                        inputProps.value.filter(v => v !== option.value)
                      );
                    }
                  }}
                  disabled={disabled ?? isSubmitting}
                />
              }
              label={<Typography variant="body2">{option.label}</Typography>}
              sx={{ ml: 0, mr: 0 }}
              key={`${option.value}-${index}`}
            />
          ) : inputType === "switch" ? (
            <FormControlLabel
              control={
                <Switch
                  type="checkbox"
                  size={size}
                  color={color}
                  checked={inputProps.value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      helperProps.setValue(
                        Array.from(new Set([...inputProps.value, option.value]))
                      );
                    } else {
                      helperProps.setValue(
                        inputProps.value.filter(v => v !== option.value)
                      );
                    }
                  }}
                  disabled={disabled ?? isSubmitting}
                />
              }
              label={<Typography variant="body2">{option.label}</Typography>}
              key={`${option.value}-${index}`}
            />
          ) : null
        )}
      </FormGroup>
      {metaProps.error ?? helperText ? (
        <FormHelperText>{metaProps.error ?? helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
