import { clamp } from "lodash";
import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { FormattedInput } from "./FormattedInput";

export const FormattedPriceInput = ({
  value,
  min,
  max,
  multiplier = 1,
  onChange,
  ...props
}: {
  min?: number;
  max?: number;
  multiplier?: number;
} & Omit<
  React.ComponentProps<typeof FormattedInput<number>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue" | "getError"
>) => {
  const { getFormattedPrice, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: number | null | undefined) {
    if (value === null || value === undefined) return "";
    return getFormattedPrice(value * multiplier) ?? "";
  }

  function getEditableValue(value: number | null | undefined) {
    if (value === null || value === undefined) return "";
    return getEditableFloat(value * multiplier, false);
  }

  function getActualValue(value: string) {
    if (value === "") return null;
    const newValue = clamp(
      getFloat(value) / multiplier,
      min ?? Number.MIN_SAFE_INTEGER,
      max ?? Number.MAX_SAFE_INTEGER
    );
    return newValue;
  }

  return (
    <FormattedInput
      {...props}
      value={value}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
      onChange={onChange}
    />
  );
};
