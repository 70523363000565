export const etimUnits = [
  {
    key: "(ft².K)/W",
    value: "(ft².K)/W",
    description: "Square foot Kelvin per Watt",
    etimId: "EU571128",
  },
  {
    key: "(m².K)/W",
    value: "(m².K)/W",
    description: "Square metre Kelvin per Watt",
    etimId: "EU571060",
  },
  { key: "%", value: "%", description: "Percentage", etimId: "EU570382" },
  { key: "% m/md", value: "% m/md", description: "% m/md", etimId: "EU571045" },
  {
    key: "%/K",
    value: "%/K",
    description: "Temperature coefficient",
    etimId: "EU571030",
  },
  { key: "°", value: "°", description: "Degrees", etimId: "EU570383" },
  {
    key: "°C",
    value: "°C",
    description: "Degrees celsius",
    etimId: "EU570073",
  },
  {
    key: "°dH",
    value: "°dH",
    description: "Degrees German hardness",
    etimId: "EU571148",
  },
  {
    key: "°F",
    value: "°F",
    description: "Degrees Fahrenheit",
    etimId: "EU571087",
  },
  {
    key: "1/min",
    value: "1/min",
    description: "Per minute",
    etimId: "EU000052",
  },
  { key: "A", value: "A", description: "Ampere", etimId: "EU570459" },
  { key: "Ah", value: "Ah", description: "Ampere hour", etimId: "EU000050" },
  {
    key: "AirWatt",
    value: "AirWatt",
    description: "Airwatt",
    etimId: "EU571028",
  },
  {
    key: "AX",
    value: "AX",
    description: "Fluorescent lamp rated current",
    etimId: "EU000055",
  },
  { key: "bar", value: "bar", description: "Bar", etimId: "EU570056" },
  { key: "Bd", value: "Bd", description: "Baud", etimId: "EU000013" },
  { key: "Bft", value: "Bft", description: "Beaufort", etimId: "EU570942" },
  { key: "Bit", value: "Bit", description: "Bit", etimId: "EU570502" },
  {
    key: "c (contacts)",
    value: "c (contacts)",
    description: "c (contacts)",
    etimId: "EU570483",
  },
  {
    key: "c/h",
    value: "c/h",
    description: "Contacts per hour",
    etimId: "EU570387",
  },
  {
    key: "c/min",
    value: "c/min",
    description: "Contacts per minute",
    etimId: "EU570388",
  },
  {
    key: "cal/cm²",
    value: "cal/cm²",
    description: "Calories per square centimetre",
    etimId: "EU571173",
  },
  {
    key: "cal/ft²",
    value: "cal/ft²",
    description: "Calories per square foot",
    etimId: "EU571178",
  },
  { key: "cd", value: "cd", description: "Candela", etimId: "EU570109" },
  {
    key: "cd/ft²",
    value: "cd/ft²",
    description: "Candela per square foot",
    etimId: "EU571094",
  },
  {
    key: "cd/m²",
    value: "cd/m²",
    description: "Candela per square metre",
    etimId: "EU570257",
  },
  { key: "cm", value: "cm", description: "Centimetre", etimId: "EU570399" },
  {
    key: "cm²",
    value: "cm²",
    description: "Square centimetre",
    etimId: "EU570121",
  },
  {
    key: "cm³",
    value: "cm³",
    description: "Cubic centimetre",
    etimId: "EU570378",
  },
  {
    key: "cm4",
    value: "cm4",
    description: "Area moment of inertia (metric in cm4)",
    etimId: "EU571003",
  },
  { key: "cSt", value: "cSt", description: "centistokes", etimId: "EU570401" },
  { key: "Days", value: "Days", description: "Days", etimId: "EU571035" },
  { key: "dB", value: "dB", description: "Decibel", etimId: "EU570480" },
  {
    key: "dB(A)",
    value: "dB(A)",
    description: "Decibel A weighted",
    etimId: "EU570555",
  },
  {
    key: "dB(A) re 1 pW",
    value: "dB(A) re 1 pW",
    description: "Sound pressure level referenced to 1 picowatt",
    etimId: "EU571026",
  },
  {
    key: "dB(C)",
    value: "dB(C)",
    description: "Decibel C weighted",
    etimId: "EU571079",
  },
  { key: "dBi", value: "dBi", description: "Antenna gain", etimId: "EU571004" },
  {
    key: "dBm",
    value: "dBm",
    description: "Decibel referenced to one milliwatt",
    etimId: "EU571034",
  },
  {
    key: "dBµV",
    value: "dBµV",
    description: "Decibel microVolt",
    etimId: "EU000015",
  },
  {
    key: "DPI",
    value: "DPI",
    description: "Dots per Inch",
    etimId: "EU000006",
  },
  {
    key: "fl oz",
    value: "fl oz",
    description: "Fluid ounce",
    etimId: "EU571098",
  },
  { key: "ft", value: "ft", description: "Foot", etimId: "EU571086" },
  {
    key: "ft/min",
    value: "ft/min",
    description: "Feet per minute",
    etimId: "EU571100",
  },
  {
    key: "ft/s",
    value: "ft/s",
    description: "Feet per second",
    etimId: "EU571101",
  },
  {
    key: "ft/s²",
    value: "ft/s²",
    description: "Foot per square second",
    etimId: "EU571141",
  },
  { key: "ft²", value: "ft²", description: "Square foot", etimId: "EU571089" },
  {
    key: "ft²/ft",
    value: "ft²/ft",
    description: "Square foot per foot",
    etimId: "EU571129",
  },
  {
    key: "ft²/gal",
    value: "ft²/gal",
    description: "Square foot per gallon",
    etimId: "EU571130",
  },
  {
    key: "ft²/h",
    value: "ft²/h",
    description: "Square foot per hour",
    etimId: "EU571131",
  },
  { key: "ft³", value: "ft³", description: "Cubic foot", etimId: "EU571088" },
  {
    key: "ft³/h",
    value: "ft³/h",
    description: "Cubic feet per hour",
    etimId: "EU571095",
  },
  {
    key: "ft³/min",
    value: "ft³/min",
    description: "Cubic feet per minute",
    etimId: "EU571137",
  },
  {
    key: "ft³/s",
    value: "ft³/s",
    description: "Cubic feet per second",
    etimId: "EU571096",
  },
  {
    key: "ftH2O",
    value: "ftH2O",
    description: "Foot of water column",
    etimId: "EU571099",
  },
  { key: "g", value: "g", description: "Gram", etimId: "EU570408" },
  {
    key: "g/cm³",
    value: "g/cm³",
    description: "Gram per cubic centimetre",
    etimId: "EU570144",
  },
  {
    key: "g/h",
    value: "g/h",
    description: "Gram per hour",
    etimId: "EU571054",
  },
  {
    key: "g/kg",
    value: "g/kg",
    description: "Gram per kilogram",
    etimId: "EU570785",
  },
  {
    key: "g/l",
    value: "g/l",
    description: "Gram per litre",
    etimId: "EU571038",
  },
  {
    key: "g/m²",
    value: "g/m²",
    description: "Gram per square metre",
    etimId: "EU571002",
  },
  {
    key: "g/m³",
    value: "g/m³",
    description: "Gram per cubic metre",
    etimId: "EU571157",
  },
  {
    key: "g/min",
    value: "g/min",
    description: "Gram per minute",
    etimId: "EU571005",
  },
  { key: "gal", value: "gal", description: "Gallon", etimId: "EU571102" },
  {
    key: "gal/ft",
    value: "gal/ft",
    description: "Gallon per foot",
    etimId: "EU571176",
  },
  {
    key: "gal/h",
    value: "gal/h",
    description: "Gallon per hour",
    etimId: "EU571083",
  },
  {
    key: "gal/min",
    value: "gal/min",
    description: "Gallon per minute",
    etimId: "EU571084",
  },
  {
    key: "gal/s",
    value: "gal/s",
    description: "Gallon per second",
    etimId: "EU571103",
  },
  { key: "GB", value: "GB", description: "Gigabyte", etimId: "EU571000" },
  {
    key: "Gbit/s",
    value: "Gbit/s",
    description: "Gigabit per second",
    etimId: "EU571073",
  },
  { key: "GHz", value: "GHz", description: "GigaHertz", etimId: "EU570223" },
  {
    key: "GJ/year",
    value: "GJ/year",
    description: "Giga Joule per year",
    etimId: "EU570547",
  },
  { key: "h", value: "h", description: "Hour", etimId: "EU570410" },
  { key: "hPa", value: "hPa", description: "Hecto Pascal", etimId: "EU000002" },
  {
    key: "HRC",
    value: "HRC",
    description: "Rockwell hardness HRC",
    etimId: "EU571164",
  },
  { key: "Hz", value: "Hz", description: "Hertz", etimId: "EU570028" },
  {
    key: "Imp/kV Ah",
    value: "Imp/kV Ah",
    description: "Imp/kV Ah",
    etimId: "EU571029",
  },
  {
    key: "Imp/kWh (kvarh)",
    value: "Imp/kWh (kvarh)",
    description: "Pulse constant",
    etimId: "EU000057",
  },
  {
    key: "in/(ft.K)",
    value: "in/(ft.K)",
    description: "Inch per foot Kelvin",
    etimId: "EU571106",
  },
  {
    key: "in/ft",
    value: "in/ft",
    description: "Inch per foot",
    etimId: "EU571105",
  },
  {
    key: "in/s",
    value: "in/s",
    description: "Inch per second",
    etimId: "EU571140",
  },
  { key: "in²", value: "in²", description: "Square inch", etimId: "EU571090" },
  { key: "in³", value: "in³", description: "Cubic inch", etimId: "EU571097" },
  {
    key: "in4",
    value: "in4",
    description: "Area moment of inertia (imperial)",
    etimId: "EU571093",
  },
  { key: "inch", value: "inch", description: "Inch", etimId: "EU000051" },
  { key: "J", value: "J", description: "Joule", etimId: "EU570011" },
  {
    key: "J/(kg·K)",
    value: "J/(kg·K)",
    description: "Specific heat capacity",
    etimId: "EU571171",
  },
  { key: "K", value: "K", description: "Kelvin", etimId: "EU570076" },
  { key: "kA", value: "kA", description: "kiloampere", etimId: "EU570170" },
  {
    key: "kBit/s",
    value: "kBit/s",
    description: "kiloBit/second",
    etimId: "EU000043",
  },
  {
    key: "kByte",
    value: "kByte",
    description: "kiloByte (1000 Byte)",
    etimId: "EU000041",
  },
  {
    key: "kcmil",
    value: "kcmil",
    description: "1000 circular mil",
    etimId: "EU571070",
  },
  { key: "kg", value: "kg", description: "Kilogram", etimId: "EU570039" },
  {
    key: "kg/100 m",
    value: "kg/100 m",
    description: "Kilogram per 100 metres",
    etimId: "EU571167",
  },
  {
    key: "kg/24h",
    value: "kg/24h",
    description: "Kilogram per 24 hours",
    etimId: "EU570825",
  },
  {
    key: "kg/cm/m²",
    value: "kg/cm/m²",
    description: "Kilogram per centimetre per square metre",
    etimId: "EU571064",
  },
  {
    key: "kg/h",
    value: "kg/h",
    description: "Kilogram per hour",
    etimId: "EU570413",
  },
  {
    key: "kg/km",
    value: "kg/km",
    description: "Kilogram per kilometer",
    etimId: "EU571166",
  },
  {
    key: "kg/m",
    value: "kg/m",
    description: "Kilogram/metre",
    etimId: "EU570254",
  },
  {
    key: "kg/m²",
    value: "kg/m²",
    description: "Kilogram per square metre",
    etimId: "EU571046",
  },
  {
    key: "kg/m³",
    value: "kg/m³",
    description: "Kilogram per cubic metre",
    etimId: "EU570006",
  },
  {
    key: "kg/s",
    value: "kg/s",
    description: "Kilogram per second",
    etimId: "EU571051",
  },
  { key: "kHz", value: "kHz", description: "kiloHertz", etimId: "EU570224" },
  { key: "kJ", value: "kJ", description: "Kilojoule", etimId: "EU571075" },
  {
    key: "kJ/ft²",
    value: "kJ/ft²",
    description: "Kilojoule per square foot",
    etimId: "EU571179",
  },
  {
    key: "kJ/kg",
    value: "kJ/kg",
    description: "Kilojoule per kilogram",
    etimId: "EU571158",
  },
  {
    key: "kJ/lb",
    value: "kJ/lb",
    description: "Kilojoule per pound",
    etimId: "EU571162",
  },
  {
    key: "kJ/m²",
    value: "kJ/m²",
    description: "Kilojoule per square metre",
    etimId: "EU571074",
  },
  {
    key: "kJ/Ohm",
    value: "kJ/Ohm",
    description: "Kilojoule per Ohm",
    etimId: "EU571049",
  },
  {
    key: "km/h",
    value: "km/h",
    description: "Kilometre per hour",
    etimId: "EU571136",
  },
  { key: "kN", value: "kN", description: "Kilonewton", etimId: "EU570508" },
  {
    key: "kN/m²",
    value: "kN/m²",
    description: "Kilonewton per square metre",
    etimId: "EU571032",
  },
  { key: "kOhm", value: "kOhm", description: "Kilo-Ohm", etimId: "EU000053" },
  { key: "kPa", value: "kPa", description: "Kilo Pascal", etimId: "EU570416" },
  { key: "kV", value: "kV", description: "Kilovolt", etimId: "EU570193" },
  {
    key: "kVA",
    value: "kVA",
    description: "Kilovolt-Ampere",
    etimId: "EU000018",
  },
  {
    key: "kvar",
    value: "kvar",
    description: "Kilo-blindpower",
    etimId: "EU000003",
  },
  { key: "kW", value: "kW", description: "Kilowatt", etimId: "EU570418" },
  {
    key: "kW/(ft³/s)",
    value: "kW/(ft³/s)",
    description: "Kilowatt per cubic foot per second",
    etimId: "EU571107",
  },
  {
    key: "kW/(m³/s)",
    value: "kW/(m³/s)",
    description: "kW/(m³ per second)",
    etimId: "EU570931",
  },
  {
    key: "kW/24h",
    value: "kW/24h",
    description: "Kilowatt per 24 hours",
    etimId: "EU000058",
  },
  {
    key: "kW/ft²",
    value: "kW/ft²",
    description: "Kilowatt per square foot",
    etimId: "EU571108",
  },
  {
    key: "kW/m²",
    value: "kW/m²",
    description: "Kilowatt per square metre",
    etimId: "EU571007",
  },
  {
    key: "kWh",
    value: "kWh",
    description: "Kilowatt hour",
    etimId: "EU570419",
  },
  {
    key: "kWp",
    value: "kWp",
    description: "Kilowatt peak n(kWh/d)",
    etimId: "EU571078",
  },
  { key: "l", value: "l", description: "Litre", etimId: "EU570403" },
  {
    key: "l/h",
    value: "l/h",
    description: "Litre per hour",
    etimId: "EU000038",
  },
  {
    key: "l/m",
    value: "l/m",
    description: "Litre per metre",
    etimId: "EU571175",
  },
  {
    key: "l/min",
    value: "l/min",
    description: "Litre per minute",
    etimId: "EU000045",
  },
  {
    key: "l/s",
    value: "l/s",
    description: "Litre per second",
    etimId: "EU570449",
  },
  {
    key: "lb",
    value: "lb",
    description: "Pound (imperial)",
    etimId: "EU570420",
  },
  {
    key: "lb/100 ft",
    value: "lb/100 ft",
    description: "Pound per 100 feet",
    etimId: "EU571168",
  },
  {
    key: "lb/24h",
    value: "lb/24h",
    description: "Pound per 24 hour",
    etimId: "EU571118",
  },
  {
    key: "lb/ft",
    value: "lb/ft",
    description: "Pound per foot",
    etimId: "EU571120",
  },
  {
    key: "lb/ft²",
    value: "lb/ft²",
    description: "Pound per square foot",
    etimId: "EU571124",
  },
  {
    key: "lb/ft³",
    value: "lb/ft³",
    description: "Pound per cubic foot",
    etimId: "EU571085",
  },
  {
    key: "lb/h",
    value: "lb/h",
    description: "Pound per hour",
    etimId: "EU571121",
  },
  {
    key: "lb/in/ft²",
    value: "lb/in/ft²",
    description: "Pound per inch per square foot",
    etimId: "EU571122",
  },
  {
    key: "lb/in³",
    value: "lb/in³",
    description: "Pound per cubic inch",
    etimId: "EU571119",
  },
  {
    key: "lb/mile",
    value: "lb/mile",
    description: "Pound per mile",
    etimId: "EU571165",
  },
  {
    key: "lb/s",
    value: "lb/s",
    description: "Pound per second",
    etimId: "EU571123",
  },
  { key: "lbf", value: "lbf", description: "Pound-force", etimId: "EU571185" },
  {
    key: "lbf·ft",
    value: "lbf·ft",
    description: "Pound-force-foot",
    etimId: "EU571125",
  },
  {
    key: "lbf/ft",
    value: "lbf/ft",
    description: "Pound-force per foot",
    etimId: "EU571126",
  },
  {
    key: "lbf/ft²",
    value: "lbf/ft²",
    description: "Pound-force per square foot",
    etimId: "EU571116",
  },
  {
    key: "lbf/in",
    value: "lbf/in",
    description: "Pound-force per inch",
    etimId: "EU571115",
  },
  { key: "lm", value: "lm", description: "Lumen", etimId: "EU570108" },
  { key: "lm/W", value: "lm/W", description: "Lumen/Watt", etimId: "EU000004" },
  { key: "lx", value: "lx", description: "Lux", etimId: "EU570106" },
  { key: "m", value: "m", description: "Metre", etimId: "EU570036" },
  {
    key: "m rad",
    value: "m rad",
    description: "Milliradians",
    etimId: "EU571076",
  },
  {
    key: "m/min",
    value: "m/min",
    description: "Metre per minute",
    etimId: "EU571008",
  },
  {
    key: "m/Nm²",
    value: "m/Nm²",
    description: "Meters per newton meters squared",
    etimId: "EU571184",
  },
  {
    key: "m/s",
    value: "m/s",
    description: "Metre per second",
    etimId: "EU570376",
  },
  {
    key: "m/s²",
    value: "m/s²",
    description: "Metre per square second",
    etimId: "EU571080",
  },
  { key: "m²", value: "m²", description: "Square metre", etimId: "EU570097" },
  {
    key: "m²/h",
    value: "m²/h",
    description: "Square metre per hour",
    etimId: "EU571059",
  },
  {
    key: "m²/l",
    value: "m²/l",
    description: "Square metre per litre",
    etimId: "EU571037",
  },
  {
    key: "m²/m",
    value: "m²/m",
    description: "Square metre per metre",
    etimId: "EU571036",
  },
  { key: "m³", value: "m³", description: "Cubic metre", etimId: "EU570090" },
  {
    key: "m³/h",
    value: "m³/h",
    description: "Cubic metre per hour",
    etimId: "EU570451",
  },
  {
    key: "m³/m²h50Pa",
    value: "m³/m²h50Pa",
    description: "m³/m²h50Pa",
    etimId: "EU571147",
  },
  {
    key: "m³/min",
    value: "m³/min",
    description: "Cubic metre per minute",
    etimId: "EU571135",
  },
  {
    key: "m³/s",
    value: "m³/s",
    description: "Cubic metre per second",
    etimId: "EU571050",
  },
  { key: "mA", value: "mA", description: "Milliampere", etimId: "EU570174" },
  {
    key: "mAh",
    value: "mAh",
    description: "Milliampere hour",
    etimId: "EU000025",
  },
  { key: "MB", value: "MB", description: "Megabyte", etimId: "EU000042" },
  { key: "mbar", value: "mbar", description: "Millibar", etimId: "EU570336" },
  {
    key: "Mbit/s",
    value: "Mbit/s",
    description: "Megabit per second",
    etimId: "EU571001",
  },
  { key: "mg", value: "mg", description: "Milligram", etimId: "EU571047" },
  {
    key: "mg/l",
    value: "mg/l",
    description: "Milligram per litre",
    etimId: "EU571153",
  },
  { key: "mH", value: "mH", description: "Millihenry", etimId: "EU571022" },
  {
    key: "mH2O",
    value: "mH2O",
    description: "Metre of water column",
    etimId: "EU571041",
  },
  { key: "MHz", value: "MHz", description: "Megahertz", etimId: "EU570225" },
  {
    key: "mil",
    value: "mil",
    description: "millesimus (1/1.000 inch)",
    etimId: "EU571142",
  },
  { key: "min", value: "min", description: "Minute", etimId: "EU570422" },
  { key: "MJ", value: "MJ", description: "Megajoule", etimId: "EU571077" },
  { key: "ml", value: "ml", description: "Millilitre", etimId: "EU570380" },
  { key: "mm", value: "mm", description: "Millimetre", etimId: "EU570448" },
  {
    key: "mm/(m.K)",
    value: "mm/(m.K)",
    description: "Millimetre per metre Kelvin",
    etimId: "EU570551",
  },
  {
    key: "mm/m",
    value: "mm/m",
    description: "Millimetre per metre",
    etimId: "EU571061",
  },
  {
    key: "mm/min",
    value: "mm/min",
    description: "Millimeter per minute",
    etimId: "EU571146",
  },
  {
    key: "mm/s",
    value: "mm/s",
    description: "Millimeter per second",
    etimId: "EU571139",
  },
  {
    key: "mm²",
    value: "mm²",
    description: "Square millimetre",
    etimId: "EU570126",
  },
  {
    key: "mm³",
    value: "mm³",
    description: "Cubic millimetre",
    etimId: "EU571039",
  },
  {
    key: "mm4",
    value: "mm4",
    description: "Area moment of inertia (metric in mm4)",
    etimId: "EU571040",
  },
  {
    key: "MN/ft³",
    value: "MN/ft³",
    description: "Meganewton per cubic feet",
    etimId: "EU571181",
  },
  {
    key: "MN/m³",
    value: "MN/m³",
    description: "Meganewton per cubic metre",
    etimId: "EU571180",
  },
  { key: "mOhm", value: "mOhm", description: "Milli-Ohm", etimId: "EU570199" },
  { key: "MOhm", value: "MOhm", description: "Mega-Ohm", etimId: "EU570200" },
  {
    key: "mol/l",
    value: "mol/l",
    description: "Mole per litre",
    etimId: "EU571189",
  },
  { key: "Month", value: "Month", description: "Month", etimId: "EU571066" },
  { key: "MP", value: "MP", description: "Megapixel", etimId: "EU571048" },
  {
    key: "mph",
    value: "mph",
    description: "Miles per hour",
    etimId: "EU571138",
  },
  { key: "ms", value: "ms", description: "Millisecond", etimId: "EU570169" },
  {
    key: "mS/cm",
    value: "mS/cm",
    description: "Millisiemens per centimeter",
    etimId: "EU571188",
  },
  { key: "mV", value: "mV", description: "Millivolt", etimId: "EU570515" },
  { key: "mW", value: "mW", description: "milliwatt", etimId: "EU571067" },
  { key: "MW", value: "MW", description: "Megawatt", etimId: "EU571182" },
  { key: "N", value: "N", description: "Newton", etimId: "EU570020" },
  {
    key: "N/m",
    value: "N/m",
    description: "Newton per metre",
    etimId: "EU571177",
  },
  {
    key: "N/m²",
    value: "N/m²",
    description: "Newton per square metre",
    etimId: "EU571057",
  },
  {
    key: "N/mm",
    value: "N/mm",
    description: "Newton per millimetre",
    etimId: "EU571053",
  },
  {
    key: "N/mm²",
    value: "N/mm²",
    description: "Newton per square millimetre",
    etimId: "EU571044",
  },
  { key: "nm", value: "nm", description: "Nanometre", etimId: "EU000056" },
  { key: "Nm", value: "Nm", description: "Newtonmetre", etimId: "EU570245" },
  {
    key: "nmi",
    value: "nmi",
    description: "Nautical mile",
    etimId: "EU571033",
  },
  { key: "ns", value: "ns", description: "Nanosecond", etimId: "EU571156" },
  { key: "Ohm", value: "Ohm", description: "Ohm", etimId: "EU570104" },
  {
    key: "Ohm-m",
    value: "Ohm-m",
    description: "Ohm-metre",
    etimId: "EU571151",
  },
  {
    key: "Ohm/km",
    value: "Ohm/km",
    description: "Ohm per kilometer",
    etimId: "EU571143",
  },
  {
    key: "Ohm/mile",
    value: "Ohm/mile",
    description: "Ohm per mile",
    etimId: "EU571144",
  },
  { key: "oz", value: "oz", description: "Ounce", etimId: "EU571109" },
  {
    key: "oz/ft²",
    value: "oz/ft²",
    description: "Ounce per square foot",
    etimId: "EU571114",
  },
  {
    key: "oz/ft³",
    value: "oz/ft³",
    description: "Ounce per cubic feet",
    etimId: "EU571161",
  },
  {
    key: "oz/gal",
    value: "oz/gal",
    description: "Ounce per gallon",
    etimId: "EU571110",
  },
  {
    key: "oz/h",
    value: "oz/h",
    description: "Ounce per hour",
    etimId: "EU571111",
  },
  {
    key: "oz/in³",
    value: "oz/in³",
    description: "Ounce per cubic inch",
    etimId: "EU571092",
  },
  {
    key: "oz/lb",
    value: "oz/lb",
    description: "Ounce per pound",
    etimId: "EU571113",
  },
  {
    key: "oz/min",
    value: "oz/min",
    description: "Ounce per minute",
    etimId: "EU571112",
  },
  { key: "Pa", value: "Pa", description: "Pascal", etimId: "EU570492" },
  {
    key: "Pa-s",
    value: "Pa-s",
    description: "Pascal-second",
    etimId: "EU571052",
  },
  {
    key: "ppm",
    value: "ppm",
    description: "parts per million",
    etimId: "EU570442",
  },
  {
    key: "psi (lbf/in²)",
    value: "psi (lbf/in²)",
    description: "Pound-force per square inch",
    etimId: "EU570428",
  },
  {
    key: "PSU",
    value: "PSU",
    description: "Practical Salinity Unit",
    etimId: "EU571152",
  },
  { key: "px", value: "px", description: "Pixels", etimId: "EU571068" },
  { key: "s", value: "s", description: "Second", etimId: "EU570103" },
  { key: "t", value: "t", description: "Metric ton", etimId: "EU570438" },
  { key: "TB", value: "TB", description: "Terabyte", etimId: "EU571072" },
  {
    key: "TPI",
    value: "TPI",
    description: "Threads per inch",
    etimId: "EU571031",
  },
  { key: "US t", value: "US t", description: "Short ton", etimId: "EU571127" },
  { key: "V", value: "V", description: "Volt", etimId: "EU570079" },
  { key: "VA", value: "VA", description: "Voltampere", etimId: "EU000022" },
  { key: "W", value: "W", description: "Watt", etimId: "EU570054" },
  {
    key: "W/(ft.K)",
    value: "W/(ft.K)",
    description: "Watt per foot Kelvin",
    etimId: "EU571132",
  },
  {
    key: "W/(ft².K)",
    value: "W/(ft².K)",
    description: "Watt per square foot Kelvin",
    etimId: "EU571091",
  },
  {
    key: "W/(ft².K²)",
    value: "W/(ft².K²)",
    description: "Watt per square foot Kelvin²",
    etimId: "EU571134",
  },
  {
    key: "W/(m.K)",
    value: "W/(m.K)",
    description: "Watt per meter Kelvin",
    etimId: "EU570374",
  },
  {
    key: "W/(m².K)",
    value: "W/(m².K)",
    description: "Watt / m² Kelvin",
    etimId: "EU570133",
  },
  {
    key: "W/(m².K²)",
    value: "W/(m².K²)",
    description: "Watt / m² Kelvin²",
    etimId: "EU571065",
  },
  {
    key: "W/ft",
    value: "W/ft",
    description: "Watt per foot",
    etimId: "EU571082",
  },
  {
    key: "W/ft²",
    value: "W/ft²",
    description: "Watt per square foot",
    etimId: "EU571133",
  },
  {
    key: "W/K",
    value: "W/K",
    description: "Watt per Kelvin",
    etimId: "EU570542",
  },
  {
    key: "W/m",
    value: "W/m",
    description: "Watt per metre",
    etimId: "EU000020",
  },
  {
    key: "W/m²",
    value: "W/m²",
    description: "Watt per square metre",
    etimId: "EU570235",
  },
  { key: "Wh", value: "Wh", description: "Watt hour", etimId: "EU571145" },
  {
    key: "Wp",
    value: "Wp",
    description: "Watt peak (Wh/d)",
    etimId: "EU000059",
  },
  { key: "Year", value: "Year", description: "Year", etimId: "EU571069" },
  { key: "µF", value: "µF", description: "Mikro-Farad", etimId: "EU000054" },
  {
    key: "µg/m³",
    value: "µg/m³",
    description: "Microgram per cubic metre",
    etimId: "EU571159",
  },
  { key: "µH", value: "µH", description: "Micro Henry", etimId: "EU000032" },
  { key: "µm", value: "µm", description: "Micrometre", etimId: "EU570499" },
  {
    key: "µoz/ft³",
    value: "µoz/ft³",
    description: "Micro ounce per cubic feet",
    etimId: "EU571160",
  },
  {
    key: "µS/cm",
    value: "µS/cm",
    description: "Microsiemens per centimeter",
    etimId: "EU571154",
  },
  { key: "μT", value: "μT", description: "Microtesla", etimId: "EU571183" },
];
export type EtimKey =
  | "(ft².K)/W"
  | "(m².K)/W"
  | "%"
  | "% m/md"
  | "%/K"
  | "°"
  | "°C"
  | "°dH"
  | "°F"
  | "1/min"
  | "A"
  | "Ah"
  | "AirWatt"
  | "AX"
  | "bar"
  | "Bd"
  | "Bft"
  | "Bit"
  | "c (contacts)"
  | "c/h"
  | "c/min"
  | "cal/cm²"
  | "cal/ft²"
  | "cd"
  | "cd/ft²"
  | "cd/m²"
  | "cm"
  | "cm²"
  | "cm³"
  | "cm4"
  | "cSt"
  | "Days"
  | "dB"
  | "dB(A)"
  | "dB(A) re 1 pW"
  | "dB(C)"
  | "dBi"
  | "dBm"
  | "dBµV"
  | "DPI"
  | "fl oz"
  | "ft"
  | "ft/min"
  | "ft/s"
  | "ft/s²"
  | "ft²"
  | "ft²/ft"
  | "ft²/gal"
  | "ft²/h"
  | "ft³"
  | "ft³/h"
  | "ft³/min"
  | "ft³/s"
  | "ftH2O"
  | "g"
  | "g/cm³"
  | "g/h"
  | "g/kg"
  | "g/l"
  | "g/m²"
  | "g/m³"
  | "g/min"
  | "gal"
  | "gal/ft"
  | "gal/h"
  | "gal/min"
  | "gal/s"
  | "GB"
  | "Gbit/s"
  | "GHz"
  | "GJ/year"
  | "h"
  | "hPa"
  | "HRC"
  | "Hz"
  | "Imp/kV Ah"
  | "Imp/kWh (kvarh)"
  | "in/(ft.K)"
  | "in/ft"
  | "in/s"
  | "in²"
  | "in³"
  | "in4"
  | "inch"
  | "J"
  | "J/(kg·K)"
  | "K"
  | "kA"
  | "kBit/s"
  | "kByte"
  | "kcmil"
  | "kg"
  | "kg/100 m"
  | "kg/24h"
  | "kg/cm/m²"
  | "kg/h"
  | "kg/km"
  | "kg/m"
  | "kg/m²"
  | "kg/m³"
  | "kg/s"
  | "kHz"
  | "kJ"
  | "kJ/ft²"
  | "kJ/kg"
  | "kJ/lb"
  | "kJ/m²"
  | "kJ/Ohm"
  | "km/h"
  | "kN"
  | "kN/m²"
  | "kOhm"
  | "kPa"
  | "kV"
  | "kVA"
  | "kvar"
  | "kW"
  | "kW/(ft³/s)"
  | "kW/(m³/s)"
  | "kW/24h"
  | "kW/ft²"
  | "kW/m²"
  | "kWh"
  | "kWp"
  | "l"
  | "l/h"
  | "l/m"
  | "l/min"
  | "l/s"
  | "lb"
  | "lb/100 ft"
  | "lb/24h"
  | "lb/ft"
  | "lb/ft²"
  | "lb/ft³"
  | "lb/h"
  | "lb/in/ft²"
  | "lb/in³"
  | "lb/mile"
  | "lb/s"
  | "lbf"
  | "lbf·ft"
  | "lbf/ft"
  | "lbf/ft²"
  | "lbf/in"
  | "lm"
  | "lm/W"
  | "lx"
  | "m"
  | "m rad"
  | "m/min"
  | "m/Nm²"
  | "m/s"
  | "m/s²"
  | "m²"
  | "m²/h"
  | "m²/l"
  | "m²/m"
  | "m³"
  | "m³/h"
  | "m³/m²h50Pa"
  | "m³/min"
  | "m³/s"
  | "mA"
  | "mAh"
  | "MB"
  | "mbar"
  | "Mbit/s"
  | "mg"
  | "mg/l"
  | "mH"
  | "mH2O"
  | "MHz"
  | "mil"
  | "min"
  | "MJ"
  | "ml"
  | "mm"
  | "mm/(m.K)"
  | "mm/m"
  | "mm/min"
  | "mm/s"
  | "mm²"
  | "mm³"
  | "mm4"
  | "MN/ft³"
  | "MN/m³"
  | "mOhm"
  | "MOhm"
  | "mol/l"
  | "Month"
  | "MP"
  | "mph"
  | "ms"
  | "mS/cm"
  | "mV"
  | "mW"
  | "MW"
  | "N"
  | "N/m"
  | "N/m²"
  | "N/mm"
  | "N/mm²"
  | "nm"
  | "Nm"
  | "nmi"
  | "ns"
  | "Ohm"
  | "Ohm-m"
  | "Ohm/km"
  | "Ohm/mile"
  | "oz"
  | "oz/ft²"
  | "oz/ft³"
  | "oz/gal"
  | "oz/h"
  | "oz/in³"
  | "oz/lb"
  | "oz/min"
  | "Pa"
  | "Pa-s"
  | "ppm"
  | "psi (lbf/in²)"
  | "PSU"
  | "px"
  | "s"
  | "t"
  | "TB"
  | "TPI"
  | "US t"
  | "V"
  | "VA"
  | "W"
  | "W/(ft.K)"
  | "W/(ft².K)"
  | "W/(ft².K²)"
  | "W/(m.K)"
  | "W/(m².K)"
  | "W/(m².K²)"
  | "W/ft"
  | "W/ft²"
  | "W/K"
  | "W/m"
  | "W/m²"
  | "Wh"
  | "Wp"
  | "Year"
  | "µF"
  | "µg/m³"
  | "µH"
  | "µm"
  | "µoz/ft³"
  | "µS/cm"
  | "μT";
