import React from "react";

export const PathForPdf = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <span
      style={{
        marginRight: 4,
        fontSize: "0.75em",
        lineHeight: "1em",
      }}
    >
      {children}
    </span>
  );
};

export const TitleWithPathForPdf = ({
  title,
  pathForPdf,
}: {
  title: string;
  pathForPdf: string;
}) => {
  return (
    <div style={{ wordBreak: "break-word" }}>
      {pathForPdf ? <PathForPdf>{pathForPdf}</PathForPdf> : null}
      {title}
    </div>
  );
};
