import { getImageUrl } from "@msys/common";
import { ImageList } from "@mui/material";
import React from "react";
import {
  Attachment3dModel,
  AttachmentImage,
  is3dModel,
  isImage,
} from "../attachments/helpers";
import { ImageListItem } from "../image/ImageListItem";
import { ThreeDGenericImage } from "../threeD/ThreeDGenericImage";

const DEFAULT_IMAGE_SIZE = 240; // TODO: is this a good generic value

export interface GalleryRowProps<
  I extends AttachmentImage | Attachment3dModel
> {
  images: I[];
  handleClick?:
    | null
    | ((index: number, image: I, isMoreAmount: boolean) => void);
  maxItems?: number;
  selectedIndex?: number;
  imageHeight?: number;
  aspectRatio?: React.CSSProperties["aspectRatio"];
  style?: React.CSSProperties;
  idPrefix?: string;
}

export const GalleryRow = <I extends AttachmentImage | Attachment3dModel>({
  images,
  handleClick,
  maxItems,
  selectedIndex,
  imageHeight,
  aspectRatio,
  style,
  idPrefix = "msys-gallery-row-",
}: GalleryRowProps<I>) => {
  const imagesList = maxItems ? images.slice(0, maxItems) : images;
  const width = maxItems
    ? "100%"
    : imageHeight
    ? images.length * imageHeight + (images.length - 1) * 4
    : "100%";
  const height = imageHeight;
  return images.length > 0 ? (
    <ImageList
      style={{
        height,
        width,
        flexGrow: 0,
        flexShrink: 0,
        userSelect: "none",
        ...style,
      }}
      cols={maxItems ?? images.length}
      rowHeight={imageHeight}
    >
      {imagesList.map((image, index) => {
        const isMoreAmount = Boolean(
          maxItems &&
            images.length > maxItems &&
            index === imagesList.length - 1
        );
        return (
          <ImageListItem
            key={image.id}
            id={`${idPrefix}${image.id}`}
            onClick={
              handleClick
                ? e => {
                    e.stopPropagation();
                    handleClick(index, image, isMoreAmount);
                  }
                : undefined
            }
            role={
              Boolean(handleClick) && selectedIndex !== index
                ? "button"
                : "initial"
            }
            $clickable
            $selected={selectedIndex === index}
            $moreAmount={
              isMoreAmount ? Math.min(images.length - maxItems!, 99) : undefined
            }
            $aspectRatio={aspectRatio}
          >
            {isImage(image) ? (
              <img
                draggable={false}
                src={getImageUrl({
                  url: image.url,
                  height: height ?? DEFAULT_IMAGE_SIZE,
                })}
                srcSet={`${getImageUrl({
                  url: image.url,
                  height: height ?? DEFAULT_IMAGE_SIZE,
                  scaling: 2,
                })} 2x`}
                alt={image.title}
                loading="lazy"
              />
            ) : is3dModel(image) ? (
              <ThreeDGenericImage />
            ) : null}
          </ImageListItem>
        );
      })}
    </ImageList>
  ) : null;
};
