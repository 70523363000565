import React from "react";
import { useSearchParams, type NavigateOptions } from "react-router-dom";

type ToSetParams = { [key: string]: string | undefined | null };
type ToRemoveParams =
  | string[]
  | true
  | ((key: string, value: string) => boolean);
type QueueItem = [
  toSetParams: ToSetParams,
  toRemoveParams: ToRemoveParams | undefined,
  navigateOptions: NavigateOptions | undefined,
];
type Queue = QueueItem[];

const URLSearchParamsContext = React.createContext<{
  urlSearchParams: URLSearchParams;
  setUrlSearchParams: (
    toSetParams: ToSetParams,
    toRemoveParams?: ToRemoveParams,
    navigateOptions?: NavigateOptions
  ) => void;
}>({
  urlSearchParams: new URLSearchParams(),
  setUrlSearchParams: () => {},
});

export const URLSearchParamsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [queue, setQueue] = React.useState<Queue>([]);

  const setUrlSearchParams = React.useCallback(
    (
      toSetParams: ToSetParams,
      toRemoveParams?: ToRemoveParams,
      navigateOptions?: NavigateOptions
    ) => {
      setQueue(queue => [
        ...queue,
        [toSetParams, toRemoveParams, navigateOptions],
      ]);
    },
    []
  );

  React.useEffect(() => {
    if (queue.length > 0) {
      const navigateOpts: NavigateOptions = queue.reduce(
        (accumulator, [, , navigateOptions]) => ({
          ...accumulator,
          ...navigateOptions,
        }),
        {}
      );

      setSearchParams(previousParams => {
        const newParams = queue.reduce((accumulator, current) => {
          const [toSetParams, toRemoveParams] = current;

          let newParams: URLSearchParams;

          if (toRemoveParams === true) {
            newParams = new URLSearchParams();
          } else if (toRemoveParams instanceof Function) {
            newParams = accumulator;
            newParams.forEach((value, key) => {
              if (toRemoveParams(key, value)) {
                newParams.delete(key);
              }
            });
          } else if (toRemoveParams instanceof Array) {
            newParams = accumulator;
            toRemoveParams.forEach(param => {
              newParams.delete(param);
            });
          } else {
            newParams = accumulator;
          }

          Object.entries(toSetParams).forEach(([key, value]) => {
            if (value === null || value === undefined) {
              newParams.delete(key);
            } else {
              newParams.set(key, value.toString());
            }
          });

          return newParams;
        }, previousParams);

        setQueue([]);

        return newParams;
      }, navigateOpts);
    }
  }, [queue, setSearchParams]);

  return (
    <URLSearchParamsContext.Provider
      value={{
        urlSearchParams: searchParams,
        setUrlSearchParams,
      }}
    >
      {children}
    </URLSearchParamsContext.Provider>
  );
};

export function useUrlSearchParams() {
  return React.useContext(URLSearchParamsContext);
}
