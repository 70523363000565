import { ellipsisStyle } from "@msys/ui";
import LinkIcon from "@mui/icons-material/Link";
import {
  Link,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
} from "@mui/material";
import React from "react";

interface Props {
  link: { url: string };
  divider: boolean;
  button: boolean;
  Action?: React.ReactElement;
}

export const LinkRow = ({
  link,
  button,
  divider,
  Action,
  ...props
}: Props & ListItemProps) => {
  return (
    <ListItem
      dense
      // @ts-ignore
      button={button}
      divider={divider}
      component={Link}
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
      style={ellipsisStyle}
      {...props}
    >
      <ListItemAvatar
        sx={theme => ({
          minWidth: theme.layout.listItemMinWidth.xs,
          display: "flex",
          alignItems: "center",
        })}
      >
        <LinkIcon fontSize="small" />
      </ListItemAvatar>
      <ListItemText
        style={ellipsisStyle}
        primary={getDomainFromUrl(link.url)}
        primaryTypographyProps={{ style: ellipsisStyle }}
      />
      {Action}
    </ListItem>
  );
};

function getDomainFromUrl(url: string): string {
  try {
    const u = new URL(url);
    return u.hostname;
  } catch (e) {
    return url;
  }
}
