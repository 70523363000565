import { gql } from "@apollo/client";
import {
  getFormattedDuration,
  getFormattedFloat,
  getFormattedPrice,
} from "@msys/formatting";
import { TFunction } from "@tolgee/core";
import { intersection, sortBy } from "lodash";
import {
  AskWhen,
  AskWhom,
  DocActor,
  DocType,
  ItemType,
  ProjectMode,
  Props2,
  Props2AskWhen,
  Props2AskWhom,
  QuantityUnit,
} from "../../../clients/graphqlTypes";
import { ExpandedStore } from "../../trees-virtual/hooks/useExpandedStore";
import { VirtualItem } from "../../trees-virtual/types";
import {
  getItemChildren,
  getParentItem,
  isAnyParentMatching,
  isOrphanedItem,
} from "../../trees/helpers";
import { TreeHelperItem } from "../../trees/types";
import {
  CanItemBeFinalized_ItemFragment,
  GetDefaultDecisionQuestion_ItemFragment,
  IsItemAdmittedToDecisionWizard_ItemFragment,
  IsItemGreyedOut_ItemFragment,
  ShouldAskQuestions_ItemFragment,
  ShouldAskForDecision_ItemFragment,
  ShouldAskForProperties_ItemFragment,
  ShouldAskForContingency_ItemFragment,
  ShouldAskForProductSelection_ItemFragment,
  ShouldAskForContingencyOrDecision_ItemFragment,
  HasProductFilters_ItemFragment,
} from "./helpers.generated";
import { isComputedProp } from "./properties";

export function shouldAskQuestions(
  item: ShouldAskQuestions_ItemFragment,
  docType: DocType
) {
  // we allow showing decision button for eliminated items in templates
  return docType === "TEMPLATE" || !item.isItemEliminated;
}

export const shouldAskForContingencyOrDecision = (
  item: ShouldAskForContingencyOrDecision_ItemFragment,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) => {
  return (
    shouldAskForContingency(item, viewerDecisionRole, decisionContext) ||
    shouldAskForDecision(item, viewerDecisionRole, decisionContext)
  );
};

export const shouldAskForContingency = (
  item: ShouldAskForContingency_ItemFragment,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) => {
  return (
    item.decisionIsContingentItem &&
    (!decisionContext ||
      item.wizardSettings.askWhenContingent.includes(decisionContext)) &&
    (!viewerDecisionRole ||
      item.wizardSettings.askWhomContingent.includes(viewerDecisionRole))
  );
};

export const shouldAskForDecision = (
  item: ShouldAskForDecision_ItemFragment,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) => {
  return (
    item.type === "decision" &&
    (!decisionContext ||
      item.wizardSettings.askWhenDecision.includes(decisionContext)) &&
    (!viewerDecisionRole ||
      item.wizardSettings.askWhomDecision.includes(viewerDecisionRole))
  );
};

export const shouldAskForProperties = (
  item: ShouldAskForProperties_ItemFragment,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) => {
  return item.props2.some(p =>
    isItemProp2AdmittedToDecisionWizard(p, viewerDecisionRole, decisionContext)
  );
};

export const isItemProp2AdmittedToDecisionWizard = (
  prop2: Props2,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
): boolean => {
  return (
    !isComputedProp(prop2) &&
    (!decisionContext || prop2.askWhen.includes(decisionContext)) &&
    (!viewerDecisionRole || prop2.askWhom.includes(viewerDecisionRole))
  );
};

export function shouldAskForProductSelection(
  item: ShouldAskForProductSelection_ItemFragment,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) {
  return (
    item.type === "paid" &&
    hasProductFilters(item) &&
    (!decisionContext ||
      item.wizardSettings.askWhenPaidAndProduct.includes(decisionContext)) &&
    (!viewerDecisionRole ||
      item.wizardSettings.askWhomPaidAndProduct.includes(viewerDecisionRole))
  );
}

function hasProductFilters(item: HasProductFilters_ItemFragment) {
  return item.productSearchFilterDefaults.productTypeIdFilter;
}

export const hasAnyQuestions = (
  item: IsItemAdmittedToDecisionWizard_ItemFragment,
  docType: DocType,
  viewerDecisionRole?: AskWhom,
  decisionContext?: AskWhen
) => {
  return (
    !item.deletedAt &&
    shouldAskQuestions(item, docType) &&
    (shouldAskForContingencyOrDecision(
      item,
      viewerDecisionRole,
      decisionContext
    ) ||
      shouldAskForProperties(item, viewerDecisionRole, decisionContext) ||
      shouldAskForProductSelection(item, viewerDecisionRole, decisionContext))
  );
};

export const canItemBeFinalized = (item: CanItemBeFinalized_ItemFragment) => {
  return (
    (!item.decisionIsContingentItem ||
      (item.decisionIsContingentItem &&
        (item.decisionContingentItemPreselection === true ||
          (item.decisionContingentItemPreselection === false &&
            item.parentId !== null)))) &&
    (item.type !== "decision" ||
      (item.type === "decision" &&
        ((item.decisionBehaviorOfSubitems === "SELECT_ONE" &&
          item.decisionSubitemsPreselection.length > 0) ||
          item.decisionBehaviorOfSubitems === "SELECT_MANY")))
  );
};

export const getViewerDecisionRole = (
  docActors: Array<Pick<DocActor, "type" | "isMyOrganisation">>
): AskWhom | undefined => {
  const myActor = docActors.find(a => a.isMyOrganisation);

  if (!myActor) return undefined;

  return myActor.type === "CONTRACTOR"
    ? "contractor"
    : myActor.type === "CONTRACTEE"
      ? "contractee"
      : undefined;
};

export function getDefaultDecisionQuestion(
  t: TFunction<"Decisions"[]>,
  item: GetDefaultDecisionQuestion_ItemFragment
) {
  return item.decisionBehaviorOfSubitems === "SELECT_MANY"
    ? t("Select options for {title}", {
        ns: "Decisions",
        title: item.title,
      })
    : t("Select option for {title}", {
        ns: "Decisions",
        title: item.title,
      });
}

export type ItemClipboard = {
  docId: string;
  docType: DocType;
  projectId: string | null;
  itemId: string;
  itemType: ItemType;
};

// TODO: put calculation to the backend
export const isPreviewItemVisible = <
  T extends VirtualItem & { isDecisionOption: boolean },
>(
  item: T,
  docIsBinding: boolean,
  showDecisionOptions: boolean
): boolean => {
  return (
    (item.type === "section" ||
      item.type === "paid" ||
      item.type === "decision") &&
    (docIsBinding ? item.binding !== "draft" : true) &&
    !item.isHidden &&
    (!item.isDecisionOption || showDecisionOptions)
  );
};

// hide tree items that should not be visible to contractee
export const isTreePreviewItemVisible = <T extends VirtualItem>(
  viewerIsContractor: boolean,
  item: T,
  allDocItems: T[],
  docIsBinding: boolean,
  // decision options should be hidden for tasks
  showDecisionOptions: boolean = true
): boolean => {
  return (
    (item.type === "section" ||
      item.type === "paid" ||
      (viewerIsContractor && item.type === "unpaid") ||
      item.type === "defect" ||
      item.type === "decision") &&
    (docIsBinding ? item.binding !== "draft" : true) &&
    !item.isExplicitHidden &&
    // always show defects & internal task if possible in a tree
    (item.type === "defect" ||
      item.type === "unpaid" ||
      !isAnyParentMatching(
        item,
        allDocItems,
        parentItem =>
          parentItem.isExplicitHidden ||
          parentItem.childrenVisibility === "HIDE_CHILDREN" ||
          (!showDecisionOptions && parentItem.type === "decision")
      ))
  );
};

export const notSetSymbol = "…";

export function isItemGreyedOut<
  T extends TreeHelperItem & IsItemGreyedOut_ItemFragment,
>(item: T): boolean {
  return (
    item.isParentContingencyNotPreselected ||
    item.isParentDecisionNotPreselected ||
    item.decisionOptionIsPreselected === false
  );
}

export function getSortedPreviewItems<
  T extends VirtualItem & { isDecisionOption: boolean },
>({
  allDocItems,
  shouldRenderItem,
  docIsBinding,
  isTimeAndMaterialContract,
  quantityUnitLabels,
  language,
  expandedStore,
}: {
  allDocItems: T[];
  shouldRenderItem?: (item: T, allDocItems: T[]) => boolean;
  docIsBinding: boolean;
  isTimeAndMaterialContract: boolean;
  quantityUnitLabels: Record<QuantityUnit, string>;
  language: string;
  expandedStore: ExpandedStore | undefined;
}) {
  return sortBy(allDocItems, i => i.pathSortable)
    .filter(
      item =>
        isPreviewItemVisible(item, docIsBinding, false) &&
        // additionally filter out items which were moved into collapsed sections/decisions
        // - we don't do full refetch of preview items after move
        (!expandedStore ||
          item.level <= 1 ||
          !item.parentId ||
          expandedStore.isItemExpanded(item.parentId))
    )
    .filter((item, idx, arr) => !isOrphanedItem(item, arr))
    .filter(item => shouldRenderItem?.(item, allDocItems) !== false)
    .map(item => {
      return {
        item: item,
        parent: getParentItem(item, allDocItems),
        childItems:
          item.type === "decision"
            ? sortBy(
                getItemChildren(item, allDocItems).filter(childItem =>
                  isPreviewItemVisible(childItem, docIsBinding, true)
                ),
                i => i.pathSortable
              ).map(childItem => ({
                item: childItem,
                parent: item,
                priceData: getItemPriceData(
                  quantityUnitLabels,
                  childItem,
                  childItem.isPriceHidden,
                  isTimeAndMaterialContract,
                  language
                ),
              }))
            : null,
        priceData: getItemPriceData(
          quantityUnitLabels,
          item,
          item.isPriceHidden,
          isTimeAndMaterialContract,
          language
        ),
      };
    })
    .filter(i => !i.item.isRootItem || i.childItems?.length);
}

type PriceDataExtendedItemCalculation = {
  quantity: number;
  quantityUnit: QuantityUnit;
  materialPricePerUnit: number;
  materialPriceSubTotal: number;
  workSellingRate: number;
  workBuyingRate: number;
  timeTotal: number;
  pricePerUnit: number;
  priceSubTotal: number;
};

type PriceDataExtendedItem = {
  type: ItemType;
  level: number;
  agreedCalculation?: PriceDataExtendedItemCalculation | null;
  proposedCalculation?: PriceDataExtendedItemCalculation | null;
};

type Value = {
  agreed: string | undefined;
  proposed?: string | undefined;
};

type PriceDataExtendedRow = {
  quantity?: Value;
  unitPrice?: Value;
  subtotal?: Value;
};
export type PriceDataExtended = {
  rows?: PriceDataExtendedRow[];
  total?: Value;
};

const getItemPriceData = (
  quantityUnitLabels: Record<QuantityUnit, string>,
  item: PriceDataExtendedItem,
  hidePrices: boolean,
  isTimeAndMaterialContract: boolean,
  locale: string
): PriceDataExtended => {
  if (!item.agreedCalculation && !item.proposedCalculation) {
    return {};
  }

  if (item.type === "decision") {
    if (hidePrices) return {};

    if (item.level === 1) {
      return {
        total: {
          agreed: getFormattedPrice(
            item.agreedCalculation?.priceSubTotal,
            locale
          ),
          proposed: getFormattedPrice(
            item.proposedCalculation?.priceSubTotal,
            locale
          ),
        },
      };
    }

    return {
      rows: [
        {
          subtotal: {
            agreed: getFormattedPrice(
              item.agreedCalculation?.priceSubTotal,
              locale
            ),
            proposed: getFormattedPrice(
              item.proposedCalculation?.priceSubTotal,
              locale
            ),
          },
        },
      ],
    };
  }

  if (item.type === "section") {
    const quantity = {
      agreed:
        item.agreedCalculation?.quantity && item.agreedCalculation?.quantity > 1
          ? `${item.agreedCalculation?.quantity}`
          : undefined,
      proposed:
        item.proposedCalculation?.quantity &&
        item.proposedCalculation?.quantity > 1
          ? `${item.proposedCalculation?.quantity}`
          : undefined,
    };

    if (hidePrices) {
      return {
        rows: [
          {
            quantity,
          },
        ],
      };
    }

    if (item.level === 1)
      return {
        rows: [
          {
            quantity,
          },
        ],
        total: {
          agreed: getFormattedPrice(
            item.agreedCalculation?.priceSubTotal,
            locale
          ),
          proposed: getFormattedPrice(
            item.proposedCalculation?.priceSubTotal,
            locale
          ),
        },
      };

    return {
      rows: [
        {
          quantity,
          subtotal: {
            agreed: getFormattedPrice(
              item.agreedCalculation?.priceSubTotal,
              locale
            ),
            proposed: getFormattedPrice(
              item.proposedCalculation?.priceSubTotal,
              locale
            ),
          },
        },
      ],
    };
  }

  if (item.type === "paid") {
    const quantity = {
      agreed:
        item.agreedCalculation?.quantity !== undefined
          ? `${item.agreedCalculation.quantity} ${
              quantityUnitLabels[item.agreedCalculation.quantityUnit]
            }`
          : undefined,
      proposed:
        item.proposedCalculation?.quantity !== undefined
          ? `${item.proposedCalculation.quantity} ${
              quantityUnitLabels[item.proposedCalculation.quantityUnit]
            }`
          : undefined,
    };

    if (hidePrices) {
      return {
        rows: [
          {
            quantity,
          },
        ],
      };
    }

    if (isTimeAndMaterialContract) {
      const totalHoursAgreed =
        item.agreedCalculation?.timeTotal &&
        item.agreedCalculation.timeTotal / 60;
      const totalHoursProposed =
        item.proposedCalculation?.timeTotal &&
        item.proposedCalculation.timeTotal / 60;

      return {
        rows: [
          {
            quantity,
            unitPrice: {
              agreed: getFormattedPrice(
                item.agreedCalculation?.materialPricePerUnit,
                locale
              ),
              proposed: getFormattedPrice(
                item.proposedCalculation?.materialPricePerUnit,
                locale
              ),
            },
            subtotal:
              item.level === 1
                ? undefined
                : {
                    agreed: getFormattedPrice(
                      item.agreedCalculation?.materialPriceSubTotal,
                      locale
                    ),
                    proposed: getFormattedPrice(
                      item.proposedCalculation?.materialPriceSubTotal,
                      locale
                    ),
                  },
          },
          {
            quantity: {
              agreed:
                item.agreedCalculation !== undefined &&
                item.agreedCalculation !== null
                  ? `${getFormattedDuration(item.agreedCalculation.timeTotal)} h`
                  : undefined,
              proposed:
                item.proposedCalculation !== undefined &&
                item.proposedCalculation !== null
                  ? `${getFormattedDuration(item.proposedCalculation.timeTotal)} h`
                  : undefined,
            },
            unitPrice: {
              agreed: getFormattedPrice(
                item.agreedCalculation?.workSellingRate!,
                locale
              ),
              proposed: getFormattedPrice(
                item.proposedCalculation?.workSellingRate!,
                locale
              ),
            },
            subtotal:
              item.level === 1
                ? undefined
                : {
                    agreed:
                      totalHoursAgreed !== undefined
                        ? getFormattedPrice(
                            totalHoursAgreed *
                              item.agreedCalculation?.workSellingRate!,
                            locale
                          )
                        : undefined,
                    proposed:
                      totalHoursProposed !== undefined
                        ? getFormattedPrice(
                            totalHoursProposed *
                              item.proposedCalculation?.workSellingRate!,
                            locale
                          )
                        : undefined,
                  },
          },
        ],
        total:
          item.level === 1
            ? {
                agreed: getFormattedPrice(
                  item.agreedCalculation?.priceSubTotal,
                  locale
                ),
                proposed: getFormattedPrice(
                  item.proposedCalculation?.priceSubTotal,
                  locale
                ),
              }
            : undefined,
      };
    }

    return {
      rows: [
        {
          quantity,
          unitPrice: {
            agreed: getFormattedPrice(
              item.agreedCalculation?.pricePerUnit,
              locale
            ),
            proposed: getFormattedPrice(
              item.proposedCalculation?.pricePerUnit,
              locale
            ),
          },
          subtotal:
            item.level === 1
              ? undefined
              : {
                  agreed: getFormattedPrice(
                    item.agreedCalculation?.priceSubTotal,
                    locale
                  ),
                  proposed: getFormattedPrice(
                    item.proposedCalculation?.priceSubTotal,
                    locale
                  ),
                },
        },
      ],
      total:
        item.level === 1
          ? {
              agreed: getFormattedPrice(
                item.agreedCalculation?.priceSubTotal,
                locale
              ),
              proposed: getFormattedPrice(
                item.proposedCalculation?.priceSubTotal,
                locale
              ),
            }
          : undefined,
    };
  }

  throw new Error("Unknown item type");
};
