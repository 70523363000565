import { useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { GOOGLE_MAPS_API_KEY } from "../../environment";
import { GoogleMapsContext } from "./GoogleMapsContext";

const libraries: Libraries = ["places"];

export const GoogleMapsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  return (
    <GoogleMapsContext.Provider value={{ isLoaded }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};
