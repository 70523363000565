import { htmlToDraftState } from "@msys/textutils";
import React from "react";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  Editor,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import { blockRenderMap } from "./blockRenderMap";

export const RichTextValue = ({
  htmlContent = "",
  content,
  style,
}: {
  htmlContent?: string;
  content?: RawDraftContentState;
  style?: React.CSSProperties;
}) => {
  const editorState = React.useMemo(() => {
    if (content) return EditorState.createWithContent(convertFromRaw(content));
    const contentState = htmlToDraftState(htmlContent);
    return EditorState.createWithContent(contentState);
  }, [htmlContent, content]);

  return (
    <div style={style}>
      <Editor
        editorState={editorState}
        readOnly={true}
        onChange={() => {}}
        blockRenderMap={blockRenderMap}
      />
    </div>
  );
};
