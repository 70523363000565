import { useTranslate } from "@tolgee/react";
import { useCallback, useMemo } from "react";
import { type Salutation } from "../../../clients/graphqlTypes";

export function useSalutation() {
  const { t } = useTranslate("UserHelper");

  const salutationLabels: Record<Salutation, string> = useMemo(
    () => ({
      mr: t("Mr."),
      ms: t("Ms."),
    }),
    [t]
  );

  const getNameWithSalutation = useCallback(
    (title: Salutation, firstName: string, familyName: string) => {
      return (
        salutationLabels[title] + " " + (firstName + " " + familyName).trim()
      );
    },
    [salutationLabels]
  );

  return { salutationLabels, getNameWithSalutation };
}
