import {
  getFormattedPrice,
  isImageOr3dModel,
  MediaCardItem,
  MediaListItem,
  processAttachment,
} from "@msys/ui";
import { SxProps } from "@mui/system";
import { useTolgee, useTranslate } from "@tolgee/react";
import React from "react";
import { Link, To } from "react-router-dom";
import { PimSearchGroupedProductsNodeFragment } from "../../../main-routes/products/ProductsCatalogues.generated";
import { getProductFullDescription } from "../helper";
import { ProductSearchItem__ProductSearchResultFragment } from "../Product.generated";

interface Props<
  P extends
    | ProductSearchItem__ProductSearchResultFragment
    | PimSearchGroupedProductsNodeFragment["product"]
> {
  product: P;
  ActionButton: React.ComponentType<{ product: P }>;
  onClick?: (product: P) => void;
  getProductTo?: (product: P) => To;
  sx?: SxProps;
}

export const ProductCardItem = <
  P extends
    | ProductSearchItem__ProductSearchResultFragment
    | PimSearchGroupedProductsNodeFragment["product"]
>({
  product,
  ActionButton,
  onClick,
  getProductTo,
  sx,
}: Props<P>) => {
  // const attachments = product.attachments.filter(attachment => {
  //   if (attachment.group === "3dItemDefinition") {
  //     return (
  //       attachment.title === "thumbnail.jpg" ||
  //       attachment.title === "thumbnail.png"
  //     );
  //   }
  //   return true;
  // });
  const pictures = product.attachments
    .map(processAttachment)
    .filter(isImageOr3dModel);
  const fullDescription = getProductFullDescription(product).concat(
    "\n\n",
    `${product.articleNumber} ${product.supplier.title}`
  );
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["Product"]);
  return (
    <MediaCardItem
      key={product.id}
      // @ts-ignore
      onClick={
        onClick
          ? e => {
              e.stopPropagation();
              onClick(product);
            }
          : undefined
      }
      {...(getProductTo
        ? {
            component: Link,
            to: getProductTo(product),
          }
        : undefined)}
      title={product.texts?.title ?? ""}
      article={
        product.articleNumber
          ? `${t("Article no.", { ns: "Product" })}: ${product.articleNumber}`
          : undefined
      }
      brand={product.brand ?? undefined}
      description={fullDescription}
      descriptionMaxNumberOfLines={1}
      price={`${
        product.pricing?.netPrice
          ? getFormattedPrice(product.pricing?.netPrice, language)
          : "–"
      } / ${
        product.pricing?.listPrice
          ? getFormattedPrice(product.pricing?.listPrice, language)
          : "–"
      }`}
      imageUrl={pictures[0]?.url}
      images={pictures}
      ActionButton={<ActionButton product={product} />}
      sx={sx}
    />
  );
};

export const ProductListItem = <
  P extends
    | ProductSearchItem__ProductSearchResultFragment
    | PimSearchGroupedProductsNodeFragment["product"]
>({
  product,
  ActionButton,
  onClick,
  getProductTo,
  sx,
}: Props<P>) => {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["Product"]);
  // const attachments = product.attachments.filter(attachment => {
  //   if (attachment.group === "3dItemDefinition") {
  //     return (
  //       attachment.title === "thumbnail.jpg" ||
  //       attachment.title === "thumbnail.png"
  //     );
  //   }
  //   return true;
  // });
  const pictures = product.attachments
    .map(processAttachment)
    .filter(isImageOr3dModel);
  const fullDescription = getProductFullDescription(product).concat(
    "\n\n",
    `${product.articleNumber} ${product.supplier.title}`
  );
  return (
    <MediaListItem
      key={product.id}
      // @ts-ignore
      onClick={
        onClick
          ? e => {
              e.stopPropagation();
              onClick(product);
            }
          : undefined
      }
      {...(getProductTo
        ? {
            component: Link,
            to: getProductTo(product),
          }
        : undefined)}
      title={product.texts?.title ?? ""}
      article={
        product.articleNumber
          ? `${t("Article no.", { ns: "Product" })}: ${product.articleNumber}`
          : undefined
      }
      brand={product.brand ?? undefined}
      description={fullDescription}
      descriptionMaxNumberOfLines={1}
      price={`${
        product.pricing?.netPrice
          ? getFormattedPrice(product.pricing?.netPrice, language)
          : "–"
      } / ${
        product.pricing?.listPrice
          ? getFormattedPrice(product.pricing?.listPrice, language)
          : "–"
      }`}
      imageUrl={pictures[0]?.url}
      images={pictures}
      ActionButton={<ActionButton product={product} />}
      sx={sx}
    />
  );
};
