import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Radio,
  RadioGroup as MuiRadioGroup,
  SxProps,
  Typography,
} from "@mui/material";
import React from "react";

interface Props<Value extends string> {
  label?: React.ReactNode;
  name?: string;
  disabled?: boolean;
  condensed?: boolean;
  inline?: boolean;
  options: RadioGroupOption<Value>[];
  value: Value;
  onChange: (value: Value, event: React.ChangeEvent<HTMLInputElement>) => void;
  labelColor?: FormLabelProps["color"];
  error?: string;
  helperText?: string;
  sx?: SxProps;
}

export const RadioGroup = <Value extends string = string>({
  label,
  error,
  helperText,
  name,
  disabled,
  condensed = true,
  inline = false,
  options,
  value,
  onChange,
  labelColor,
  sx,
}: Props<Value>) => {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      disabled={disabled}
      error={Boolean(error)}
      sx={sx}
    >
      {label && (
        <FormLabel component="legend" color={labelColor} sx={{ mb: 1 }}>
          {label}
        </FormLabel>
      )}
      <MuiRadioGroup
        name={name}
        value={value}
        onChange={(event, value) => onChange(value as Value, event)}
        row={inline}
      >
        <RadioGroupOptions
          disabled={disabled}
          inline={inline}
          condensed={condensed}
          options={options}
        />
      </MuiRadioGroup>
      {error ?? helperText ? (
        <FormHelperText>{error ?? helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export interface RadioGroupOption<Value extends string = string> {
  value: Value;
  label: string;
  subLabel?: string;
}

export const RadioGroupOptions = <Value extends string = string>({
  disabled,
  condensed = true,
  inline = false,
  options,
}: {
  disabled?: boolean;
  condensed?: boolean;
  inline?: boolean;
  options: RadioGroupOption<Value>[];
}) => {
  return (
    <>
      {options.map(({ value, label, subLabel }, index) => (
        <FormControlLabel
          key={index}
          value={value}
          control={
            <Radio
              disabled={disabled}
              size="small"
              style={{
                alignSelf: "flex-start",
                marginRight: inline ? "2px" : "4px",
              }}
            />
          }
          sx={
            inline
              ? { marginLeft: 0, marginRight: "16px" }
              : {
                  marginLeft: 0,
                  marginRight: 0,
                  ["&:not(:last-child) .msys-radio-sub-label"]: {
                    marginBottom: "12px",
                  },
                }
          }
          label={
            <div>
              <Typography variant="body2" className="msys-radio-label">
                {label}
              </Typography>
              {subLabel && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="div"
                  sx={{ mt: 0.25 }}
                  className="msys-radio-sub-label"
                >
                  {subLabel}
                </Typography>
              )}
            </div>
          }
          disabled={disabled}
        />
      ))}
    </>
  );
};
