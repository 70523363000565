import React from "react";
import { Attachment3dModel, AttachmentImage } from "../attachments/helpers";
import {
  GalleryGrid,
  GalleryGridProps as GalleryGridProps,
} from "./GalleryGrid";
import { GalleryModal } from "./GalleryModal";

export interface GalleryGridWithModalProps<
  I extends AttachmentImage | Attachment3dModel
> extends GalleryGridProps<I> {
  handleAdd?: () => void;
  handleDelete?: (image: I) => void;
  handleRotate?: (image: I, direction: "right" | "left") => void;
  disabled?: boolean;
  /*
   * Use Modal as a standalone modal which is not pushing something to stack which involves closing other modals
   * Useful to show small modal on top of existing one without closin it
   */
  notInStack?: boolean;

  downloadTitle?: string;
  addTitle?: string;
  deleteTitle?: string;
  rotateTitle?: string;
  arButtonTitle?: string;
}

export const GalleryGridWithModal = <
  I extends AttachmentImage | Attachment3dModel
>({
  handleAdd,
  handleDelete,
  handleRotate,
  disabled,
  notInStack,

  downloadTitle,
  addTitle,
  deleteTitle,
  rotateTitle,
  arButtonTitle,
  ...props
}: GalleryGridWithModalProps<I>) => {
  const [modalInitialIndex, setModalInitialIndex] = React.useState<
    number | null
  >(null);
  return (
    <>
      <GalleryGrid
        {...props}
        handleDelete={handleDelete}
        handleClick={index => {
          setModalInitialIndex(index);
        }}
      />
      {modalInitialIndex !== null && (
        <GalleryModal
          images={props.images}
          initialIndex={modalInitialIndex}
          handleClose={() => setModalInitialIndex(null)}
          notInStack={notInStack}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleRotate={handleRotate}
          disabled={disabled}
          downloadTitle={downloadTitle}
          addTitle={addTitle}
          deleteTitle={deleteTitle}
          rotateTitle={rotateTitle}
          arButtonTitle={arButtonTitle}
        />
      )}
    </>
  );
};
