import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Stack,
  Box,
} from "@mui/material";
import React from "react";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { LoadingSpinner } from "../loading/LoadingSpinner";
import { useModalStack } from "./ModalStack";

export interface ModalActionButtonProps {
  label: string;
  handleClick?: () => void;
  buttonProps?: LoadingButtonProps;
}

export interface ModalProps
  extends React.ComponentProps<typeof ModalDialog>,
    React.ComponentProps<typeof ModalTitle>,
    React.ComponentProps<typeof ModalContent> {
  handleClose?: (() => void) | undefined;
  actionButtons?: React.ComponentProps<typeof ModalActions>["actionButtons"];
  dialogActions?: React.ReactNode;
}

export function Modal({
  id,
  maxWidth,
  dialogProps,
  alwaysVisible = false,
  notInStack = false,
  fixedHeight = false,
  title,
  icon,
  headerActions,
  headerButtons,
  titleProps,
  isLoading,
  contentContainerRef,
  contentProps,
  dialogActions,
  actionButtons,
  children,
  handleClose,
}: React.PropsWithChildren<ModalProps>) {
  return (
    <ModalDialog
      id={id}
      maxWidth={maxWidth}
      dialogProps={dialogProps}
      alwaysVisible={alwaysVisible}
      notInStack={notInStack}
      fixedHeight={fixedHeight}
      handleClose={handleClose}
    >
      <ModalTitle
        title={title}
        icon={icon}
        headerActions={headerActions}
        headerButtons={headerButtons}
        handleClose={handleClose}
        titleProps={titleProps}
      />
      <ModalContent
        isLoading={isLoading}
        contentContainerRef={contentContainerRef}
        contentProps={contentProps}
      >
        {children}
      </ModalContent>
      {dialogActions ??
        (actionButtons && <ModalActions actionButtons={actionButtons} />)}
    </ModalDialog>
  );
}

export function ModalDialog({
  id,
  maxWidth,
  dialogProps,
  alwaysVisible = false,
  notInStack = false,
  fixedHeight = false,
  handleClose,
  children,
}: React.PropsWithChildren<{
  id?: string;
  dialogProps?: Omit<DialogProps, "open" | "onClose">;
  handleClose?: (() => void) | undefined;
  alwaysVisible?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  fixedHeight?: boolean;
  /*
   * Use Modal as a standalone modal which is not pushing something to stack which involves closing other modals
   * Useful to show small modal on top of existing one without closing it
   */
  notInStack?: boolean;
}>) {
  const { isTop } = useModalStack(notInStack);
  const { isMaxPhone } = useScreenWidth();

  const isFullScreen = isMaxPhone || dialogProps?.fullScreen;

  return (
    <Dialog
      id={id}
      open={true}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        handleClose?.();
      }}
      onClick={e => e.stopPropagation()}
      fullScreen={isFullScreen}
      maxWidth={maxWidth}
      fullWidth={true}
      {...dialogProps}
      style={{
        display: isTop || alwaysVisible ? "block" : "none",
      }}
      PaperProps={{
        ...dialogProps?.PaperProps,
        ...(fixedHeight && !isFullScreen
          ? {
              sx: {
                height: "80%",
                maxHeight: "900px",
                minHeight: "450px",
                ...dialogProps?.PaperProps?.sx,
              },
            }
          : undefined),
      }}
    >
      {children}
    </Dialog>
  );
}

export function ModalContent({
  children,
  isLoading,
  contentContainerRef,
  contentProps,
}: React.PropsWithChildren<{
  isLoading?: boolean;
  contentContainerRef?: React.Ref<unknown>;
  contentProps?: DialogContentProps;
}>) {
  return (
    <DialogContent ref={contentContainerRef} {...contentProps}>
      {isLoading ? <LoadingSpinner /> : children}
    </DialogContent>
  );
}

export function ModalTitle({
  title,
  icon,
  headerButtons,
  headerActions,
  handleClose,
  titleProps,
}: {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  headerButtons?: React.ReactNode;
  headerActions?: React.ReactNode;
  handleClose?: () => unknown;
  titleProps?: DialogTitleProps;
}) {
  return (
    <DialogTitle
      {...titleProps}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...titleProps?.sx,
      }}
    >
      {icon && (
        <Box mr={1} display="flex" flexGrow={0} flexShrink={0}>
          {icon}
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
        flex={1}
        minWidth={0}
      >
        {typeof title === "string" ? <span>{title}</span> : title}
        {headerButtons}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={0.5}
      >
        {headerActions}
        {handleClose && (
          <IconButton
            style={{ width: 40, height: 40, flexShrink: 0, flexGrow: 0 }}
            aria-label="Close"
            color="default"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </DialogTitle>
  );
}

export function ModalActions({
  actionButtons,
}: {
  actionButtons: ModalActionButtonProps[];
}) {
  return (
    <DialogActions>
      {actionButtons.map(button => (
        <LoadingButton
          key={button.label}
          onClick={button.handleClick}
          variant="contained"
          color="primary"
          {...button.buttonProps}
        >
          {button.label}
        </LoadingButton>
      ))}
    </DialogActions>
  );
}
