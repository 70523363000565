import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Theme } from "@mui/material";
import React from "react";
import { PopoverOpenButton } from "../popover/PopoverOpenButton";

interface Props {
  label: string;
  counter?: number | null;
  disabled?: boolean;
  popoverWidth?: keyof Theme["layout"]["filters"]["popoverWidth"];
  content: React.ReactNode;
  overflowVisible?: boolean;
}

export const ButtonInputWithPopover = ({
  label,
  counter,
  disabled,
  popoverWidth = "lg",
  content,
  overflowVisible,
}: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <PopoverOpenButton
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      content={
        <Box
          sx={theme => ({
            width: `${theme.layout.filters.popoverWidth[popoverWidth]}px`,
            maxWidth: "100vw",
          })}
        >
          {content}
        </Box>
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          my: 0.5,
          ...(overflowVisible ? { overflow: "visible" } : undefined),
        },
      }}
    >
      <Button
        size="extra-small"
        variant="text"
        color="primary"
        sx={{ textTransform: "none" }}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s ease-out",
              ...(open ? { transform: `rotate(-180deg)` } : undefined),
            }}
          />
        }
        disabled={disabled}
      >
        {label}
        {counter ? ` (${counter})` : ""}
      </Button>
    </PopoverOpenButton>
  );
};
