import { useApolloClient } from "@apollo/client";
import { Modal, useScreenWidth } from "@msys/ui";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Box, Fab, Stack, styled } from "@mui/material";
import { groupBy, merge } from "lodash";
import omitDeep from "omit-deep-lodash";
import React from "react";
import { Tabs } from "@msys/ui";
import { Content, ContentInput } from "../../../clients/graphqlTypes";
import { useContentsSetMutation } from "./Content.generated";
import { useTranslate } from "@tolgee/react";
import { Article } from "./Article";
import { ArticleListItem } from "./ArticleListItem";
import { ArticleMenuButton } from "./ArticleMenuButton";
import { PredefinedTopic, useContentTopics } from "./useContentTopics";

interface Props {
  canEdit: boolean;
  title?: string;
  itemId: string;
  content: Content[];
  currentArticleId?: string;
  handleClose: () => void;
  refetchQueries?: string[];
}

export const ContentsModal = ({
  canEdit,
  title,
  itemId,
  content,
  currentArticleId: initialArticleId,
  handleClose,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["Contents", "Global"]);
  const { topicLabels } = useContentTopics();
  const { isMinTablet } = useScreenWidth();

  const [selectedTopic, setSelectedTopic] = React.useState<string>(() => {
    if (initialArticleId)
      return content.find(c => c.id === initialArticleId)?.group ?? "All";
    return "All";
  });
  const [currentArticleId, setCurrentArticleId] = React.useState<string | null>(
    initialArticleId ?? null
  );

  const groupedContent = groupBy(content, "group");
  const topics = Object.keys(groupedContent);

  const [callbackRef, isScrolled, scrollToTop] = useRefWithOnScrollCallback();

  const client = useApolloClient();
  const [setContents] = useContentsSetMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  function changeArticle(index: number, article?: Content) {
    const newContent = article
      ? [...content.slice(0, index), article, ...content.slice(index + 1)]
      : [...content.slice(0, index), ...content.slice(index + 1)];

    setContents({
      variables: {
        input: {
          id: itemId,
          contents: newContent.map(item =>
            omitDeep(item, ["__typename"])
          ) as ContentInput[],
        },
      },
    });
  }

  function deleteArticle(index: number) {
    changeArticle(index);
  }

  const contentToDisplay =
    selectedTopic === "All" ? content : groupedContent[selectedTopic];

  const selectedArticle = currentArticleId
    ? content.find(c => c.id === currentArticleId) ?? null
    : null;

  return (
    <Modal
      title={
        title ??
        t("Additional information", {
          ns: "Contents",
        })
      }
      dialogProps={{
        maxWidth: "md",
        PaperProps: { style: { height: "100%" } },
      }}
      handleClose={handleClose}
      contentContainerRef={callbackRef}
      icon={<ArticleOutlinedIcon />}
    >
      <Stack direction="column" spacing={2}>
        <Box
          position="sticky"
          top={0}
          sx={theme => ({
            backgroundColor: theme.palette.common.white,
            zIndex: 10,
          })}
        >
          {topics.length > 0 && (
            <Tabs
              condensed={isMinTablet ? false : true}
              onChange={topic => {
                setSelectedTopic(topic);
                setCurrentArticleId(null);
              }}
              value={selectedTopic}
              options={[
                {
                  value: "All",
                  label: t("All", {
                    ns: "Global",
                  }),
                },
                ...topics.map(topic => ({
                  value: topic,
                  label: !topic
                    ? t("Other", {
                        ns: "Contents",
                      })
                    : topicLabels[topic as PredefinedTopic] ?? topic,
                })),
              ]}
            />
          )}
        </Box>

        {selectedArticle ? (
          <Article
            id={selectedArticle.id}
            title={selectedArticle.title}
            previewImageUrl={selectedArticle.previewImageUrl}
            text={selectedArticle.richTexts[0]?.richText}
            links={selectedArticle.links}
            attachments={selectedArticle.attachments}
            MenuButton={
              canEdit ? (
                <ArticleMenuButton
                  article={selectedArticle}
                  onChange={(newArticle, oldArticle) => {
                    const index = content.indexOf(oldArticle);
                    changeArticle(index, merge({}, oldArticle, newArticle));
                  }}
                  onDelete={article => {
                    const index = content.indexOf(article);
                    deleteArticle(index);
                  }}
                />
              ) : undefined
            }
            handleClose={() => setCurrentArticleId(null)}
          />
        ) : (
          <Stack direction="column" spacing={1}>
            {contentToDisplay.map((article, index) => (
              <ArticleListItem
                key={article.id}
                id={article.id}
                title={article.title}
                group={article.group}
                previewImageUrl={article.previewImageUrl}
                text={article.richTexts[0]?.richText}
                MenuButton={
                  canEdit ? (
                    <ArticleMenuButton
                      article={article}
                      onChange={(newArticle, oldArticle) => {
                        const index = content.indexOf(oldArticle);
                        changeArticle(index, merge({}, oldArticle, newArticle));
                      }}
                      onDelete={article => {
                        const index = content.indexOf(article);
                        deleteArticle(index);
                      }}
                    />
                  ) : undefined
                }
                onClick={() => setCurrentArticleId(article.id)}
              />
            ))}
          </Stack>
        )}

        {isScrolled && (
          <StickyButtonContainer>
            <Box pb={1} px={1}>
              <Fab
                color="secondary"
                variant="extended"
                size="medium"
                onClick={scrollToTop}
                style={{ pointerEvents: "all", width: "100%" }}
              >
                <ArrowUpwardIcon style={{ marginRight: "8px" }} />
                {t("Go to top", {
                  ns: "Contents",
                })}
              </Fab>
            </Box>
          </StickyButtonContainer>
        )}
      </Stack>
    </Modal>
  );
};

const StickyButtonContainer = styled(Box)({
  bottom: 0,
  right: 0,
  left: 0,
  position: "sticky",
  zIndex: 3,
  pointerEvents: "none",
});

function useRefWithOnScrollCallback(): [
  (node: HTMLElement) => void,
  boolean,
  () => Promise<void>,
] {
  const eventName = "scroll";
  const ref = React.useRef<HTMLElement | null>(null);

  const [isScrolled, setIsScrolled] = React.useState(false);

  const handleScroll = React.useCallback(function (event: Event) {
    const target = event.target as HTMLElement;
    if (target) {
      setIsScrolled(target.scrollTop > 0);
    }
  }, []);

  const callback = React.useCallback(
    (node: HTMLElement) => {
      if (ref.current) {
        ref.current.removeEventListener(eventName, handleScroll);
      }

      if (node) {
        node.addEventListener(eventName, handleScroll);
      }

      ref.current = node;
    },
    [eventName, handleScroll]
  );

  const scrollToTop = React.useCallback(async () => {
    const element = ref.current;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  return [callback, isScrolled, scrollToTop];
}

// common state hooks
export function useContentModal() {
  const [isContentOpen, setIsContentOpen] = React.useState<boolean>(false);
  const [currentArticleId, setCurrentArticleId] = React.useState<
    string | undefined
  >(undefined);
  const handleCloseContent = React.useCallback(() => {
    setCurrentArticleId(undefined);
    setIsContentOpen(false);
  }, []);
  const handleOpenContent = React.useCallback((articleId?: string) => {
    if (articleId) setCurrentArticleId(articleId);
    setIsContentOpen(true);
  }, []);

  return {
    handleCloseContent,
    handleOpenContent,
    isContentOpen,
    currentArticleId,
  };
}
