import { Box, Slider, Stack } from "@mui/material";
import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { FormattedPriceInput } from "./FormattedPriceInput";

export function PriceSliderWithInput({
  label,
  range,
  labelMin,
  valueMin,
  setValueMin,
  labelMax,
  valueMax,
  setValueMax,
}: {
  label: string;
  range: [number, number];
  labelMin: string;
  valueMin: number | null;
  setValueMin: (value: number) => void;
  labelMax: string;
  valueMax: number | null;
  setValueMax: (value: number) => void;
}) {
  const { getFormattedPrice } = useFormatting();

  const [min, max] = range;

  const handleSliderChange = React.useCallback(
    (event: Event, newValue: number | number[]) => {
      if (!Array.isArray(newValue))
        throw new Error(`Invalid value for range slider: ${newValue}`);

      const [newMin, newMax] = newValue;
      setValueMin(newMin);
      setValueMax(newMax);
    },
    [setValueMax, setValueMin]
  );

  const handleMinInputChange = React.useCallback(
    (value: number | null) => {
      setValueMin(value ?? min);
    },
    [min, setValueMin]
  );
  const handleMaxInputChange = React.useCallback(
    (value: number | null) => {
      setValueMax(value ?? max);
    },
    [max, setValueMax]
  );

  const handleBlur = React.useCallback(() => {
    if (!valueMin || valueMin < min) {
      setValueMin(min);
    } else if (!valueMax || valueMax > max) {
      setValueMax(max);
    }
  }, [max, min, setValueMax, setValueMin, valueMax, valueMin]);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box flexBasis="25%" flexGrow={1} flexShrink={1}>
        <FormattedPriceInput
          label={labelMin}
          value={valueMin ?? min}
          min={min}
          max={max}
          // size="extra-small"
          onChange={handleMinInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            "aria-labelledby": "input-slider",
          }}
          variant="standard"
        />
      </Box>
      <Box
        flexBasis="50%"
        minWidth="80px"
        maxWidth="150px"
        flexGrow={2}
        flexShrink={2}
      >
        <Slider
          // size={"small"}
          value={[valueMin ?? min, valueMax ?? max]}
          step={1}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="input-slider"
          getAriaLabel={() => label}
          getAriaValueText={value => getFormattedPrice(value) ?? ""}
          valueLabelFormat={value => getFormattedPrice(value) ?? ""}
          track={"normal"}
          min={min}
          max={max}
        />
      </Box>
      <Box flexBasis="25%" flexGrow={1} flexShrink={1}>
        <FormattedPriceInput
          label={labelMax}
          value={valueMax ?? max}
          min={min}
          max={max}
          // size="extra-small"
          onChange={handleMaxInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            "aria-labelledby": "input-slider",
          }}
          variant="standard"
        />
      </Box>
    </Stack>
  );
}
