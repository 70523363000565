import { draftStateToHtml } from "@msys/textutils";
import {
  CollapseSection,
  isImageOr3dModel,
  MenuButton,
  MenuItemWithIcon,
  Modal,
  processAttachment,
  RichTextEditor,
  useIconButtonLightStyles,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Link as MuiLink,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { convertFromRaw } from "draft-js";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { cloneDeep, merge, partition, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { Content } from "../../../clients/graphqlTypes";
import { AutocompleteFreeSoloField } from "../../commons/form-fields/AutocompleteFreeSoloField";
import { GalleryGrid } from "../../commons/images/GalleryGrid";
import { Stack } from "../../commons/layout/Stack";
import { cleanHTML } from "../../utils";
import { AttachmentUploader } from "../attachments/AttachmentUploader";
import {
  newContent,
  newContentAttachment,
  newContentLink,
  newContentRichText,
} from "./helpers";
import { PredefinedTopic, useContentTopics } from "./useContentTopics";

interface Props {
  title?: string;
  content?: Content;
  confirmButtonLabel?: string;
  handleClose: () => void;
  handleComplete: (content: Content) => void | Promise<void>;
}

export const ContentEditModal = ({
  title,
  content = newContent(),
  confirmButtonLabel,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Contents", "Global"]);
  const { topicOptions } = useContentTopics();
  const { classes: menuButtonClasses } = useIconButtonLightStyles();

  const coverPhotoUploaderRef = React.useRef<HTMLInputElement>(null);
  const onAddCoverPhoto = () => {
    if (coverPhotoUploaderRef.current) {
      coverPhotoUploaderRef.current.click();
    }
  };

  const attachmentUploaderRef = React.useRef<HTMLInputElement>(null);
  const onAddAttachment = () => {
    if (attachmentUploaderRef.current) {
      attachmentUploaderRef.current.click();
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(() => Yup.object().shape({}), []); // FIXME

  return (
    <Formik<Content>
      initialValues={cloneDeep(content)}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await handleComplete(values);
        handleClose();
      }}
    >
      {({ isSubmitting, isValid, values, setFieldValue }) => (
        <Modal
          title={
            title ??
            t("Additional information", {
              ns: "Contents",
            })
          }
          dialogProps={{ maxWidth: "md" }}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text", disabled: isSubmitting },
            },
            {
              label:
                confirmButtonLabel ??
                t("Confirm", {
                  ns: "Global",
                }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: isSubmitting,
                disabled: !isValid,
              },
            },
          ]}
          handleClose={handleClose}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <AutocompleteFreeSoloField<
                (typeof topicOptions)[0] & { inputValue?: string },
                boolean | undefined
              >
                name="group"
                inputLabel={t("Topic", {
                  ns: "Contents",
                })}
                options={topicOptions}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  // Regular option
                  return option.label;
                }}
                // TODO: Is renderOption like this needed? Isn't just getOptionLabel doing the same?
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                getNewValueOption={inputValue => ({
                  inputValue,
                  label: t("Add {topic}", {
                    ns: "Contents",
                    topic: inputValue,
                  }),
                  value: inputValue as PredefinedTopic,
                })}
                onChange={(newValue, event) => {
                  event.preventDefault();
                  if (typeof newValue === "string") {
                    setFieldValue("group", newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setFieldValue("group", newValue.inputValue);
                  } else {
                    setFieldValue("group", newValue?.value ?? null);
                  }
                }}
              />
              <Field
                component={TextField}
                name="title"
                label={t("Content title", {
                  ns: "Contents",
                })}
                required
              />
              <Box alignSelf="flex-start">
                {values.previewImageUrl ? (
                  <Box position="relative">
                    <img
                      draggable={false}
                      src={values.previewImageUrl}
                      alt={t("Cover photo", {
                        ns: "Contents",
                      })}
                      style={{ display: "block", maxWidth: "100%" }}
                    />
                    <Box position="absolute" bottom={8} right={8}>
                      <MenuButton buttonProps={{ classes: menuButtonClasses }}>
                        <MenuItemWithIcon
                          icon={<EditIcon />}
                          onClick={() => onAddCoverPhoto()}
                        >
                          {t("Change photo", {
                            ns: "Contents",
                          })}
                        </MenuItemWithIcon>
                        <MenuItemWithIcon
                          icon={<DeleteIcon />}
                          onClick={() => setFieldValue("previewImageUrl", "")}
                        >
                          {t("Delete", {
                            ns: "Global",
                          })}
                        </MenuItemWithIcon>
                      </MenuButton>
                    </Box>
                  </Box>
                ) : (
                  <Button
                    startIcon={<AddIcon />}
                    variant="text"
                    color="primary"
                    onClick={() => {
                      onAddCoverPhoto();
                    }}
                  >
                    {t("Cover photo", {
                      ns: "Contents",
                    })}
                  </Button>
                )}
                <AttachmentUploader
                  innerRef={coverPhotoUploaderRef}
                  accept={"image/*,.heic,.heif"}
                  multiple={false}
                  onComplete={attachment => {
                    setFieldValue("previewImageUrl", attachment.url);
                  }}
                />
              </Box>
              <RichTextEditor
                label={t("Content", {
                  ns: "Contents",
                })}
                htmlContent={values.richTexts[0]?.richText ?? ""}
                onChange={async content => {
                  setFieldValue("richTexts", [
                    {
                      ...(values.richTexts[0] ?? newContentRichText()),
                      richText: cleanHTML(
                        draftStateToHtml(convertFromRaw(content))
                      ),
                    },
                  ]);
                }}
                saveButtonLabel={t("Save", {
                  ns: "Global",
                })}
                cancelButtonLabel={t("Cancel", {
                  ns: "Global",
                })}
              />
              <FieldArray
                name="links"
                render={arrayHelpers => (
                  <Stack flexDirection="column">
                    {values.links.map((link, index) => (
                      <Stack
                        key={index}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Field
                          component={TextField}
                          name={`links.${index}.url`}
                          label={t("Link", {
                            ns: "Contents",
                          })}
                        />
                        <IconButton size="small" color="primary">
                          <DeleteIcon
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </IconButton>
                      </Stack>
                    ))}
                    <Button
                      startIcon={<AddIcon />}
                      variant="text"
                      color="primary"
                      style={{ alignSelf: "flex-start" }}
                      onClick={() => arrayHelpers.push(newContentLink())}
                    >
                      {t("Add link", {
                        ns: "Contents",
                      })}
                    </Button>
                  </Stack>
                )}
              />

              <Stack justifyContent="space-between" alignItems="flex-start">
                <CollapseSection
                  title={t("Files", {
                    ns: "Contents",
                  })}
                  itemCount={values.attachments.length}
                  ActionButtons={
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        onAddAttachment();
                      }}
                    >
                      <AddIcon />
                      <AttachmentUploader
                        innerRef={attachmentUploaderRef}
                        accept={"*"}
                        multiple={true}
                        onComplete={attachments => {
                          setFieldValue("attachments", [
                            ...values.attachments,
                            ...attachments.map(attachment =>
                              merge(newContentAttachment(), {
                                url: attachment.url,
                                mimeType: attachment.mimeType,
                                title: attachment.title,
                              })
                            ),
                          ]);
                        }}
                      />
                    </IconButton>
                  }
                >
                  <FieldArray
                    name="attachments"
                    render={arrayHelpers => {
                      const typedAttachments =
                        values.attachments.map(processAttachment);
                      const [images, files] = partition(
                        typedAttachments,
                        isImageOr3dModel
                      );
                      return (
                        <>
                          <Box>
                            <GalleryGrid
                              images={images}
                              handleClick={null}
                              handleDelete={image => {
                                arrayHelpers.remove(
                                  typedAttachments.indexOf(image)
                                );
                              }}
                              columns={4}
                              showDeleteButton={true}
                            />
                          </Box>
                          {files.length > 0 && (
                            <List disablePadding>
                              {files.map((file, index) => (
                                <Stack
                                  key={index}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <ListItem>
                                    <MuiLink href={file.url} target="_blank">
                                      <Stack>
                                        <DescriptionIcon fontSize="small" />
                                        {file.title}
                                      </Stack>
                                    </MuiLink>
                                  </ListItem>
                                  <Tooltip
                                    title={t("Delete File", {
                                      ns: "Contents",
                                    })}
                                  >
                                    <IconButton
                                      aria-label={t("Delete File", {
                                        ns: "Contents",
                                      })}
                                      color="primary"
                                      onClick={() => {
                                        arrayHelpers.remove(
                                          typedAttachments.indexOf(file)
                                        );
                                      }}
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              ))}
                            </List>
                          )}
                        </>
                      );
                    }}
                  />
                </CollapseSection>
              </Stack>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
