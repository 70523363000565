import {
  Box,
  BoxProps,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";

export interface LabeledValueProps extends TypographyProps<"div"> {
  inline?: boolean;
  label: string;
  labelProps?: Omit<
    TypographyProps<"label">,
    "children" | "variant" | "component"
  >;
  labelIcon?: typeof SvgIcon;
  labelIconProps?: SvgIconProps;
  boxProps?: Omit<BoxProps, "children" | "component">;
  align?: React.ComponentProps<typeof Typography>["align"];
  icon?: typeof SvgIcon;
  iconFontSize?: SvgIconProps["fontSize"];
  notSet?: boolean;
}

export const LabeledValue = ({
  inline = false,
  children,
  label,
  labelProps,
  labelIcon: LabelIcon,
  labelIconProps,
  boxProps,
  style,
  align = "left",
  icon: Icon,
  iconFontSize = "medium",
  notSet = false,
  ...otherProps
}: React.PropsWithChildren<LabeledValueProps>) => {
  const Text = (
    <Box
      display="flex"
      flexDirection={inline ? "row" : "column"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      width="100%"
      {...boxProps}
    >
      <Typography
        component="label"
        color="textSecondary"
        variant="caption"
        align={align}
        sx={{
          pointerEvents: "none",
          maxWidth: "100%",
          wordWrap: "break-word",
          alignSelf: !inline ? "stretch" : undefined,
        }}
        {...labelProps}
      >
        {label}
        {inline ? ":" : ""}
        {LabelIcon && (
          <LabelIcon
            sx={{
              fontSize: "0.85rem",
              verticalAlign: "text-bottom",
              marginLeft: 0.5,
            }}
            {...labelIconProps}
          />
        )}
      </Typography>
      <Typography
        component="div"
        marginTop={0}
        marginLeft={inline ? 5 : undefined}
        style={style}
        align={align}
        variant="body2"
        sx={{
          maxWidth: "100%",
          wordWrap: "break-word",
          alignSelf: !inline ? "stretch" : undefined,
          color: notSet ? "grey.500" : undefined,
        }}
        {...otherProps}
      >
        {children}
      </Typography>
    </Box>
  );

  return Icon ? (
    <Stack direction={"row"} alignItems="center" spacing={1}>
      <Icon color="secondary" fontSize={iconFontSize} />
      {Text}
    </Stack>
  ) : (
    Text
  );
};
