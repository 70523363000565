import React from "react";

export interface Size {
  width: number;
  height: number;
}

export function useElementObserveSize<T extends HTMLElement = HTMLDivElement>(
  ref: React.MutableRefObject<T | null>,
  onSizeChange?: (size: Size) => void
) {
  const lastSize = React.useRef<Size | null>(null);

  const onSizeChangeRef = React.useRef(onSizeChange);
  onSizeChangeRef.current = onSizeChange;

  const observer = React.useRef(
    new ResizeObserver(entries => {
      const { width, height } = entries[0]?.contentRect ?? {};
      if (
        width !== undefined &&
        height !== undefined &&
        (lastSize.current?.width !== width ||
          lastSize.current?.height !== height)
      ) {
        lastSize.current = { width, height };
        onSizeChangeRef.current?.({ width, height });
      }
    })
  );

  const savedRef = React.useRef<T | null>(null);

  React.useEffect(() => {
    return () => {
      if (savedRef.current) observer.current.unobserve(savedRef.current);
      savedRef.current = null;
    };
  }, []);

  React.useEffect(() => {
    if (!ref.current || savedRef.current === ref.current) return;
    if (savedRef.current) observer.current.unobserve(savedRef.current);
    savedRef.current = ref.current;
    if (savedRef.current) observer.current.observe(savedRef.current);
  });
}
