import { Modal, TextWithBreaks } from "@msys/ui";
import { Typography, ButtonProps } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslate } from "@tolgee/react";

export function ConfirmModal({
  id,
  title,
  text,
  content,
  handleClose,
  handleCancel,
  handleConfirm,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonProps,
  cancelButtonProps,
  maxWidth,
  notInStack = true,
}: {
  id?: string;
  title?: React.ReactNode;
  text?: string;
  content?: (handleClose: () => void) => React.ReactNode;
  handleClose: () => void;
  handleCancel?: () => void;
  handleConfirm?: () => void | Promise<void>;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  maxWidth?: React.ComponentProps<typeof Modal>["maxWidth"];
  notInStack?: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate("Global");
  const [loading, setLoading] = React.useState<boolean>(false);
  return (
    <Modal
      id={id}
      title={title ?? t("Are you sure?")}
      actionButtons={[
        {
          label: cancelButtonLabel ?? t("Cancel"),
          handleClick: () => {
            handleCancel?.();
            handleClose();
          },
          buttonProps: {
            variant: "text",
            disabled: loading,
            ...cancelButtonProps,
          },
        },
        {
          label: confirmButtonLabel ?? t("Confirm"),
          handleClick: async () => {
            try {
              setLoading(true);
              await handleConfirm?.();
              handleClose();
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
              Sentry.captureException(e);
            } finally {
              setLoading(false);
            }
          },
          buttonProps: { loading, disabled: loading, ...confirmButtonProps },
        },
      ]}
      handleClose={handleClose}
      dialogProps={{ fullScreen: false }}
      maxWidth={maxWidth}
      notInStack={notInStack}
    >
      {content
        ? content(handleClose)
        : text && (
            <Typography variant="body1">
              <TextWithBreaks text={text} />
            </Typography>
          )}
    </Modal>
  );
}
