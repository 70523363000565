import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Typography } from "@mui/material";
import React from "react";

interface Props {
  icon?: React.ReactNode;
  message: React.ReactNode;
}

export const InfoLabelMessage = ({
  icon = <InfoOutlinedIcon color="secondary" fontSize="small" />,
  message,
}: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      spacing={1 / 2}
    >
      {icon}
      <Typography color="textSecondary" variant="caption">
        {message}
      </Typography>
    </Stack>
  );
};
