import {
  StandardTextFieldProps as MuiTextFieldProps,
  TextField as MuiTextField,
} from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { useLatest } from "react-use";

export interface FormattedFieldProps<Value>
  extends Omit<MuiTextFieldProps, "value" | "onChange" | "error"> {
  id?: string;
  autoCompleteDisabled?: boolean;
  value: Value | null;
  getFormattedValue: (value: Value | null) => string;
  getEditableValue: (value: Value | null) => string;
  getActualValue: (value: string) => Value | null; // throwing an error from this function will show the error state/message on the field
  debounceMs?: number;
  onChange: (value: Value | null) => void;
}

export const FormattedInput = <Value,>({
  id,
  label,
  placeholder,
  autoCompleteDisabled = true,
  value,
  getFormattedValue,
  getActualValue,
  getEditableValue,
  debounceMs = 500,
  onChange,
  helperText,
  ...props
}: FormattedFieldProps<Value>) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [dirtyValue, setDirtyValue] = React.useState<string>(
    getEditableValue(value)
  );
  const [error, setError] = React.useState<string | undefined>();

  const latestChangeHandler = useLatest((value: string) => {
    try {
      setError(undefined);
      const acutalValue = getActualValue(value);
      onChange(acutalValue);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  });

  const debouncedHandleChange = React.useMemo(
    () =>
      debounce((value: string) => {
        latestChangeHandler.current(value);
      }, debounceMs),
    [debounceMs, latestChangeHandler]
  );

  function getValue(value: Value | null) {
    return isFocused ? dirtyValue : getFormattedValue(value);
  }

  return (
    <MuiTextField
      {...props}
      id={id}
      type={"text"}
      label={label}
      placeholder={placeholder}
      value={getValue(value)}
      autoComplete={autoCompleteDisabled ? "off" : undefined}
      onChange={(event: any) => {
        const dirtyValue = event.target.value;
        setDirtyValue(dirtyValue);
        debouncedHandleChange(dirtyValue);
      }}
      onFocus={() => {
        setDirtyValue(getEditableValue(value));
        setIsFocused(true);
      }}
      onBlur={(event: any) => {
        setIsFocused(false);
        if (props.onBlur) {
          props.onBlur(event);
        }
      }}
      error={!!error}
      helperText={error ?? helperText}
      style={props.style}
    />
  );
};
