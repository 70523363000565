import React from "react";
import {
  Attachment3dModel,
  AttachmentImage,
  GalleryModal as GalleryModalUI,
  GalleryModalProps,
} from "@msys/ui";
import { useTranslate } from "@tolgee/react";

export const GalleryModal = <I extends AttachmentImage | Attachment3dModel>(
  props: GalleryModalProps<I>
) => {
  const { t } = useTranslate("Global");
  return (
    <GalleryModalUI
      downloadTitle={t("Download")}
      addTitle={t("Add")}
      rotateTitle={t("Rotate")}
      deleteTitle={t("Delete")}
      {...props}
    />
  );
};
