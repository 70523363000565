import { flatten } from "lodash";
import { ItemType } from "../../clients/graphqlTypes";
import { TreeHelperItem } from "./types";

// Getters

export function getRootItem<T extends TreeHelperItem>(allDocItems: T[]) {
  return allDocItems.find(item => !item.parentId)!;
}

export function getParentItem<
  T extends TreeHelperItem,
  Item extends T,
  AllItem extends T
>(item: Item, allDocItems: AllItem[]) {
  return allDocItems.find(maybeParent => maybeParent.id === item.parentId);
}

export function getParentForSubItems<T extends TreeHelperItem>(
  docItems: T[],
  currentItem?: T
): T | null {
  if (!docItems.length) return null;
  const parentItem = getParentItem(currentItem ?? docItems[0], docItems);
  if (!parentItem) {
    return currentItem ?? docItems[0];
  }
  return getParentForSubItems(docItems, parentItem);
}

export function getAllParentItems<T extends TreeHelperItem>(
  item: T,
  allDocItems: T[]
): T[] {
  const parentItem = getParentItem(item, allDocItems);

  return (parentItem ? [parentItem] : []).concat(
    parentItem ? getAllParentItems(parentItem, allDocItems) ?? [] : []
  );
}

export function getAllParentItemIds<T extends TreeHelperItem>(
  item: T,
  allDocItems: T[]
): string[] {
  const parentItem = getParentItem(item, allDocItems);

  return (parentItem ? [parentItem.id] : []).concat(
    parentItem ? getAllParentItemIds(parentItem, allDocItems) ?? [] : []
  );
}

export function getItemChildren<
  Item extends TreeHelperItem,
  AllDocItem extends TreeHelperItem
>(item: Item, allDocItems: AllDocItem[]): AllDocItem[] {
  return allDocItems.filter(i => i.parentId === item.id);
}

export function getAllItemChildren<T extends TreeHelperItem>(
  item: T,
  allDocItems: T[]
): T[] {
  const children = getItemChildren(item, allDocItems);

  if (children.length === 0) {
    return [];
  }

  return [
    ...children,
    ...flatten(children.map(child => getAllItemChildren(child, allDocItems))),
  ];
}

// Predicates

export function isAnyParentMatching<T extends TreeHelperItem>(
  item: T,
  items: T[],
  fn: (parent: T) => boolean
) {
  let currentItem = item;

  while (currentItem.parentId) {
    const parentQuoteItem = getParentItem(currentItem, items);

    if (parentQuoteItem) {
      if (fn(parentQuoteItem)) return true;
      currentItem = parentQuoteItem;
    } else {
      return false;
    }
  }

  return false;
}

export function isOrphanedItem<
  T extends TreeHelperItem & { isRootItem: boolean }
>(item: T, items: Array<T>): boolean {
  let parent = getParentItem(item, items);

  if (parent === undefined) return item.isRootItem ? false : true;

  return isOrphanedItem(parent, items);
}

export function isPricedItem<T extends { type: ItemType }>(item: T) {
  return item.type === "paid";
}
