import React from "react";

export const LinesWithBreaks = ({ lines }: { lines: string[] }) => {
  return (
    <>
      {lines
        .map(line => line.trim())
        .map((item: string, idx: number, array: string[]) => (
          <React.Fragment key={idx}>
            {item}
            {idx < array.length - 1 ? <br /> : null}
          </React.Fragment>
        ))}
    </>
  );
};
