import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import React from "react";
import { useGlobalLoadingCounter } from "../../../common/global-loading-state/useGlobalLoadingCounter";
import { DragAndDropContainer } from "./DragAndDropContainer";
import { Attachment, uploadFileToUploadCare } from "./helpers";

interface Props {
  title?: string;
  size?: "small" | "large";

  uploading?: boolean;
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
  skipUpload?: boolean;
  onAttachment?: (attachment: Attachment) => Promise<void> | void;
  onAttachments?: (attachments: Attachment[]) => Promise<void> | void;
  onFile?: (file: File) => void | Promise<void>;
  onFiles?: (files: File[]) => void | Promise<void>;
  iconComponent?: React.ComponentType<SvgIconTypeMap["props"]>;
}

/**
 * Drag & drop container with upload functionality
 */
export const DragAndDropUploader = ({
  title,
  size,
  accept = "*",
  skipUpload = false,
  multiple = false,
  uploading = false,
  disabled = false,
  onAttachment,
  onAttachments,
  onFile,
  onFiles,
  iconComponent,
}: Props) => {
  const { increment, decrement } = useGlobalLoadingCounter();
  return (
    <DragAndDropContainer
      title={title}
      size={size}
      uploading={uploading}
      disabled={disabled}
      accept={accept}
      onFiles={async files => {
        try {
          increment();
          if (!multiple) {
            const file = files[0];
            await onFile?.(file);
            if (!skipUpload) {
              const attachment = await uploadFileToUploadCare(file);
              await onAttachment?.(attachment);
            }
          } else {
            await onFiles?.(files);
            if (!skipUpload) {
              const attachments: Attachment[] = [];
              await Promise.all(
                files.map(async (file: File) => {
                  const result = await uploadFileToUploadCare(file);
                  attachments.push(result);
                })
              );
              await onAttachments?.(attachments);
            }
          }
        } catch (e) {
          throw e;
        } finally {
          decrement();
        }
      }}
      iconComponent={iconComponent}
    />
  );
};
