import { isEqualWith } from "lodash";
import moment from "moment";
import React from "react";
import { useLatest } from "react-use";
import { useUrlSearchParams } from "./useUrlSearchParams";

const T_STRING_DEF = "s";
const T_NUMBER_DEF = "n";
const T_BOOLEAN_DEF = "b";
const T_NULL_DEF = "null";
const T_UNDEFINED_DEF = "undef";
const T_DATE_DEF = "d";
const T_MOMENT_DEF = "m";

const isSerializable = (value: any) =>
  typeof value === "string" ||
  typeof value === "number" ||
  typeof value === "boolean" ||
  value === null ||
  value === undefined ||
  value instanceof Date ||
  value instanceof moment;

const serialize = (value: any): string => {
  if (typeof value === "string") return `${T_STRING_DEF}~${value}`;
  if (typeof value === "number") return `${T_NUMBER_DEF}~${value}`;
  if (typeof value === "boolean") return `${T_BOOLEAN_DEF}~${value}`;
  if (value === null) return T_NULL_DEF;
  if (value === undefined) return T_UNDEFINED_DEF;
  if (value instanceof Date) return `${T_DATE_DEF}~${value.toISOString()}`;
  if (value instanceof moment)
    return `${T_MOMENT_DEF}~${(value as moment.Moment).format(
      "YYYY-MM-DD_HH-mm"
    )}`;

  throw new Error(
    `Value type not supported: ${value} [typeof ${typeof value}]`
  );
};

const unserialize = (str: string): any => {
  const [type, ...rest] = str.split("~");
  const strValue = rest.join("~");

  if (type === T_STRING_DEF) return strValue;
  if (type === T_NUMBER_DEF) return parseFloat(strValue);
  if (type === T_BOOLEAN_DEF) return strValue === "true";
  if (type === T_NULL_DEF) return null;
  if (type === T_UNDEFINED_DEF) return undefined;
  if (type === T_DATE_DEF) return new Date(strValue);
  if (type === T_MOMENT_DEF) return moment(strValue, "YYYY-MM-DD_HH-mm");
  if (type === "" && strValue === "") return []; // Special fix for empty arrays

  throw new Error(`Type not supported: ${type} [${strValue}]`);
};

// taken from: https://stackoverflow.com/a/19101235
const flatten = function (data: any) {
  const result: any = {};
  function recurse(cur: any, prop: string) {
    if (cur instanceof moment || cur instanceof Date || Object(cur) !== cur) {
      result[prop] = serialize(cur);
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i++) recurse(cur[i], prop + "[" + i + "]");
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (let p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

const unflatten = function (data: any) {
  if (Object(data) !== data || Array.isArray(data)) return data;
  const regex = /\.?([^.[\]]+)|\[(\d+)]/g,
    resultholder: any = {};
  for (const p in data) {
    let cur = resultholder,
      prop = "",
      m;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = unserialize(data[p]);
  }
  return resultholder[""] || resultholder;
};

export const customizerWithDates = (v1: any, v2: any) => {
  if (v1 instanceof moment && v2 instanceof moment) {
    return v1.valueOf() === v2.valueOf();
  }
  if (v1 instanceof Date && v2 instanceof Date) {
    return v1.valueOf() === v2.valueOf();
  }
};

export const isEqual = (v1: any, v2: any) => {
  return isEqualWith(v1, v2, customizerWithDates);
};

export const getUrlStateByParamsName = <T,>(
  searchParams: URLSearchParams,
  paramsName: string
): T | undefined => {
  const urlState: any = {};

  for (const [key, value] of searchParams.entries()) {
    if (key === paramsName) {
      // extra case for simple values
      return unserialize(value) as T;
    }
    if (key.startsWith(`${paramsName}.`)) {
      urlState[key.replace(`${paramsName}.`, "")] = value;
    }
  }
  if (Object.keys(urlState).length > 0) {
    // we take value from URL Params if anything is defined
    return unflatten(urlState) as T;
  }
};

export const getUrlSearchParamsByParamsName = <T,>(
  state: T,
  paramsName: string,
  initialParams?: { [key: string]: string }
): URLSearchParams => {
  const toSetParams: { [key: string]: string } = initialParams ?? {};
  const stateFlatten = flatten(state);
  for (let [key, value] of Object.entries(stateFlatten)) {
    toSetParams[`${paramsName}.${key}`] = value as string;
  }
  return new URLSearchParams(toSetParams);
};

// Could be used for complex types: Objects, Arrays, etc.
export const useStateWithUrlParams = <T,>(
  paramsName: string,
  initialState: T,
  toRemoveParams?: string[] | true | ((key: string, value: string) => boolean)
): readonly [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();

  const latestUrlSearchParams = useLatest(urlSearchParams);
  const latestToRemoveParams = useLatest(toRemoveParams);
  const latestInitialState = useLatest(initialState);

  // set state directly to url search parameter first
  const setStateToUrl = React.useCallback(
    (state: T) => {
      const toSetParams: { [key: string]: string | undefined | null } = {};

      // 1. removing all related existing keys
      const urlKeys = latestUrlSearchParams.current.keys();
      for (let key of urlKeys) {
        if (key.startsWith(`${paramsName}.`) || key === paramsName)
          toSetParams[key] = null;
      }

      // 2. setting new values
      if (state === undefined) {
        // just set nothing
      } else if (isSerializable(state)) {
        // serialize simple value
        toSetParams[paramsName] = serialize(state);
      } else {
        const newStateFlatten = flatten(state);
        for (let [key, value] of Object.entries(newStateFlatten)) {
          toSetParams[`${paramsName}.${key}`] = value as string;
        }
      }

      setUrlSearchParams(toSetParams, latestToRemoveParams.current, {
        replace: true,
      });
    },
    [
      paramsName,
      setUrlSearchParams,
      latestUrlSearchParams,
      latestToRemoveParams,
    ]
  );

  const latestState = React.useRef<T | undefined>(undefined);

  const state = React.useMemo((): T => {
    const urlState: T | undefined = getUrlStateByParamsName(
      urlSearchParams,
      paramsName
    );
    const newState =
      urlState !== undefined ? urlState : latestInitialState.current;
    // we'd like to preserve pointer to last calculated state (in `latestState.current`) to not trigger effects every time!
    return isEqual(latestState.current, newState)
      ? (latestState.current as T)
      : newState;
  }, [urlSearchParams, paramsName, latestInitialState]);

  latestState.current = state;

  const setState = React.useCallback(
    (stateOrFunction: T | ((state: T) => T)): void => {
      if (stateOrFunction instanceof Function) {
        setStateToUrl(stateOrFunction(latestState.current as T));
      } else {
        setStateToUrl(stateOrFunction);
      }
    },
    [setStateToUrl, latestState]
  );

  return [state, setState];
};
