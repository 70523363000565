import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { Field, useField } from "formik";
import { TextField as FormikMuiTextField } from "formik-mui";

type Props = {
  name: string;
  clearable?: boolean;
} & MuiTextFieldProps;

export const TextField = ({ clearable, InputProps, ...props }: Props) => {
  const [{ value }, _, { setValue }] = useField(props.name);

  return (
    <Field
      {...props}
      component={FormikMuiTextField}
      value={value ?? ""} // should not be null, otherwise changing from controlled to uncontrolled
      InputProps={
        clearable
          ? {
              sx: {
                "&.MuiInputBase-adornedEnd:hover .clear-adornment": {
                  visibility: value ? "visible" : "hidden",
                },
              },
              endAdornment: (
                <IconButton
                  className="clear-adornment"
                  size="small"
                  color={"default"}
                  onClick={() => {
                    setValue("");
                  }}
                  aria-label="Clear"
                  sx={{ visibility: "hidden" }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
              ...InputProps,
            }
          : InputProps
      }
    />
  );
};
