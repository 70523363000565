import { LabeledValue } from "@msys/ui";
import { Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";

interface Props {
  properties: { key: string; value: string }[];
  style?: React.CSSProperties;
  columns?: 1 | 2 | 3 | 4 | 6 | 12;
  collapsedLines?: number;
}

export const PropertiesLabeledValue = ({
  properties,
  columns = 2,
  collapsedLines,
  style,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const showAmount =
    collapsedLines !== undefined ? collapsedLines * columns : 0;
  return properties.length ? (
    <Stack direction="column" spacing={1}>
      <Grid container style={style} spacing={1}>
        {(showAmount > 0 && !showMore
          ? properties.slice(0, showAmount)
          : properties
        ).map((property, index) => (
          <Grid item key={`${property.key}-${index}`} xs={12 / columns}>
            <LabeledValue label={property.key}>{property.value}</LabeledValue>
          </Grid>
        ))}
      </Grid>
      {showAmount > 0 && properties.length > showAmount && (
        <Box>
          <Button
            size="extra-small"
            color="secondary"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setShowMore(v => !v);
            }}
          >
            {showMore
              ? t("Show less", {
                  ns: "Global",
                })
              : t("Show more", {
                  ns: "Global",
                })}
          </Button>
        </Box>
      )}
    </Stack>
  ) : null;
};
