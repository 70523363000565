import DescriptionIcon from "@mui/icons-material/Description";
import {
  Link,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemProps,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Attachment, ellipsisStyle } from "@msys/ui";

interface Props {
  file: Attachment;
  button: boolean;
  Action?: React.ReactNode;
}

export const FileRow = ({
  file,
  button,
  Action,
  ...props
}: Props & ListItemProps) => {
  return (
    <ListItem
      dense
      component="div"
      disablePadding
      secondaryAction={Action}
      {...props}
    >
      <ListItemButton
        dense
        component={Link}
        href={file.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
        }}
        style={ellipsisStyle}
      >
        <ListItemAvatar
          sx={theme => ({
            minWidth: theme.layout.listItemMinWidth.xs,
            display: "flex",
            alignItems: "center",
          })}
        >
          <DescriptionIcon fontSize="small" />
        </ListItemAvatar>
        <ListItemText
          style={ellipsisStyle}
          primary={file.title}
          primaryTypographyProps={{ style: ellipsisStyle }}
        />
      </ListItemButton>
    </ListItem>
  );
};
