import { TDefinedFunction, TFunction } from "@tolgee/core";
import { etimUnits } from "./generated/etim-units";
import type { EtimKey } from "./generated/etim-units";

export { etimUnits, EtimKey };

export type Unit = {
  key: string;
  value: string;
  description: string;
};

export function getUnits(
  t: TFunction<("EtimUnitsValue" | "EtimUnitsDescription")[]>,
  language: string
): Unit[] {
  const units = etimUnits
    .map(etimUnit => {
      const translatedUnit = translateEtimUnit(etimUnit, t);

      return translatedUnit;
    })
    .sort((u1, u2) => u1.value.localeCompare(u2.value, language));

  return units;
}

export function getUnitByKey(
  key: string,
  t: TFunction<("EtimUnitsValue" | "EtimUnitsDescription")[]>
): Unit | null {
  const unit = etimUnits.find(etimUnit => etimUnit.value === key);
  if (!unit) {
    return null;
  }
  const translatedUnit = translateEtimUnit(unit, t);
  return translatedUnit;
}

function translateEtimUnit(
  unit: Unit,
  t: TFunction<("EtimUnitsValue" | "EtimUnitsDescription")[]>
) {
  const valueTranslation = t(unit.key as EtimKey, {
    ns: "EtimUnitsValue",
    defaultValue: unit.value,
  });
  const descriptionTranslation = t(unit.key as EtimKey, {
    ns: "EtimUnitsDescription",
    defaultValue: unit.description,
  });

  return {
    key: unit.key,
    value: valueTranslation,
    description: descriptionTranslation,
  };
}

// backend version

export function getUnitByKeyDefined(
  key: string,
  t: TDefinedFunction<("EtimUnitsValue" | "EtimUnitsDescription")[]>
): Unit | null {
  const unit = etimUnits.find(etimUnit => etimUnit.value === key);
  if (!unit) {
    return null;
  }
  const translatedUnit = translateEtimUnitDefined(unit, t);
  return translatedUnit;
}

function translateEtimUnitDefined(
  unit: Unit,
  t: TDefinedFunction<("EtimUnitsValue" | "EtimUnitsDescription")[]>
) {
  const valueTranslation = t(unit.key as EtimKey, {
    ns: "EtimUnitsValue",
    defaultValue: unit.value,
  });
  const descriptionTranslation = t(unit.key as EtimKey, {
    ns: "EtimUnitsDescription",
    defaultValue: unit.description,
  });

  return {
    key: unit.key,
    value: valueTranslation,
    description: descriptionTranslation,
  };
}
